import React, { useContext, useEffect, useMemo, useState } from 'react'
import { IPalletToChangeItem, PalletToChangeTypes } from '../../components/modules/API/Pallets/models/palletToChange'
import { Pallets } from '../../components/modules/API/Pallets/pallets'
import { useDispatch, useSelector } from 'react-redux'
import { LOAD, LOAD_END } from '../../store/actions/actionsTypes'
import { ContextModal } from '../../components/modules/modal/modalContext'
import { RootState } from '../../store/reducers/rootReducer'
import selectBrodMigdal from '../../components/generic/utils/selectBrodMigdal'

type Props = {
  id: number
  type: PalletToChangeTypes
}

const usePalletToChangeService = ({
  id,
  type
}: Props) => {
  const dispatch = useDispatch()
  const defaults = useSelector((state: RootState) => state.defaults.defaults)
  const codeWithoutPallet = selectBrodMigdal(defaults, 14, "Num_Value", "2")
  const [modal, setModal] = useContext(ContextModal)
  const [data, setData] = useState<IPalletToChangeItem[] | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)

  const onTypeChange = (index: number, type: number) => {
    if(!data) return
    const newData = [...data]
    newData[index].Pallet_Type = type
    if(type !== codeWithoutPallet) {
      newData[index].Real_Pallete = 1
    } else if (type === codeWithoutPallet) {
      newData[index].Real_Pallete = 0
      newData[index].Quantity_Pallets = 0
    }
    setData(newData)
  }

  const onQtyChange = (index: number, qty: number) => {
    if(!data) return
    const newData = [...data]
    newData[index].Quantity_Pallets = qty
    setData(newData)
  }

  const clearData = (index: number) => {
    if(!data) return
    const newData = [...data]
    newData[index].Quantity_Pallets = 0
    newData[index].Pallet_Type = codeWithoutPallet
    newData[index].Real_Pallete = 0
    setData(newData)
  }

  const onSave = async () => {
    if(!data) return
    const isDisabled = data.some(item => item.Pallet_Type === 0)
    if(isDisabled) {
      setModal({
        mood: false,
        value: 'יש לבחור סוג פלטה לכל פריט',
        open: true
      })
      setTimeout(() => {
        setModal({
          value: '',
          open: false
        })
      }, 3000)
      return
    }
    try {
      dispatch({type: LOAD})
      const formedData = data.map(item => ({
        RecID: item.RecID,
        Pallet_Type: item.Pallet_Type,
        Quantity_Pallets: item.Quantity_Pallets
      }))
      await Pallets.putPalletDitailes(formedData)

      setModal({
        mood: true,
        value: 'המידע נשמר בהצלחה',
        open: true
      })
      setTimeout(() => {
        setModal({
          value: '',
          open: false
        })
      }, 3000)
    } catch (error) {
      setError(
        'An error occurred while trying to save the data. Please try again.'
      )
    } finally {
      dispatch({type: LOAD_END})
    }
  }
  const checkForSave = useMemo(() => {
    if(!data) return true
    const isDisabled = data.some(item => 
      item.Pallet_Type === 0 ||
      (item.Pallet_Type !== codeWithoutPallet && item.Quantity_Pallets === 0)
    
    )
    if(isDisabled) {
      setModal({
        mood: false,
        value: 'יש לבחור סוג פלטה וכמות פלטות לכל פריט',
        open: true
      })
      setTimeout(() => {
        setModal({
          value: '',
          open: false
        })
      }, 3000)
      return false
    }
    return true
    
  }, [data, codeWithoutPallet])
  useEffect(() => {
    (async () => {
      try {
        setLoading(true)
        const response = await Pallets.getPalletsToChange(id, type)
        setData(response)
        setLoading(false)
      } catch (error) {
        setError(
          'An error occurred while trying to fetch the data. Please try again.'
        )
      } finally {
        setLoading(false)
      }
    })()
  }, [id, type])

  return { data, loading, error, onTypeChange, onQtyChange, onSave, clearData, checkForSave }
}

export default usePalletToChangeService