import React, {useState} from 'react'
import styled from 'styled-components'
import ImageComponentHead from '../imageComponents/ImageComponentHead'
import Image from '../assets/cogsPNG.png'
import useDataFetcher from '../../main/second/mainbar/knisot/hooks/useFetchData'
import { IPrintersResponseNode } from '../../modules/API/Defaults/models/printers'
import { Defaults } from '../../modules/API/Defaults/defaults'
import SearchPrinter from './Components/SearchPrinter'
import SearchBlockWithTitle from '../../main/second/mainbar/procedures/Q8/Layouts/SearchBlockWithTitle'
import SearchFormatsPrint from './Components/SearchFormatsPrint'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../store/reducers/rootReducer'
import { SET_FORMAT_ID, SET_PRINTER_ID, SET_SCALES } from '../../../store/reducers/printer'
import SearchScales from './Components/SearchScales'
// import { useState } from 'react'

type Props = {}

const PrinterSettingsModal = (props: Props) => {
  const dispatch = useDispatch()
  const printerState = useSelector((state: RootState) => state.printerReducer)
  const [selectedPrinterID, setSelectedPrinterID] = useState<number>(printerState.printerID || 0)
  const [selectedFormatID, setSelectedFormatID] = useState<number>(printerState.formatID || 0)
  const [selectedScales, setSelectedScales] = useState<number>(printerState.scales || '')
  
  const {
    data: printersList,
    isLoading: printersListLoading
  } = useDataFetcher<IPrintersResponseNode>(
    Defaults.getPrinters, 'data'
  )
  const formatList = [
    {NumWeight: 1, DescWeights: '10X15 עם פירוט'},
    {NumWeight: 2, DescWeights: '15X10 ללא פירוט'},
    {NumWeight: 3, DescWeights: '10X10 ללא פירוט'}
  ] 

  const handleChangePrinter = (printerID: number) => {
    setSelectedPrinterID(printerID)
    dispatch({type: SET_PRINTER_ID, payload: printerID})
  }

  const handleChangeFormat = (formatID: number) => {
    setSelectedFormatID(formatID)
    dispatch({type: SET_FORMAT_ID, payload: formatID})
  }
  const handleChangeScales = (id: number) => {
    setSelectedScales(id)
    dispatch({type: SET_SCALES, payload: id})
  }
  return (
    <MainWrapper>
      <ImageWrapper>
        <ImageComponentHead 
          img={Image}
          large
        />
      </ImageWrapper>
      <BodyWrapper>
        <SearchBlockWithTitle
          title={'בחר מדפסת'}
        >
          {
            !printersListLoading &&
            <SearchPrinter 
              defaultID={selectedPrinterID}
              setPrinterID={handleChangePrinter}
              list={printersList?.printers || []}

            />
          }
        </SearchBlockWithTitle>
        <SearchBlockWithTitle
          title={'בחר פורמט'}
        >
          <SearchFormatsPrint 
            defaultID={selectedFormatID}
            setFormatID={handleChangeFormat}
            list={formatList}
          />
        </SearchBlockWithTitle>
        <SearchBlockWithTitle
          title={'בחר משקל'}
        >
          <SearchScales 
            defaultID={selectedScales}
            setScalesNum={handleChangeScales}
            list={printersList?.weights || []}
          
          />
        </SearchBlockWithTitle>

      </BodyWrapper>
    </MainWrapper>
  )
}
const MainWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  gap: 0.5em;

`

const ImageWrapper = styled.div`
  width: 100%;
  height: 30%;
`

const BodyWrapper = styled.div`
  width: 90%;
  height: 80%;
  display: flex;
  flex-flow: column;
  gap: 1em;
`
export default PrinterSettingsModal