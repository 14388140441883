
export enum PalletToChangeTypes {
  ORDER = '?OrderID=',
  DELIVERY = '?Delivery_Sub=0&Delivery_Num=',
  PALLET = '?Pallet_Num=',

}

export interface IPalletToChangeItem {
  Pallet_Num: number,
  Pallet_Type: number,
  Pallete_Desc: string,
  Quantity_Pallets: number,
  Real_Pallete: number,
  RecID: number,
  Quantity: number,
}

export interface IPalletToChangePutData {
  RecID: number,
  Pallet_Type: number,
  Quantity_Pallets: number,
}