import React from 'react'
import styled from 'styled-components'

type Props = {
  children: React.ReactNode | React.ReactNode[]
}

const ControlWrapper = (props: Props) => {
  return (
    <MainWrapper>
      {props.children}
    </MainWrapper>
  )
}

const MainWrapper = styled.div`
    width: 100%;
    display: flex;
    gap: 0.5em;
`
export default ControlWrapper