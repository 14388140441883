import React, { useState, useEffect, useMemo, useContext } from "react";
import { DragDropContext, DropResult } from "react-beautiful-dnd";
import {
  ICustomerItem,
  IKavDataItem,
  IOrderItem,
} from "../../../../modules/API/Drivers/models/kavData";
import {IWorkerItem} from "../../../../modules/API/Defaults/models/workers"
import { useDispatch, useSelector } from "react-redux";
import {
  LOAD,
  LOAD_END,
  SELECT_MARKETING_AREA,
} from "../../../../../store/actions/actionsTypes";
import { RootState } from "../../../../../store/reducers/rootReducer";
import { Defaults } from "../../../../modules/API/Defaults/defaults";
import { ICarItem, TCarDict } from "../../../../../models/Defaults/carList";
import { Trucks } from "../../../../modules/API/Distribution/trucks";
import styled from "styled-components";
import ColumModal from "../../../../generic/Layouts/ColumModal";
import Logger from "./Logger/Logger";
import DatePickerModule from "../../../../generic/datePicker/datePicker";
import SearchInput from "../../../../generic/inputs/inputSearch";
import KavItem from "./Items/KavItem";
import {
  halperMergeOrders,
  helperApiDataCollector,
  helperFormatSourceSelectedItemForDrag,
  helperFormatSourceSelectedItemForRest,
} from "./helpers/helperFunctions";
import { IChangeKavData, IUpdateCarDriver } from "../../../../modules/API/Distribution/models/fetchData";
import SelectFromTheList from "../../../../generic/customSearchComponents/SelectFromTheList";
import { ITableOfTableItem } from "../../../../modules/API/Defaults/models/tableOfTables";
import MultiSelect from "./MultiSelect";
import { ITransportAreaItem } from "../../../../../models/Defaults/transportArea";
import NewKavSelector from "./Items/NewKavSelector";
import { format } from "date-fns";
import LogButton from "./Items/LogButton";
import ChangeDriverAndSave from "./ChangeDriverAndSave";
import RemarksControl from "./generic/RemarksControl";
import { Orders } from "../../../../modules/API/Orders/orders";
import { ContextModal } from "../../../../modules/modal/modalContext";
import { currentPalletWeightValue, machineСapacity } from "./helpers/summuryKavInfo";
import CircleActiveFilterBtn from "../../../../generic/particles/CircleActiveFilterBtn";
import { AcceptIcon, CloseIcon, InvoiceIcon } from "../../../../generic/icons/Icons";
import PackageOrderTrucks from "./Modals/PackageOrderTrucks";
import useDataFetcher from "../knisot/hooks/useFetchData";
import { IClientItem } from "../../../../modules/API/Defaults/models/clients";
import { Packages } from "../../../../modules/API/Packages/packages";
import { IOrderPackageTitle, IPackageOrderData } from "../../../../modules/API/Packages/models/createPackageOrderModel";
import { IPackPalletDefaultClient } from "../../../../modules/API/Packages/models/arizotLine";
import selectBrodMigdal from "../../../../generic/utils/selectBrodMigdal";
import StatisticModalLayout from "../../../../generic/Layouts/StatisticModalLayout";
import StatisticTable from "./StatisticTable/StatisticTable";

type Props = {};

interface IHashData {
  index: number;
  clients: Record<number, IHashDataClient>;
}
interface IHashDataClient {
  index: number;
  orders: Record<number, IHashDataOrder>;
}
interface IHashDataOrder {
  index: number;
  pallets: Record<number, IHashDataPallet>;
}
interface IHashDataPallet {
  index: number;
}
interface ILog {
  SourceID: number,
  Order: number[],
  Client: string,
  DestinationID: number,
  Pallets: number[],
  Date: string,

}
interface IRemarkHolder {
  item: IOrderItem;
  kavID: number;
  customerID: number;
}
const reorder = (
  list: ICustomerItem[],
  startIndex: number,
  endIndex: number
) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};
const TrucksMain = (props: Props) => {
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.mainReducer.user);
  const isKablan = user?.rights?.includes("TRCOMPANY");

  const { array, dict } = useSelector(
    (state: RootState) => state.defaults.transportAreas
  );
  const drivers: IWorkerItem[] = useSelector(
    (state: RootState) => state.defaults.workerList
  ).filter((item:IWorkerItem) => 
  item.Role_108 === 4 && (
    isKablan ? item.FreightCompanyID === +(user?.code_internal || 0) : true
  )
  );

  const sessionLogData: ILog[] = useMemo(() => {
    const data = sessionStorage.getItem('log')
    if(data) return JSON.parse(data)
    return []
  }, []) 
  const [modal, setModal] = useContext(ContextModal);
  const [logData, setLogData] = useState<ILog[]>(sessionLogData)
  const [deliveryCodeList, setDeliveryCodeList] = useState([]);
  const [deliveryID, setDeliveryID] = useState<number | null>(null);

  const defaults = useSelector((state: RootState) => state.defaults?.defaults);
  const ignorDirection = selectBrodMigdal(defaults, 593, 'Num_Value')

  const [marketingAreaList, setMarketingAreaList] = useState<
    ITableOfTableItem[]
  >([]);
  const [selectedAreas, setSelectedAreas] = useState<ITableOfTableItem[]>([]);
  const [isOpenChangeDriver, setIsOpenChangeDriver] = useState(false);
  const [isOpenLog, setIsOpenLog] = useState(false);
  const [isOpenDashboard, setIsOpenDashboard] = useState(false);
  const [isOpenTable, setIsOpenTable] = useState(false);
  const [isOpenRemark, setIsOpenRemark] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState<IRemarkHolder>();
  const [orderTree, setOrderTree] = useState<IKavDataItem[]>([]);
  const [selectedKav, setSelectedKav] = useState<IKavDataItem>();
  const [isOpenPackageOrder, setIsOpenPackageOrder] = useState(false);
  const [carsInfo, setCarsInfo] =
    useState<{ list: ICarItem[]; dict: TCarDict }>();
  const [date, setDate] = useState<string>('');
  const [searchInput, setSearchInput] = useState<string>("");
  
  const getOrdersTree = async (date: string) => {
    dispatch({ type: LOAD });
    try {
      let res = await Trucks.getOrdersTree(date);
      setOrderTree(res);
    } catch (err) {
    } finally {
      dispatch({ type: LOAD_END });
    }
  };

  const handleOpenPackageOrder = (item?: IKavDataItem) => {
    if (item) {
      setSelectedKav(item);
    }
    setIsOpenPackageOrder(!isOpenPackageOrder);
  }

  const {
    data: clientList
  } = useDataFetcher<{clients: IClientItem[], message: string}>(
    Defaults.getClientList, 'data'
  )

  const {
    data: packsData
  } = useDataFetcher<{defaultClient: IPackPalletDefaultClient[]}>(
    Packages.getPackPalletList, 'data'
  )

  const getDeliveryCodes = async () => {
    try {
      let res = await Defaults.getDeliveryCodeList();
      setDeliveryCodeList(res.deliveryCodes);
    } catch (err) {}
  };

  const getCars = async () => {
    try {
      let res: { cars: ICarItem[] } = await Defaults.getCarList();
      let newSet: TCarDict = {};

      res.cars.forEach((item) => {
        newSet[item.CarID] = item;
      });
      setCarsInfo({
        list: isKablan ? res.cars.filter(item => item.FreightCompanyID === +(user?.code_internal || 0) && item.Active) : res.cars.filter(item => item.Active),
        dict: newSet,
      });
    } catch (err) {}
  };
  
  const getMarketingAreaList = async () => {
    try {
      let res = await Defaults.getDataFromTableOfTables(300);
      setMarketingAreaList(res);
    } catch (err) {}
  };

  const patchDriver = async (id: number) => {
    if(!selectedKav) return
    try {
      await Trucks.patchDriver(selectedKav?.AreaID, {CarId: id})
      let copy = [...orderTree]
      copy[hashData[selectedKav.AreaID].index] = {
        ...copy[hashData[selectedKav.AreaID].index],
        CarId: id,
        
      }
      setOrderTree(copy)
      dict[selectedKav.AreaID].CarId = id
      setIsOpenChangeDriver(false)
  } catch(err) {
     
  }
  };
  const changeDriver = (item: IKavDataItem) => {
    setSelectedKav(item);
    setIsOpenChangeDriver(true);
}

  /**
 * Handles the drag end event when a draggable item is dropped.
 * @param result - The result object containing information about the drag event.
 */
  const onDragEnd = (result: DropResult) => {
    const { destination, draggableId, source } = result;

    // If there is no valid destination, exit the function
    if (!destination) return;

    // If the draggable item is dropped back to its original position, exit the function
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) return;

    // Extract necessary variables
    const sourceID = parseInt(source.droppableId);
    const destinationID = parseInt(destination.droppableId);
    const draggableItemId = parseInt(draggableId);
    const sourceIndex = hashData[sourceID].clients[draggableItemId].index;
    // const sourceIndex = source.index;
    const destIndex = destination.index;
    // Create a copy of the orderTree array
    const copyDataArray = [...orderTree];

    // Initialize the apiData object for collecting API data
    let apiData: IChangeKavData = {
      Orders: [],
      TransferZone: destinationID,
    };

    let logElement: ILog 
  

    // If the source and destination zones are the same
    if (sourceID === destinationID) {
      // Reorder the customers within the same zone
      const reordered = reorder(
        copyDataArray[hashData[sourceID].index].Customers,
        sourceIndex,
        destIndex
      );

      // Update the customers array with the reordered list
      copyDataArray[hashData[sourceID].index].Customers = reordered;
      let sortDataOrders = copyDataArray[hashData[sourceID].index].Customers.map(item => {
        let orders = [...item.Orders.map(order => order.OrderID)]
        return orders
      }).flat()
      sortKav(sortDataOrders)
      // Update the orderTree state with the updated copy of the array
      setOrderTree(copyDataArray);
    } else {

      // If the source and destination zones are different

      // Create copies of the customers lists for the source and destination zones
      let sourseClientsList = [
        ...copyDataArray[hashData[sourceID].index].Customers,
      ];
      let destenationClientsList = [
        ...copyDataArray[hashData[destinationID].index].Customers,
      ];

      // Get the dragged client from the source zone
      const draggedClient = Object.assign(
        {},
        sourseClientsList[hashData[sourceID].clients[draggableItemId].index]
      );

      // Check if any orders are selected in the dragged client
      const isSomethingSelected = draggedClient.Orders.some((e) => e.Selected);

      // Check if the dragged client already exists in the destination zone
      let isExistsInDestList =
        hashData[destinationID].clients[draggableItemId] !== undefined;

      // Format the selected orders for dragging
      let formatedDest = helperFormatSourceSelectedItemForDrag(
        sourseClientsList[hashData[sourceID].clients[draggableItemId].index]
          .Orders
      );

      // Format the remaining orders for the source client
      let formatedSource = helperFormatSourceSelectedItemForRest(
        sourseClientsList[hashData[sourceID].clients[draggableItemId].index]
          .Orders
      );

      if (isExistsInDestList) {
        // If the dragged client already exists in the destination zone

        // Merge the formatted orders for dragging with the existing orders in the destination client
        let newDestClientOrders;
        if (isSomethingSelected) {
          // Collect API data for the formatted orders for dragging
          apiData = helperApiDataCollector(apiData, formatedDest);

          // Merge the formatted orders for dragging with the existing orders in the destination client
          newDestClientOrders = halperMergeOrders(
            destenationClientsList[
              hashData[destinationID].clients[draggableItemId].index
            ].Orders,
            formatedDest
          );

          // Remove the dragged client from the source zone
          sourseClientsList.splice(sourceIndex, 1);

          // Insert the dragged client with the remaining orders in the source zone
          if (formatedSource.length > 0) {
            sourseClientsList.splice(sourceIndex, 0, {
              ...draggedClient,
              Orders: formatedSource,
            });
          }

          logElement = {
            "SourceID": sourceID,
            "Order": formatedDest.map(e => e.OrderID),
            "Client": draggedClient?.Customer_Name?.trim(),
            "DestinationID": destinationID,
            "Pallets": formatedDest.map(e => e.PalletsList.map(pallet => pallet.Pallet_Num)).flat(),
            "Date": format(new Date(), 'HH:mm')
          }
        } else {
          // Collect API data for the dragged client's orders
          apiData = helperApiDataCollector(apiData, draggedClient.Orders);

          // Merge the dragged client's orders with the existing orders in the destination client
          newDestClientOrders = halperMergeOrders(
            destenationClientsList[
              hashData[destinationID].clients[draggableItemId].index
            ].Orders,
            draggedClient.Orders
          );

          // Remove the dragged client from the source zone
          sourseClientsList.splice(sourceIndex, 1);

          logElement = {
            "SourceID": sourceID,
            "Order": draggedClient.Orders.map(e => e.OrderID),
            "Client": draggedClient?.Customer_Name?.trim(),
            "DestinationID": destinationID,
            "Pallets": draggedClient.Orders.map(e => e.PalletsList.map(pallet => pallet.Pallet_Num)).flat(),
            "Date": format(new Date(), 'HH:mm')
          }
        }

        // Update the orders of the dragged client with the merged orders
        draggedClient.Orders = newDestClientOrders;

        // Update the destination clients list with the modified dragged client
        destenationClientsList[
          hashData[destinationID].clients[draggableItemId].index
        ] = draggedClient;
        console.log(draggedClient.Orders)
        
        console.log(logElement)
      } else {
        if (isSomethingSelected) {
          // Collect API data for the formatted orders for dragging
          apiData = helperApiDataCollector(apiData, formatedDest);

          // Remove the dragged client from the source zone
          sourseClientsList.splice(sourceIndex, 1);

          // Insert the dragged client with the formatted orders in the destination zone
          if (formatedSource.length > 0) {
            sourseClientsList.splice(sourceIndex, 0, {
              ...draggedClient,
              Orders: formatedSource,
            });
          }
          
          // Update the orders of the dragged client with the formatted orders for dragging
          draggedClient.Orders = formatedDest;
          
          // Insert the modified dragged client into the destination clients list
          destenationClientsList.splice(destIndex, 0, draggedClient);

          logElement = {
            "SourceID": sourceID,
            "Order": formatedDest.map(e => e.OrderID),
            "Client": draggedClient?.Customer_Name?.trim(),
            "DestinationID": destinationID,
            "Pallets": formatedDest.map(e => e.PalletsList.map(pallet => pallet.Pallet_Num)).flat(),
            "Date": format(new Date(), 'HH:mm')
          }
        } else {
          // Remove the dragged client from the source zone
          sourseClientsList.splice(sourceIndex, 1);

          // Insert the dragged client into the destination zone
          destenationClientsList.splice(destIndex, 0, draggedClient);

          // Collect API data for the dragged client's orders
          apiData = helperApiDataCollector(apiData, draggedClient.Orders);

          logElement = {
            "SourceID": sourceID,
            "Order": draggedClient.Orders.map(e => e.OrderID),
            "Client": draggedClient?.Customer_Name?.trim(),
            "DestinationID": destinationID,
            "Pallets": draggedClient.Orders.map(e => e.PalletsList.map(pallet => pallet.Pallet_Num)).flat(),
            "Date": format(new Date(), 'HH:mm')
          }
        }
      }

      // If the dragged client has a non-zero PO_Num (package order number), add it to the API data
      if (draggedClient.PO_Num !== 0) {
        apiData.Orders.push({
          OrderID: draggedClient.PO_Num,
          Pallets: [],
          Type: "PackageOrder",
        });
      }

      // Update the source and destination clients lists in the copied orderTree array
      copyDataArray[hashData[sourceID].index].Customers = sourseClientsList;
      copyDataArray[hashData[destinationID].index].Customers = destenationClientsList;

      let sortDataOrders = copyDataArray[hashData[destinationID].index].Customers.map(item => {
        let orders = [...item.Orders.map(order => order.OrderID)]
        return orders
      }).flat()
      sortKav(sortDataOrders)
      // Update the orderTree state with the modified copy of the root array
      setOrderTree(copyDataArray);

      // Fetch updates using the collected API data
      patchPallet(apiData);
      setLogData((prev) => [...logData, logElement])
    }
  };
  
  /* The above code is creating a hash data structure using the `useMemo` hook in a TypeScript React
  component. */
  const hashData = useMemo(() => {
    let hash = orderTree.reduce(
      (acc: Record<number, IHashData>, cur, index) => {
        const clients = cur?.Customers.reduce(
          (
            clientsHash: Record<number, IHashDataClient>,
            client,
            indexClient
          ) => {
            const orders = client?.Orders?.reduce(
              (
                ordersHash: Record<number, IHashDataOrder>,
                order,
                orderIndex
              ) => {
                const pallets = order?.PalletsList?.reduce(
                  (
                    palletsHash: Record<number, IHashDataPallet>,
                    pallet,
                    palletIndex
                  ) => {
                    palletsHash[pallet.Pallet_Num] = {
                      index: palletIndex,
                    };
                    return palletsHash;
                  },
                  {}
                );
                ordersHash[order.OrderID] = {
                  index: orderIndex,
                  pallets: pallets || {},
                };

                return ordersHash;
              },
              {}
            );
            clientsHash[client.CustomerID] = {
              index: indexClient,
              orders: orders || {},
            };
            return clientsHash;
          },
          {}
        );

        acc[cur.AreaID] = {
          index: index,
          clients: clients || {},
        };
        return acc;
      },
      {}
    );
    return hash;
  }, [orderTree]);

  const dataForAddKav: ITransportAreaItem[] = useMemo(() => {
    return [...array]?.filter(e => !hashData[e.AreaID]).sort((a, b) => a.Sort - b.Sort);
  }, [array, hashData]);

  const patchPallet = async (body: IChangeKavData) => {
    try {
      let res = await Trucks.changeKav(body);
      // console.log(res.data)
    } catch (err) {
      console.log(err);
    }
  };
 /**
  * The function `handleSelectToDrag` is used to handle the selection of elements in a tree structure
  * and update the state accordingly.
  * @param {number} elementID - The ID of the element to be selected/deselected for dragging.
  * @param {number} kavID - The `kavID` parameter represents the ID of a specific kav (customer) in the
  * order tree.
  * @param {number} CustomerID - CustomerID is the ID of the customer within the order tree.
  * @param {number} OrderID - The `OrderID` parameter represents the ID of the order.
  * @param {boolean} [isOrder] - The `isOrder` parameter is a boolean flag that indicates whether the
  * selected element is an order or not. If `isOrder` is `true`, it means the selected element is an
  * order. If `isOrder` is `false` or not provided, it means the selected element is a
  */
  const handleSelectToDrag = (
    elementID: number,
    kavID: number,
    CustomerID: number,
    OrderID: number,
    isOrder?: boolean
  ) => {
    let newArrData = [...orderTree];

    if (!isOrder) {
      const selectedElement =
        newArrData[hashData[kavID].index].Customers[
          hashData[kavID].clients[CustomerID].index
        ].Orders[hashData[kavID].clients[CustomerID].orders[OrderID].index]
          .PalletsList[
          hashData[kavID].clients[CustomerID].orders[OrderID].pallets[elementID]
            .index
        ];
      console.log(!selectedElement.Selected);
      newArrData[hashData[kavID].index].Customers[
        hashData[kavID].clients[CustomerID].index
      ].Orders[
        hashData[kavID].clients[CustomerID].orders[OrderID].index
      ].PalletsList[
        hashData[kavID].clients[CustomerID].orders[OrderID].pallets[
          elementID
        ].index
      ].Selected = !selectedElement.Selected;

      let tempForCalc =
        newArrData[hashData[kavID].index].Customers[
          hashData[kavID].clients[CustomerID].index
        ].Orders[hashData[kavID].clients[CustomerID].orders[OrderID].index]
          .PalletsList;
      console.log(tempForCalc);
      let selectedPallet = tempForCalc.some((e) => e.Selected);
      if (selectedPallet) {
        newArrData[hashData[kavID].index].Customers[
          hashData[kavID].clients[CustomerID].index
        ].Orders[
          hashData[kavID].clients[CustomerID].orders[OrderID].index
        ].Selected = true;
      } else {
        newArrData[hashData[kavID].index].Customers[
          hashData[kavID].clients[CustomerID].index
        ].Orders[
          hashData[kavID].clients[CustomerID].orders[OrderID].index
        ].Selected = false;
      }
    } else {
      const selectedElement =
        newArrData[hashData[kavID].index].Customers[
          hashData[kavID].clients[CustomerID].index
        ].Orders[hashData[kavID].clients[CustomerID].orders[OrderID].index];
      newArrData[hashData[kavID].index].Customers[
        hashData[kavID].clients[CustomerID].index
      ].Orders[
        hashData[kavID].clients[CustomerID].orders[OrderID].index
      ].Selected = !selectedElement.Selected;
      if (selectedElement.PalletsList.length > 0) {
        newArrData[hashData[kavID].index].Customers[
          hashData[kavID].clients[CustomerID].index
        ].Orders[
          hashData[kavID].clients[CustomerID].orders[OrderID].index
        ].PalletsList.map((item) => {
          item.Selected = selectedElement.Selected;
          return item;
        });
      }
      console.log(newArrData);
    }
    setOrderTree(newArrData);
  };

  const selectDelivery = (value: string) => {
    console.log(value);
    if (value) {
      setDeliveryID(+value);
    } else {
      setDeliveryID(null);
    }
  };
  const collectSelectedDistricts = (
    item: ITableOfTableItem | null,
    clear?: boolean
  ) => {
    if (clear || !item) {
      setSelectedAreas([]);
      dispatch({ type: SELECT_MARKETING_AREA, payload: [] });
      return;
    }
    let newArr = [...selectedAreas];
    let flag = selectedAreas.find((e) => e.SecondCode === item.SecondCode);
    if (!flag) {
      newArr = [...newArr, item];
    } else {
      let elemIndex = newArr.indexOf(item);
      newArr.splice(elemIndex, 1);
    }
    setSelectedAreas(newArr);
    dispatch({ type: SELECT_MARKETING_AREA, payload: newArr });
  };
  const handleSelectNewKav = (target: string) => {
    let targetInt = +target;
    const found = hashData[targetInt]
    console.log(found)
    if (found) return;
    let newTreeArray = [...orderTree];
    let newKav: IKavDataItem  = {
      AreaDesc: dict[targetInt].AreaDesc?.trim(),
      AreaID: dict[targetInt].AreaID,
      CarId: dict[targetInt].CarId,
      DriverName: dict[targetInt].DriverName,
      Sort: dict[targetInt].Sort,
      LastDistDate: '',
      Customers: [],
      isNew: true,
      DriverWorkerId: 0,
      ShowToDriver: false,
      Actions: []
    }
    newTreeArray.splice(1, 0, newKav)
    console.log(newTreeArray)
    setOrderTree([...newTreeArray])
   
  };
  // console.log(orderTree.filter(tree => tree.Customers.filter(c => c.Orders.filter(o => o.Delivery_Code === 1).length > 0).length > 0))
  const render = useMemo(() => {
    if (!searchInput && !deliveryID && selectedAreas.length === 0) return orderTree;
    const filter = orderTree.map((item) => {
      // if (item.AreaID === 0) return true;
      return {
        ...item,
        Customers: 
          item.Customers?.filter(
            (customer) =>
            // (searchInput ? customer.Customer_Name?.trim()
            // .toLowerCase()
            // .includes(searchInput.toLowerCase()) : true)
            //    &&
              ((deliveryID ? 
                customer.Orders.filter((order) => { 
                  if(order.Delivery_Code === deliveryID) {
                   
                  }
                  return order.Delivery_Code === deliveryID
                }).length > 0
                : true) &&
              (
                selectedAreas.length > 0 ? 
                  selectedAreas.some((e) => e.SecondCode === customer.Marketing_AreaID)
                    : true
              ))
              ||
              customer.Type === 1

          )
      } 
    }).filter(e => e.Customers.length > 0 || e.isNew);
    if(filter[0]?.AreaID !== 0) {
      filter.splice(0, 1, {...orderTree[0], Customers: []})
    }
    const final = filter.filter(kav => {
      if(kav.AreaID === 0) return true
      return kav.Customers.filter(customer => 
        (searchInput ? customer.Customer_Name?.trim()
        .toLowerCase()
        .includes(searchInput.toLowerCase()) : true)
        ).length > 0
          ||
          kav.AreaDesc.trim().toLowerCase().includes(searchInput.toLowerCase())
            ||
            (carsInfo?.dict[kav.CarId] ? carsInfo?.dict[kav.CarId].DriverName.toLowerCase().trim().includes(searchInput.toLowerCase()) : false)

    }
    )
    return final;
  }, [orderTree, searchInput, deliveryID, selectedAreas]);


  useEffect(() => {
    if (!date) return;
    console.log(date);
    getOrdersTree(date);
  }, [date]);
  useEffect(() => {
    getCars();
    getDeliveryCodes();
    getMarketingAreaList();
  }, []);
  const sortKav = async (arr: number[]) => {
    try {
      await Trucks.sortKavByOrders(arr)
    } catch (Err) {

    }
  };
  const newPatchDriverCar = async (carID: number, DriverID: number, driverName: string, isPermanent?: boolean) => {
    if(!selectedKav) return
   
    try {
      let copy = [...orderTree]
      const putData = {
        "CarId": carID,
        "ChangePermanent": !!isPermanent,
        "Date": date || '',
        "DriverWorkerId": DriverID,
        "ShowToDriver": selectedKav.ShowToDriver
      }
      let res = await Trucks.updateCarDriver(selectedKav.AreaID, putData)
      copy[hashData[selectedKav.AreaID].index] = {
        ...copy[hashData[selectedKav.AreaID].index],
        CarId: carID,
        DriverName: driverName?.trim(),
        DriverWorkerId: DriverID
        
      }
      setOrderTree(copy)
      dict[selectedKav.AreaID].CarId = carID
      setIsOpenChangeDriver(false)
    } catch (err) {
      setModal({
        open: true,
        value: "משהו השתבש",
        mood: false
    })
    setTimeout(() => {
        setModal({
            ...modal,
            open: false
        })
    }, 3000)
    }
  }

  const driverVisible = async (kavID:number ,isShow: boolean) => {
      let data = {
        Date: date || '',
        ShowToDriver: isShow
      }
      try {
        await Trucks.showDriverKav(kavID, data)
      } catch (err) {

      }
  }
  const selectDriverP = {
    kav: selectedKav,
    saveFunc: patchDriver,
    list: carsInfo?.list,
  };
  const selectDriverAndCarP = {
    kav: selectedKav,
    saveFunc: newPatchDriverCar,
    listCars: carsInfo?.list,
    listCarsDrivers: drivers,
    driverName: selectedKav?.CarId ? carsInfo?.dict[selectedKav?.CarId]?.DriverName : '',
  };
  
  const handleCreatePackageOrder = async (
    clientInfo: {
      clientID: number,
      selectID: number
    }
    ) => {
    const defClientTranzitID = packsData?.defaultClient[0].ClientID || 0

    const data: IPackageOrderData = {
      ClientID_Recipient: clientInfo.selectID === 1 ? defClientTranzitID : clientInfo.clientID,
      ClientID_Sender: clientInfo.selectID === 1 ? clientInfo.clientID : defClientTranzitID,
      Reference: '',
      PD_Num: 0,
      PO_Date: date,
      Qty: 0,
      Vehicle_Code: selectedKav?.CarId || 0,
      Vehicle_Num: 0,
      Driver_Name: selectedKav?.DriverName || '',
      PO_Status: 0,
      Remarks: '',
      PO_Time: '0',
      Packs_Pallets: [],
      TransferZone: selectedKav?.AreaID || 0,
      Pickup: true,
    }
    try {
      let res: IOrderPackageTitle = await Packages.createPackageOrder(data)
      setIsOpenPackageOrder(false)
      let copy = [...orderTree]
      copy[hashData[selectedKav?.AreaID || 0].index].Customers.push({
        ClientID: defClientTranzitID === res.ClientID_Sender ? 
          res.ClientID_Recipient : res.ClientID_Sender,
        CustomerID: defClientTranzitID === res.ClientID_Sender ? 
          res.ClientID_Recipient : res.ClientID_Sender,
        Customer_Name: defClientTranzitID === res.ClientID_Sender ?
          res.RecipientName.trim() : res.SenderName.trim(),
        DistributNote: '',
        LastAction:{
          Action: 0, 
          ActionDescription: "", 
          ActionFlag: false, 
          LogDate: "", 
          LogTime: ""
        },
        Marketing_AreaID: 0,
        Orders: [],
        PD_Num: 0,
        PO_Num: res.PO_Num,
        Phone: '',
        ShippAddress: '',
        Type: 1,
      })
      setOrderTree(copy)
      setModal({
        open: true,
        value: "הזמנה נוצרה בהצלחה",
        mood: true
      })
      setTimeout(() => {
        setModal({
          ...modal,
          open: false
        })
      }, 3000)
      console.log(res)
    } catch (err) {
      setModal({
        open: true,
        value: "משהו השתבש",
        mood: false
      })
      setTimeout(() => {
        setModal({
          ...modal,
          open: false
        })
      }, 3000)
      console.log(err)
    }
  }
  const handleSaveRemarks = async (likut: string, hafa: string) => {
    if(!hashData || !selectedOrder) return
    const patchData = {
      "collectComm": likut,
      "distributNote": hafa,
      "pallets": [],
      "orders": [selectedOrder?.item.OrderID],
    }
    try {
      await Orders.patchOrderDataRemarks(patchData)

      let copy = [...orderTree]
      let newOrder = {...selectedOrder?.item}
      newOrder.Collect_Comm = likut
      newOrder.DistributNote = hafa
      copy[hashData[selectedOrder.kavID].index].Customers[hashData[selectedOrder?.kavID].clients[selectedOrder?.customerID].index].Orders[hashData[selectedOrder?.kavID].clients[selectedOrder?.customerID].orders[selectedOrder?.item.OrderID].index] = newOrder
      setOrderTree(copy)
      setIsOpenRemark(!isOpenRemark)

    } catch (err) {
      setModal({
          open: true,
          value: "משהו השתבש",
          mood: false
      })
      setTimeout(() => {
          setModal({
              ...modal,
              open: false
          })
      }, 3000)
    }
  }
  const remarkP = {
    item: selectedOrder?.item,
    saveFunc: handleSaveRemarks
  }
  console.log(orderTree);
  const handleControlLogWindow = () => {
    setIsOpenLog(!isOpenLog)
  }
  const handleIsOpenRemark = () => {
    setIsOpenRemark(!isOpenRemark)
  }
  const handleClickSelectOrder = (item: IOrderItem, area: number, CustomerID: number) => {
    setSelectedOrder({
      item: item,
      kavID: area,
      customerID: CustomerID
    });
    setIsOpenRemark(!isOpenRemark)
  }
  const [isMassActive, setIsMassActive] = useState(false)

 /**
  * The function `bulkPostSaveKav` dispatches a load action, fetches data using the `massAction`
  * function, confirms the data using the `Trucks.bulkConfirm` method, updates the `orderTree` state
  * based on the fetched data, and dispatches a load end action.
  * @returns The function `bulkPostSaveKav` does not have a return statement, so it does not explicitly
  * return anything.
  */
 const bulkPostSaveKav = async () => {
  dispatch({ type: LOAD });
  const fetchData = massAction()
  if(fetchData.length === 0) return
  try {
    let res = await Trucks.bulkConfirm(fetchData)
    let orderTreeCopy = [...orderTree]
    fetchData.forEach((item) => {
      // console.log(item.ShowToDriver)
      orderTreeCopy[hashData[item.AreaID].index] = {
        ...orderTreeCopy[hashData[item.AreaID].index],
        ShowToDriver: item.ShowToDriver
      }
    })
    setOrderTree(orderTreeCopy)
  } catch (err) {
  } finally {
    dispatch({ type: LOAD_END });
  }
 }
  
  /**
   * The `massAction` function filters an array of `orderTree` objects based on certain conditions and
   * returns a modified version of the filtered objects.
   * @returns The function `massAction` returns an array of objects. Each object in the array has the
   * following properties: "AreaID", "CarId", "ChangePermanent", "Date", "DriverWorkerId", and
   * "ShowToDriver".
   */
  const massAction = () => {
    const activated = orderTree.filter((kav) => {
      if(kav.AreaID === 0) return false
      // if(kav.ShowToDriver === !isMassActive) return false
      if(kav.Customers.length === 0) return false
      const dataKav = currentPalletWeightValue(kav.Customers)
      const dataCar = machineСapacity(carsInfo?.dict, kav.CarId)

      const isOverWeigth = (dataKav.weightCalc > (dataCar.Max_Weight || 0) && dataKav.palletCalc > (dataCar.Pallets || 0))
      return !isOverWeigth
      // console.log(data)
    })
    setIsMassActive(!isMassActive)
    return activated.map((kav) => {
      let item = {
        "AreaID": kav.AreaID,
        "CarId": kav.CarId,
        "ChangePermanent": false,
        "Date": date || '',
        "DriverWorkerId": kav.DriverWorkerId,
        "ShowToDriver": !isMassActive
      }
      return item
    })
  }

  const packageOrderProps = {
    item: selectedKav,
    clientList: clientList?.clients || [],
    createPackageOrder: handleCreatePackageOrder,
    tranzitClient: packsData?.defaultClient[0] || {}
  }
  const statisticTableP = {
    carList: carsInfo?.list,
    carsDict: carsInfo?.dict,
    orderTree: orderTree,
    transportAreaDict: dict,
    ignorDirection: ignorDirection
}
  // massAction()
  return (
    <MainWrapper >
      {/* {isOpenChangeDriver && (
        <ColumModal
          title="שינוי נהג"
          controlFunc={() => {
            setIsOpenChangeDriver(!isOpenChangeDriver);
          }}
          Children={SelectDriver}
          childrenProps={selectDriverP}
        />
      )} */}
      {
        isOpenPackageOrder &&
        <ColumModal 
          title="הוספת הזמנה אריזה"
          controlFunc={() => handleOpenPackageOrder()}
          Children={PackageOrderTrucks}
          childrenProps={packageOrderProps}
        />
      }
      {
        isOpenRemark &&
          <ColumModal 
            title={`הערות להזמנה ${selectedOrder?.item.OrderID}#`}
            controlFunc={handleIsOpenRemark}
            Children={RemarksControl}
            childrenProps={remarkP} 
          />
      }
      {isOpenChangeDriver && (
        <ColumModal
          title="שינוי רכב\נהג לקו"
          controlFunc={() => {
            setIsOpenChangeDriver(!isOpenChangeDriver);
          }}
          Children={ChangeDriverAndSave}
          childrenProps={selectDriverAndCarP}
        />
      )}
      {
        isOpenLog &&
        <Logger
            controlFunc={setIsOpenLog}
            data={logData}
            transportAreaDict={dict}
        />
      }
      
      {/* {isOpenLog && (
        <Logger
          controlFunc={setIsOpenLog}
          data={logData}
          transportAreaDict={transportAreaDict}
        />
      )}
      {isOpenDashboard && (
        <DashboardTruck
          controllFunc={setIsOpenDeshboard}
          palletList={palletList}
          transportAreaDict={transportAreaDict}
          cars={carsInfo?.dict}
          ignorDirection={ignorDirection}
        />
      )}

      
        
      */
      }
      {isOpenTable && (
        <StatisticModalLayout
          controllFunc={setIsOpenTable}
          Children={StatisticTable}
          childrenProps={statisticTableP}
        />
      )} 
      <HeadWrapper>
        <CircleActiveFilterBtn 
          withAction
          func={bulkPostSaveKav}
          children={isMassActive ? <CloseIcon handler={() => ''} /> : <AcceptIcon />}
        />
        <LogButton
          handleControl={handleControlLogWindow}
        />
        
        <CircleActiveFilterBtn
          func={() => { setIsOpenTable(true) }}
        >
          <InvoiceIcon />
        </CircleActiveFilterBtn>
        <DatePickerModule
          multyDate={false}
          ignorBrod={false}
          saveWithoutFormat={false}
          disabled={false}
          setSelectedDateStart={setDate}
          setSelectedDateEnd={() => {}}
          isNeedWithoutFormated={false}
          defEndDate={false}
          selected={false}
          large={false}
        />
        <SelectFromTheList
          lable="סבב הפצה - הכל"
          list={deliveryCodeList}
          size="large"
          itemKey="Delivery_Code"
          fieldValue="Delivery_Code"
          dispalyFieldName="Delivery_Description"
          selectFunction={selectDelivery}
          selectedValue={deliveryID}
        />
        <MultiSelect
          data={marketingAreaList}
          controllFunc={collectSelectedDistricts}
          selectedDistricts={selectedAreas}
        />
        <SearchInput input={searchInput} setInput={setSearchInput} />
        <NewKavSelector
          list={dataForAddKav}
          handleSelect={handleSelectNewKav}
        />
      </HeadWrapper>
      <Main>
        <DragDropContext onDragEnd={onDragEnd}>
          <Wrapper>
            <Inner>
              {render.map((item, index) => {
                if (item.AreaID === 0) return "";
                return (
                  <KavItem
                    {...item}
                    index={index}
                    key={item.AreaID}
                    item={item}
                    carInfo={carsInfo?.dict}
                    changeDriver={changeDriver}
                    handleSelectToDrag={handleSelectToDrag}
                    driverVisible={driverVisible}
                    handleClickSelectOrder={handleClickSelectOrder}
                    handleOpenPackageOrder={handleOpenPackageOrder}
                  />
                );
              })}
              {/* <div>TrucksMain</div> */}
            </Inner>
          </Wrapper>
          {orderTree && orderTree[0] && (
            <ZeroColumn>
              <KavItem
                {...render[0]}
                key={0}
                carInfo={carsInfo?.dict}
                changeDriver={changeDriver}
                handleSelectToDrag={handleSelectToDrag}
                handleClickSelectOrder={handleClickSelectOrder}
                reversed
              />
            </ZeroColumn>
          )}
        </DragDropContext>
      </Main>
    </MainWrapper>
  );
};
const MainWrapper = styled.div`
  width: 100%;
  height: 100vh;
  padding: 0.5em;
  display: flex;
  flex-flow: column;
  gap: 0.5em;
  align-items: center;
  background: #eff3f4;
  @media screen and (max-height: 500px) {
    position: relative;
    min-height: 1000px;
    overflow-y: scroll;
  }
`;
export const HeadWrapper = styled.div`
  width: 90%;
  min-height: 4em;
  background: #fff;
  border-radius: 30px;
  display: flex;
  align-items: center;
  padding: 0 0.4em;
  gap: 0.5em;
`;
const Main = styled.div`
  display: flex;
  width: 100%;
  height: calc(100% - 5em);
  background: #e9f3f2;
  gap: 1em;
  padding: 1em;
  border-radius: 30px;
  -webkit-box-shadow: 0px 1px 2px 2px rgba(161, 161, 161, 0.1);
  box-shadow: 0px 1px 2px 2px rgba(161, 161, 161, 0.1);
`;
const Wrapper = styled.div`
  max-height: 100%;
  width: 100vw;
  overflow: auto;
  direction: rtl;
  border-radius: 30px;
  ::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`;
const Inner = styled.div`
  min-height: 100vh;
  min-width: 100vw;
  display: flex;
  gap: 1em;
  justify-content: flex-start;
  /* padding: 1em; */

  /* flex-direction: row-reverse; */
  /* direction: rtl; */
`;
const ZeroColumn = styled.div`
  display: flex;
  direction: rtl;
  justify-content: flex-start;
  max-height: 100vh;
  overflow-y: auto;
  min-width: 280px;
  ::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`;
export default TrucksMain;
