import React from 'react'
import styled from 'styled-components'

type Props = {
    children: React.ReactNode | React.ReactNode[];
    onClick?: () => void;
}

const TableHeadCell = ({
    children, onClick
}: Props) => {
  return (
    <TableHeadCellStyle
      isClickAllow={!!onClick}
      onClick={onClick}
    >
        {children}
    </TableHeadCellStyle>
  )
}
const TableHeadCellStyle = styled.th<{isClickAllow?: boolean}>`
    min-width: 60px;
    height: 1em;
    border-radius: 15px 15px 0 0;
    padding: 0.2em;
    background: #81B4AF;
    color: #fff;
    /* text-align: right; */
    cursor: ${props => props.isClickAllow ? 'pointer' : 'default'};
`
export default TableHeadCell