import { Route, Switch, useRouteMatch } from 'react-router-dom'
import TitlesMain from './TitlesMain';
import LikutMain from './LikutMain';
import { LINKS } from '../../../../../Routs/config';
import { useContext, useState } from 'react';
import { ContextPrahim } from './helpers/ContextPrahim';
import { IContextTitle, IDataTItlePrahim } from './types/contextTitle';
import { ContextModal } from '../../../../../modules/modal/modalContext';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../../store/reducers/rootReducer';
import { Pallets } from '../../../../../modules/API/Pallets/pallets';
import { LOAD, LOAD_END } from '../../../../../../store/actions/actionsTypes';
import { SET_IS_OPEN } from '../../../../../../store/reducers/printer';

const Index = () => {
  let { path } = useRouteMatch();
  const dispatch = useDispatch()
  const printerState = useSelector((state: RootState) => state.printerReducer)
  const [modal, setModal] = useContext(ContextModal)
  const [date, setDate] = useState<Date>(new Date())
  const [dataTitle, setDataTitle] = useState<IDataTItlePrahim>({} as IDataTItlePrahim)

  const handleDate = (date: Date) => {
    setDate(date)
  }
  const handleDataTitle = (dataTitle: IDataTItlePrahim) => {
    setDataTitle(dataTitle)
  }
  const printPallet = async (pallet_num: number) => {
    if(!printerState.printerID) return dispatch({ type: SET_IS_OPEN, payload: true });
    dispatch({ type: LOAD });
    try {
        const data = {
            "ZPL_15_75": 5,
            "PalletEntryNum": +pallet_num,
            "PalletZPLFormat": +printerState.formatID
        }
        const defPrinter = printerState.printerID 
        let res = await Pallets.printPalletZPL(defPrinter, data)
        console.log(res)
        if (typeof res !== 'number') {
            let modifyed = res.split('.il').join('.il:9086');
            console.log(modifyed)
            console.log(res)
            window.open(modifyed);
        } else {
            setModal({
                open: true,
                value: 'הדפסת משטח בוצעה בהצלחה',
                mood: true
            })
            setTimeout(() => {
                setModal({
                    ...modal,
                    open: false
                })
            }, 3000)
        }
        dispatch({ type: LOAD_END });
    } catch (err: any) {
        if (err?.response?.status === 404) {
            setModal({
                open: true,
                value: 'שגיאה הדפסת משטח',
                mood: false
            })
            setTimeout(() => {
                setModal({
                    ...modal,
                    open: false
                })
            }, 3000)
            // console.log(err.response)
        }
        else {
            setModal({
                open: true,
                value: err?.response?.data?.message,
                mood: false
            })
            setTimeout(() => {
                setModal({
                    ...modal,
                    open: false
                })
            }, 3000)
        }
        dispatch({ type: LOAD_END });
    }
}

  return (
    <ContextPrahim.Provider value={{
      date, 
      handleDate, 
      dataTitle, 
      handleDataTitle,
      printPallet
    }}>
    <Switch>
      <Route 
        exact  
        path={path}
        component={TitlesMain}
      />
      <Route 
        path={LINKS.mishtahim.likutPrahim.editGeneral}
        component={LikutMain} 
        />
    </Switch>
    </ContextPrahim.Provider>

  )
}

export default Index