import React, {useEffect, useMemo, useState} from 'react'
import styled from 'styled-components'
import image from '../../../../../../components/generic/assets/warhouseWork.webp'
import SearchFromTheList from '../../../../../generic/customSearchComponents/SearchFromTheList'
import { IWarhouseListItem } from '../../../../../modules/API/Defaults/models/warhouseList'
type Props = {
  defaultID?: number,
  list: IWarhouseListItem[],
  selectedWarehouse: IWarhouseListItem | null,
  setSelectedWarehouse: (item: IWarhouseListItem | null) => void
}

const WarehouseFilter = ({
  list, selectedWarehouse, setSelectedWarehouse, defaultID
}: Props) => {
  const [input, setInput] = useState<string>('')
    const [isOpenList, setIsOpenList] = useState<boolean>(false)
    // console.log(statusOrderReheshArr)
    const renderList = useMemo(() => {
        return list.filter((item) => {
            return item.WarehouseDesc.trim().includes(input)
        })
    }, [list, input])
    const handleSearch = (e: string) => {
        setIsOpenList(true)
        setInput(e)
        if(!e) {
            console.log(null)
            setSelectedWarehouse(null)
        }
    }
    const handleSelect = (item: IWarhouseListItem) => {
        setSelectedWarehouse(item)
        setInput(item.WarehouseDesc.trim())
        setIsOpenList(false)
    }
    useEffect(() => {
        if(!defaultID) return
        const defaultItem = list.find(item => item.WarehouseID === defaultID)
        if(!defaultItem) return
        setSelectedWarehouse(defaultItem)
        setInput(defaultItem.WarehouseDesc.trim())
    }, [defaultID, list, setSelectedWarehouse])
  return (
    <MainWrapper>
      <SearchFromTheList 
            isOpenList={isOpenList}
            input={input}
            placeHolder={'חיפוש מחסן'}
            list={renderList}
            itemDisplayName='WarehouseDesc'
            isNeedFullObject={true}
            nameIdElement='WarehouseID'
            searchFunc={handleSearch}
            selectFunc={handleSelect}
            size={'full'}
            required
        />
    </MainWrapper>
  )
}
const MainWrapper = styled.div`

    width: 250px;
    
`
const Img = styled.img`
    width: 40px;
    /* height: */
`
export default WarehouseFilter