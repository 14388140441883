import { useMemo } from 'react'
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../store/reducers/rootReducer';
import selectBrodMigdal from '../../../../../generic/utils/selectBrodMigdal';


const useConfigCount = () => {
  const defaults = useSelector((e: RootState) => e.defaults?.defaults)

  const data =  useMemo(() => {
    return {
      calculationMethod: selectBrodMigdal(defaults, 344, "Num_Value")
    }
  }, [defaults])

  return data
}

export default useConfigCount