import { IEntriePutData } from "../Market/models/putRequestInterface";
import { IEntriesItem } from "../Miun/models/sortingList";
import { requests } from "../config";
import { IEntrie, IEntriesKnisotItem } from "./models/entrie";
import { IExternalPallet } from "./models/externalPallet";
import { IPostExternalEntryDetail } from "./models/postExternalEntryDetail";

export const Knisot = {
    // DateFrom: string, DateTo: string
    // DateFrom=1&DateTo=1
    putDetailsKnisa: (entryNum: string | number, data: IEntriePutData) => requests.put(`entry/${entryNum}`, "go", data),
    createTitleKnisa: (data: IEntrie) => requests.post("entry", "go", data),
    createDetailsKnisa: (entryNum: number | string, data: IEntriesKnisotItem[]) => requests.post(`entry/${entryNum}/details`, "go", data),
    getEntries: (date: string) => requests.get(`entry?${date}`, "go"),
    getEntryDetails: (id: number | string) => requests.get(`entry/${id}`, "go"),
    
    // @params {ExternalPallet} 
    // {EntryDate: string}
    // {Status: number}
    getExternalPallets: (string: string): Promise<IExternalPallet[]> => requests.get(`/external_entry/details?${string}`, "go"),
    postExternalEntryDetail: (data: IPostExternalEntryDetail): Promise<IExternalPallet> => requests.post("external_entry/create_local_entry_detail", "go", data),
    deleteEntryDetail: (entryNum: number | string, detailNum: number | string) => requests.delete(`entry/${entryNum}/detail/${detailNum}`, "go"),
}