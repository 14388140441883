import React, { useState, useEffect } from 'react'
import { IEntrie, IEntrieDetails } from '../../../../../../../modules/API/Knisot/models/entrie'
import useDataFetcher from '../../../hooks/useFetchData'
import { IOrderRehesh } from '../../../../../../../modules/API/AzmanotRehesh/models/order'
import { Rehesh } from '../../../../../../../modules/API/AzmanotRehesh/azmanotRehesh'
import { IProductsWithVarietyItem } from '../../../../../../../modules/API/Defaults/models/compliteProducts'
import { IGrowerItem } from '../../../../../../../modules/API/Defaults/models/growers'
import { ICarItem } from '../../../../../../../modules/API/Defaults/models/cars'
import { convertToDate } from '../../../../../../../modules/convertDateString'
import { dateToString } from '../../../generic/helpers/dateToString'
import styled from 'styled-components'
import SearchBlockWithTitle from '../../../../procedures/Q8/Layouts/SearchBlockWithTitle'
import SearchGrower from '../SearchGrower'
import SearchProductWithVariety from '../SearchProductWithVariety'
import CircleNumericInput from '../../../../../../../generic/inputs/CircleNumericInput'
import SearchCar from '../SearchCar'
import ControlButton from '../../../../../../../generic/buttons/ControlButton'
import DatePickerModule from '../../../../../../../generic/datePicker/datePicker'
import SearchInput from '../../../../../../../generic/inputs/inputSearch'
import { IWarhouseListItem } from '../../../../../../../modules/API/Defaults/models/warhouseList'
import SearchWarehouse from '../SearchWarehouse'
import { useKnisaConfig } from '../../config'
import { is } from 'date-fns/locale'
import TimeInput from '../../../../arizot/accounting/create/TimeInput'
import SearchTeudaTypes from '../../../../procedures/Q2/Components/SearchTeudaTypes'
import { ITeudotTypeItem } from '../../../../../../../modules/API/Defaults/models/teudotTypes'

interface IProps {
  fetchedKtisa: IEntrie | null
  growerList: IGrowerItem[]
  carList: ICarItem[]
  warehouseList: IWarhouseListItem[]
  createTitle: (data: IEntrieDetails) => void,
  teudaTypes: ITeudotTypeItem[]
}

const ModalKnisaTitle = ({
  fetchedKtisa, growerList, carList, warehouseList, createTitle, teudaTypes
}: IProps) => {
  const config = useKnisaConfig()
  const defaultDate = 
  fetchedKtisa?.EntryDate || dateToString(new Date())
  const isRequiredCar = config.create.title.car[0].value as boolean
  const isRequiredWarehouse = config.create.title.tranzit[0].value as boolean
  const defaultGrower = config.create.title.grower[0].value as number
  const defaultWarehouse = config.create.title.tranzit[1].value as number
  const isRequiredRef = config.create.title.ref[0].value as boolean
  const defaultSubTeuda = config.create.title.subTeuda[0].value as number
  console.log(defaultSubTeuda)
  const [date, setDate] = useState<string>(defaultDate)
  const [growerID, setGrowerID] = useState<number>(defaultGrower)
  const [carID, setCarID] = useState<number>(config.create.title.car[1].value as number)
  const [remark, setRemark] = useState<string>('')
  const [warehouseID, setWarehouseID] = useState<number>(defaultWarehouse)
  const [refference, setRefference] = useState<number>(0)
  const [leadName, setLeadName] = useState<string>('')
  const [carNum, setCarNum] = useState<number>(0)
  const [time, setTime] = useState<string>('')
  const [teudaTypeID, setTeudaTypeID] = useState<number>(defaultSubTeuda || 0)

  const handleRemark = (e: string) => setRemark(e)
  const handleCreateTitle = () => {
    const data = {
      Details: [],
      EntryDate: date,
      EntryNum: 0,
      EntryTime: time.split(':').join('') + '00',
      Grower: growerID,
      GrowerName: '',
      OrderNum: 0,
      Reference: +refference,
      Transport: carNum,
      Tranzit: warehouseID || defaultWarehouse,
      Remark: remark,
      LeadName: leadName,
      TypeDoc: teudaTypeID
    }
    createTitle(data)
  }

  const checkForAllowSave = () => {
    return (
      growerID !== 0 &&
      (isRequiredCar ? carID !== 0 : true) &&
      (isRequiredWarehouse ? warehouseID !== 0 : true) &&
      (isRequiredRef ? !!refference : true) &&
      !!time
    )
  }
  console.log(growerID !== 0)
  useEffect(() => {
    const car = carList.find(car => car.CarID === carID)
    if(car) {
      setLeadName(car.DriverName.trim())
      setCarNum(car.CarNum)
    }
  }, [carID, carList])
  const handleChangeTime = (e: string, valid?: boolean) => {
    if(!valid) return
    setTime(e)
  }
  return (
    <MainWrapper>
      <BodyWrapper>
        <SearchBlockWithTitle 
          title={['תאריך', '']}
        >
          <TimeInput 
            onChange={handleChangeTime}
          />
          <DatePickerModule 
            setSelectedDateStart={setDate}
            setSelectedDateEnd={false}
            multyDate={false}
            selected={false}
            defEndDate={false}
            ignorBrod
            large
            disabled={false}          
          />
          
        </SearchBlockWithTitle>
        <SearchBlockWithTitle
          title={' מגדל'}
        >
          <SearchGrower 
            defaultID={growerID}
            setGrowerID={setGrowerID}
            list={growerList}
            required={!growerID}
          />
        </SearchBlockWithTitle>
        <SearchBlockWithTitle
          title={'אסמכתא'}
        >
          <SearchInput 
            value={'אסמכתא'}
            iconHide
            type={'number'}
            setInput={setRefference}
          />
        </SearchBlockWithTitle>
        <SearchBlockWithTitle 
          title={['מספר רכב', 'שם נהג']}
        >
          <SearchInput 
            value={'שם נהג'}
            iconHide
            input={leadName}
            type={'text'}
            // defaultValue={leadName}
            setInput={setLeadName}
          />
          <SearchCar 
            defaultID={carID}
            setCarID={setCarID}
            list={carList}
            required={isRequiredCar}
            inputState={setCarNum}
          />
        </SearchBlockWithTitle>
        <SearchBlockWithTitle 
          title='מחסן'
        >
          <SearchWarehouse 
            defaultID={warehouseID}
            setWarehouseID={setWarehouseID}
            list={warehouseList}
            required={isRequiredWarehouse}
          />
        </SearchBlockWithTitle>
        <SearchBlockWithTitle 
          title='סוג תעודה'
        >
          <SearchTeudaTypes 
            list={teudaTypes}
            defaultID={teudaTypeID}
            setTeudaTypeID={setTeudaTypeID}
            required={false}
          />
        </SearchBlockWithTitle>
        
        <SearchBlockWithTitle
          title={'הערות'}
        >
          <TextArea
              placeholder='הערות'
              tabIndex={8}
              onChange={e => handleRemark(e.target.value)}
            />
        </SearchBlockWithTitle>
      </BodyWrapper>
      {
        checkForAllowSave() ?
          <ControlButton 
            lable={'צור כניסה'}
            handleClick={handleCreateTitle}
          /> : ''

      }
    </MainWrapper>
  )
}
const MainWrapper = styled.div`
  width: 100%;
  max-height: 94%;
  display: flex;
  flex-flow: column;
  gap: 1em;
  align-items: center;
`

const BodyWrapper = styled.div`
  width: 90%;
  height: 100%;
  display: flex;
  flex-flow: column;
  gap: 1em;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 0;
  
  }
`
const TextArea = styled.textarea`
  appearance: none;
  border-radius: 30px;
  border: none;
  width: 100%;
  background: #EFF3F4;
  padding: 0.5em 1em;
  font-size: 1.1em;
  font-family: 'Assistant', sans-serif;
  outline: none;
  direction: rtl;
  color: #757575;
  ::placeholder {
    opacity: 0.5;
  }
  ::-webkit-scrollbar {
    width: 0;
  }
`

export default ModalKnisaTitle