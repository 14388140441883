import React, {useState, useMemo} from 'react'
import useDataFetcher from '../../knisot/hooks/useFetchData'
import { ITitleMlaiItemWithDetails } from '../../../../../modules/API/Mlai/models/details'
import { Mlai } from '../../../../../modules/API/Mlai/mlai'
import { useHistory, useParams } from 'react-router-dom'
import OrderlikeLayout from '../../../../../generic/Layouts/OrderlikeLayout'
import BodyScrollContainer from '../../../../../generic/Layouts/Wrappers/BodyScrollContainer'
import TitlesList from '../../../../../generic/titlesRowItem/TitlesList'
import DetailsItem from './Components/DetailsItem'
import MlaiTitleItem from '../Components/MlaiTitleItem'
import ControlButton from '../../../../../generic/buttons/ControlButton'
import ControlSearchPannelLayout from '../../../../../generic/Layouts/Wrappers/ControlSearchPannelLayout'
import SearchInput from '../../../../../generic/inputs/inputSearch'

const MlaiEditMain = () => {
  const {id} = useParams<{id: string}>()
  const history = useHistory();
  const [search, setSearch] = useState<string>('')
  const handleBack = () => {
    history.goBack()
  }
  const {
    data: mlaiData,
  } = useDataFetcher<ITitleMlaiItemWithDetails>(
    Mlai.getMlaiItem, id
  )
  const render = useMemo(() => {
    if(!mlaiData) return []
    return mlaiData.Details.filter(item => 
        item.GrowerName.trim().includes(search) ||
            `${item.ProductDesc.trim()} ${item.VarietyDesc.trim()}`.includes(search) 
    )
  }, [mlaiData, search])
  return (
    <OrderlikeLayout
      label={``}
    >
        <MlaiTitleItem 
          TypeStock={mlaiData?.TypeStock || 0}
          RecID={mlaiData?.RecID || 0}
          Remarks={mlaiData?.Remarks || ''}                                                                                                                                                                                     
          Season={mlaiData?.Season || 0}
          StatusStock={mlaiData?.StatusStock || 0}
          TimeStock={mlaiData?.TimeStock || ""}
          TranzitID={mlaiData?.TranzitID || 0}
          WorkerID={mlaiData?.WorkerID || 0}
          CodeStock={mlaiData?.CodeStock || 0}
          DateStock={mlaiData?.DateStock || ''}
          WarehouseDesc={mlaiData?.WarehouseDesc || ''}
        />
        <ControlSearchPannelLayout>
          <SearchInput 
            input={search}
            setInput={setSearch}
          />
        </ControlSearchPannelLayout>
      <BodyScrollContainer>
        <TitlesList 
          list={render}
          Element={DetailsItem}
          uniqueKey={'RecID'}
          noPadding={false}
        />
      </BodyScrollContainer>
      <ControlButton 
        lable={'חזרה'}
        handleClick={handleBack}
      />
    </OrderlikeLayout>
  )
}

export default MlaiEditMain