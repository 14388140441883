import React from 'react'
import { IProductWithCounItem } from '../../../../../../modules/API/Products/models/productsWithCount'
import TableRow from '../../../procedures/Q8/Layouts/Table/TableRow'
import TableBodyCell from '../../../procedures/Q8/Layouts/Table/TableBodyCell'

interface Props extends IProductWithCounItem {
    calculationMethod: number
}

const OrderCountBodyTableRow = ({
    ProductDesc, 
    VarietyDesc,
    OrdersPacks,
    OrdersPallets,
    OrdersWeight,
    MlaiPacks,
    MlaiPallets,
    MlaiWeight,
    OrdersSupplyPacks,
    OrdersSupplyPallets,
    OrdersSupplyWeight,
    calculationMethod,
    ItraPacks,
    ItraPallets,
    ItraWeight,
    MlaiCafuiPacks,
    MlaiCafuiPallets,
    MlaiCafuiWeight
}: Props) => {
  return (
    <TableRow>
        <TableBodyCell>
            {`${ProductDesc.trim()} ${VarietyDesc.trim()}`}
        </TableBodyCell>
        <TableBodyCell>
            {OrdersPacks}
        </TableBodyCell>
        {
            calculationMethod === 2 ?
            <TableBodyCell>
                {OrdersPallets}
            </TableBodyCell> :
            calculationMethod === 3 ?
            <TableBodyCell>
                {OrdersWeight}
            </TableBodyCell> : ''
        }

        {/* <TableBodyCell>
            {
                calculationMethod === 2 ?
                OrdersPallets :
                calculationMethod === 3 ?
                OrdersWeight : ''
            }
        </TableBodyCell> */}
        {}
        <TableBodyCell>
            {OrdersSupplyPacks}
        </TableBodyCell>
            {
                calculationMethod === 2 ?
                <TableBodyCell>
                    {OrdersSupplyPallets}
                </TableBodyCell> :
                calculationMethod === 3 ?
                <TableBodyCell>
                    {OrdersSupplyWeight}
                </TableBodyCell> : ''
            }

        {/* <TableBodyCell>
           {
                calculationMethod === 2 ?
                OrdersSupplyPallets :
                calculationMethod === 3 ?
                OrdersSupplyWeight : ''
           }
        </TableBodyCell> */}
        <TableBodyCell>
            {MlaiPacks}
        </TableBodyCell>
        {
            calculationMethod === 2 ?
            <TableBodyCell>
                {MlaiPallets}
            </TableBodyCell> :
            calculationMethod === 3 ?
            <TableBodyCell>
                {MlaiWeight}
            </TableBodyCell> : ''
        }
        {
            calculationMethod === 2 ?
            <TableBodyCell>
                {MlaiCafuiPallets}
            </TableBodyCell> :
            calculationMethod === 3 ?
            <TableBodyCell>
                {MlaiCafuiWeight}
            </TableBodyCell> : 
            <TableBodyCell>
                {MlaiCafuiPacks}
            </TableBodyCell>
        }
        {
            calculationMethod === 2 ?
            <TableBodyCell>
                {ItraPallets}
            </TableBodyCell> :
            calculationMethod === 3 ?
            <TableBodyCell>
                {ItraWeight}
            </TableBodyCell> : 
            <TableBodyCell>

                {ItraPacks}
            </TableBodyCell>
            
        }
        
        {/* <TableBodyCell>
            {
                calculationMethod === 2 ?
                MlaiPallets :
                calculationMethod === 3 ?
                MlaiWeight : ''
            }
        </TableBodyCell> */}
       
    </TableRow>
  )
}

export default OrderCountBodyTableRow