export const buttonsTranslationAR = {
  buttons: {
    "save": "حفظ",
    "cancel": "إلغاء",
    "new": "جديد",
    "close": "إغلاق",
    "exit": "خروج",
    "back": "رجوع",
  }
}
