import React from "react";
import { IWarhouseListItem } from "../../../../../modules/API/Defaults/models/warhouseList";

export interface IDataMlai {
    date: string,
    tranzit: string,
    rights: string[],
    setDate: (date: string) => void,
    isCustomer: boolean,
    warehouseList: IWarhouseListItem[] ;
    defaultWarehouse: number
}
const initialState = {
    date: '',
    tranzit: '',
    rights: [] as string[],
    setDate: (date: string) => {},
    isCustomer: false,
    warehouseList: [] as IWarhouseListItem[],
    defaultWarehouse: 0
    
}
// create context prahim
export const ContextMlai = React.createContext(initialState)