import React from 'react'
import styled from 'styled-components'
import { IWarhouseListItem } from '../../../../../../../modules/API/Defaults/models/warhouseList'
import SearchWarehouse from '../../../../knisot/Create/Components/SearchWarehouse'
import ImageComponentHead from '../../../../../../../generic/imageComponents/ImageComponentHead'
import Image from '../../../../../../../generic/assets/warhouseWork.webp'
import { IProductGroupItem } from '../../../../../../../modules/API/Defaults/models/productGroup'
import SearchProductGroup from '../SearchProductGroup'
import SearchBlockWithTitle from '../../../../procedures/Q8/Layouts/SearchBlockWithTitle'
import SearchCalculationMethod from '../SearchCalculationMethod'

type Props = {
  list: IWarhouseListItem[],
  defaultID: number,
  setWarehouseID: React.Dispatch<React.SetStateAction<number>>,
  productGroupList: IProductGroupItem[]
  defaultProductGroupID: number,
  setProductGroupID: React.Dispatch<React.SetStateAction<number>>,
  defaultPriorCalculationMethod: number,
  setPriorCalculationMethod: React.Dispatch<React.SetStateAction<number>>,
}

const FilterModal = ({
  list,
  defaultID,
  setWarehouseID,
  productGroupList,
  defaultProductGroupID,
  setProductGroupID,
  defaultPriorCalculationMethod,
  setPriorCalculationMethod,

}: Props) => {
  return (
    <MainWrapper>
      <ImageWrapper>
      <ImageComponentHead 
        img={Image}
        large
      />
      </ImageWrapper>
      <SearchBlockWithTitle
        title="חיפוש מחסן"
      >
        <SearchWarehouse
          list={list}
          defaultID={defaultID}
          setWarehouseID={setWarehouseID}
        />
      </SearchBlockWithTitle>
      <SearchBlockWithTitle
        title="חיפוש קבוצת מוצרים"
      >
      <SearchProductGroup 
        defaultID={defaultProductGroupID}
        list={productGroupList}
        setProductGroupID={setProductGroupID}
      />
      </SearchBlockWithTitle>
      <SearchBlockWithTitle
        title="חישוב"
      >
      <SearchCalculationMethod
        defaultID={defaultPriorCalculationMethod}
        setCalculationMethodID={setPriorCalculationMethod}
        placeholder='בחר חישוב'
      />
      </SearchBlockWithTitle>
    </MainWrapper>
  )
}

const MainWrapper = styled.div`
  width: 90%;
  height: 100%;
  display: flex;
  flex-flow: column;
  overflow-y: auto;
  gap: 1em;
  ::-webkit-scrollbar {
    width: 0;
  }
`
const ImageWrapper = styled.div`
  width: 100%;
  height: 45%;
`
export default FilterModal