import React from 'react'
import styled from 'styled-components'
import { ArrowIcon, TimeIcon } from '../../../../../generic/icons/Icons'

type Props = {
  time: string,
  isFinish?: boolean,
  isActive?: boolean,
  isHideFinish?: boolean,
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void
}

const TimeComponent = ({isFinish, isActive, time, onClick, isHideFinish}: Props) => {
  return (
    <TimeItem isFinish={isFinish}>
      {
        !isFinish ?
        <>
          {
            isActive ?
              <>
                {time}
                <TimeIcon />
                <ArrowIcon position={'top'} />
    
              </> :
              <ButtonTime
                  onClick={onClick}
              >
                  התחלה
              </ButtonTime>
          }
          </> 
        :
        <>
        {

          isActive ?
            <>
                {time}
                <TimeIcon />
                <ArrowIcon position={'bottom'} />
            </> : !isHideFinish ?
                <ButtonTime
                onClick={onClick}
                >
                    סיום
                </ButtonTime> : ''
          
            }
        </>
      }
    </TimeItem>
  )
}
const TimeItem = styled.div<{ isFinish?: boolean }>`
    display: flex;
    width: 100%;
    justify-content: flex-end;
    gap: 0.3em;
    color: ${props => props.isFinish ? '#80E081' : '#838484'};

`
const ButtonTime = styled.button<{ isFinish?: boolean }>`
    width: 80%;
    height: 100%;
    padding: 0.3em;
    border: none;
    border-radius: 20px;
    background: #80E081;
    color: #fff;
    cursor: pointer;
    :hover {
        opacity: 0.8;
    }

`
export default TimeComponent