import React, {useState} from 'react'
import { IFreightCompanyItem } from '../../../../../../../modules/API/Defaults/models/freightCompany'
import styled from 'styled-components'
import SearchBlockWithTitle from '../../../../procedures/Q8/Layouts/SearchBlockWithTitle'
import DatePickerModule from '../../../../../../../generic/datePicker/datePicker'
import SearchFreightCompany from './SearchFreightCompany'
import SearchDocTypeDriverReport from './SearchDocTypeDriverReport'
import ControlButton from '../../../../../../../generic/buttons/ControlButton'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../../../store/reducers/rootReducer'
import { LOAD, LOAD_END } from '../../../../../../../../store/actions/actionsTypes'

type Props = {
  freightCompanyList: IFreightCompanyItem[]
}

const DriversReportMenuModal = ({
  freightCompanyList
}: Props) => {
  const dispatch = useDispatch()
  const isTRcompany = useSelector((state: RootState) => state.mainReducer.user?.rights?.includes('TRCOMPANY'))
  const token = useSelector((state: RootState) => state.mainReducer.token)
  const [startDate, setStartDate] = useState<string>('')
  const [endDate, setEndDate] = useState<string>('')
  const [selectedFreightCompany, setSelectedFreightCompany] = useState<number>(0)

  const [selectedTypeID, setSelectedTypeID] = useState<number>(0)
  const isReqFreightCompany = selectedTypeID > 3

  const getExelReport = async () => {
    dispatch({type: LOAD})
    try {
      const res = await fetch(
        `https://my.agroline.co.il/v2/driver/report?DateFrom=${startDate}&DateTo=${endDate}&Type=${selectedTypeID}&FreightCompanyID=${selectedFreightCompany}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        }
        )
        const data = await res.blob()
        const file = new File([data], "report.xlsx", { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = window.URL.createObjectURL(file)
        const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'report.xlsx')
      document.body.appendChild(link)
      link.click()
      link.remove()
      window.URL.revokeObjectURL(url)
    } catch (error) {
      console.log(error)
    } finally {
      
      dispatch({type: LOAD_END})
    }
  } 
  return (
    <MainWrapper>
      <BodyWrapper>
        <SearchBlockWithTitle title={'תאריך'}>
          <DatePickerModule 
            setSelectedDateStart={setStartDate}
            setSelectedDateEnd={setEndDate}
            defEndDate={false}
            disabled={false}
            multyDate
            large
            ignorBrod
            selected={false}
          />
        </SearchBlockWithTitle>
        <SearchBlockWithTitle title={'סוג דוח'}>
          <SearchDocTypeDriverReport 
            defaultID={selectedTypeID}
            setDocTypeID={setSelectedTypeID}
            required
          />
        </SearchBlockWithTitle>
        {
          !isTRcompany ?
            <SearchBlockWithTitle title={'חברת הובלה'}>
              <SearchFreightCompany 
                list={freightCompanyList}
                defaultID={selectedFreightCompany}
                setFreightCompanyID={setSelectedFreightCompany}
                required={isReqFreightCompany}
              />
            </SearchBlockWithTitle> : ''

        }
      </BodyWrapper>
      {
        isReqFreightCompany ?
         selectedFreightCompany !== 0 ?
            <ControlButton 
              lable='הצג דוח'
              handleClick={getExelReport}
            /> : '' 
              :
              <ControlButton 
                lable='הצג'
                handleClick={getExelReport}
              />
      }
    </MainWrapper>
  )
}
const MainWrapper = styled.div`
  width: 90%;
  height: 90%;
  display: flex;
  flex-direction: column;
  gap: 1em;
`
const BodyWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1em;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 0;
  }
`
export default DriversReportMenuModal