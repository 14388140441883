import React, {useMemo} from 'react'
import styled from 'styled-components'
import { IMarketPallet, IMarketPalletDetails } from '../../../../../../../modules/API/Market/models/marketRequests'
import TitleRow from '../../../../kosherTeudot/generic/TitleRow'
import TitleRowItem from '../../../../kosherTeudot/generic/TitleRowItem'
import { AcceptIcon, DeleteIcon, PackageIcon, PalletIcon, PrintIcon, ScalesIcon, ShekelIcon, VerticalDots } from '../../../../../../../generic/icons/Icons'
import TitlesList from '../../../../../../../generic/titlesRowItem/TitlesList'
import ProductItem from './ProductItem'
import AddClientButton from '../AddClientButton'
import BodyScrollContainer from '../../../../../../../generic/Layouts/Wrappers/BodyScrollContainer'
import { useDetectOutsideClick } from '../../../../../../../generic/hooks/useDetectOutsideClick'

interface IProps extends IMarketPallet {
    selectProduct: (data: IMarketPalletDetails) => void;
    handleSelectForPallet: (pallet: number, client: number, name: string, seller: number) => void;
    item: IMarketPallet;
    CustomerSeller: string;
    isWorker: boolean;
    printPallet: (pallet: number) => void
    deletePallet: (pallet: number, client: string) => void;
    deleteProduct: (pallet: number, client: string, redID: number) => void;
    isDisabledAddNewPalletProduct: boolean;
}

const PalletItem = ({
    Pallet_Num, Details, selectProduct, item, printPallet,
    handleSelectForPallet, CustomerSeller, deleteProduct, deletePallet, 
    isWorker, isDisabledAddNewPalletProduct
}: IProps) => {
    const [isShowDelete, setIsShowDelete] = React.useState(false)
    const [isOpenMenu, setIsOpenMenu] = React.useState(false)
    const refSwitcher = React.useRef(null)

    const isDisableTodeletePallet = useMemo(() => {
        if (!Details) return false
        return Details.some(item => item.InvoiceSale !== 0)
    }, [Details])

    const handleOpenMenu = (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation()
        setIsOpenMenu(!isOpenMenu)
    }

    const handleSwitchDeleteIcon = (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation()
        setIsShowDelete(!isShowDelete)
    }
    const handleDeletePallet = (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation()
        if(isDisableTodeletePallet) return
        deletePallet(Pallet_Num, CustomerSeller)
    }
    const detailes = useMemo(() => {
        let weight = 0;
        let packs = 0;
        let prise = 0;
        if (!Details) return {
            prise, weight, packs
        }
        Details.forEach(item => {
            weight += item.WeightNeto
            packs += item.Quantity
            prise += item.Type_SaleCalculation === 0 ?
                item.WeightNeto * item.SalePrice :
                    item.Quantity * item.SalePrice
        })
        return {
            prise: prise.toFixed(1), 
            weight: weight.toFixed(1), 
            packs: packs
        }
    }, [Details])
    const handleSelectPallet = () => {
        if(isDisabledAddNewPalletProduct) return
        handleSelectForPallet(
            Pallet_Num, 
            item.CustomerID, 
            item.Customer_Name?.trim(),
            Number(CustomerSeller.split('-')[1])
        )
    }
    const handlePrintPallet = () => {
        printPallet(Pallet_Num)
    }
    const productProps = {
        selectProduct: selectProduct,
        deleteProduct: deleteProduct,
        CustomerID: item.CustomerID,
        CustomerSeller: CustomerSeller,
        isWorker: isWorker,
        itemPallet: item
    }
    useDetectOutsideClick(refSwitcher, () => {setIsOpenMenu(false)})
  return (
    <MainWrapper>
        <MenuWrapper
            ref={refSwitcher} 
            onClick={handleOpenMenu}
        >
            <VerticalDots />
            <MenuBody isOpen={isOpenMenu}>
                <IconWrapper >
                    <IconWrapper  onClick={handlePrintPallet}>
                        <PrintIcon />
                    </IconWrapper>
                               </IconWrapper>
                <IconWrapper >
                {   
                    !isDisableTodeletePallet ?
                    !isShowDelete ?
                    <IconWrapper isPrimal  onClick={handleSwitchDeleteIcon}>
                        <DeleteIcon />
                    </IconWrapper>
                    :
                    <IconWrapper isPrimal onClick={handleDeletePallet}>
                        <AcceptIcon />
                    </IconWrapper> : ''

                }
                </IconWrapper>
            </MenuBody>
        </MenuWrapper>
        
        <TitleWrapper>
            <TitleRow>
                <TitleRowItem name={detailes?.weight.toString() || "0"} icon={<ScalesIcon />} />
                <TitleRowItem name={Pallet_Num.toString()} icon={<PalletIcon />} />
            </TitleRow>
            <TitleRow>
                {
                    !isWorker &&
                        <TitleRowItem name={detailes?.prise.toString() || "0"} icon={<ShekelIcon />} />
                }
                <TitleRowItem name={detailes?.packs.toString() || "0"} icon={<PackageIcon />} />
            </TitleRow>
        </TitleWrapper>
            <BodyScrollContainer>
                <ProductListWrapper>
                    <AddClientButton
                        hight='40px'
                        size='full'
                        onClick={handleSelectPallet}
                        disabled={isDisabledAddNewPalletProduct}
                    />
                </ProductListWrapper>
                    {
                        Details?.length > 0 &&
                        <TitlesList 
                            list={Details}
                            uniqueKey={'RecID'}
                            noPadding={false}
                            Element={ProductItem}
                            additionalData={productProps}
                            borderLess
                        />
                    }
            </BodyScrollContainer>
    </MainWrapper>
  )
}
const MainWrapper = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    flex-flow: column;
    gap: 1em;
    border-radius: 30px;
    direction: ltr;
    background: #E9F3F2;
    padding: 0.5em;
`
const MenuWrapper = styled.div`
    position: absolute;
    left: 0.5em;
    top: 0.5em;
    width: 2em;
    height: 2em;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #297f76;
    cursor: pointer;
`
const MenuBody = styled.div<{isOpen: boolean}>`
    position: absolute;
    display: ${props => props.isOpen ? 'flex' : 'none'};
    justify-content: space-evenly;
    top: 1.5em;
    left: 1em;
    width: 5em;
    padding: 0.4em;
    border-radius: 10px;
    background: #fff;
    gap: 0.5em;
    
`

const IconWrapper = styled.div<{isPrimal?: boolean}>`
    /* width: 2em;
    height: 2em; */
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.3s;
    border-radius: 50%;
    background-color: #fff;
    color: ${props => props.isPrimal ? 'tomato' : '#297F76'} ;
    
    cursor: pointer;
    
`
const TitleWrapper = styled.div`
    width: 100%;
    border-radius: 15px;
    background: #E9F3F2;
    display: flex;
    flex-flow: column;
    gap: 0.5em;

`
const ProductListWrapper = styled.div`
    background: #fff;
    border-radius: 30px 30px 0 0;
    padding: 0.5em ;
    
`
export default PalletItem