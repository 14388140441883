import React, { useMemo, useState } from 'react'
import MarketMainLayout from '../../market/Collect/Layout/MarketMainLayout'
import Q4Layout from '../../procedures/Q4/Layouts/Q4Layout'
import ControlSearchPannelLayout from '../../../../../generic/Layouts/Wrappers/ControlSearchPannelLayout'
import DatePickerISO from '../../forcasts/Distribution/Components/DatePickerISO'
import SearchInput from '../../../../../generic/inputs/inputSearch'
import Table from '../../procedures/Q8/Layouts/Table/Table'
import TableHead from '../../procedures/Q8/Layouts/Table/TableHead'
import TableBody from '../../procedures/Q8/Layouts/Table/TableBody'
import useDataFetcher from '../../knisot/hooks/useFetchData'
import { IProductWithCounItem } from '../../../../../modules/API/Products/models/productsWithCount'
import { Product } from '../../../../../modules/API/Products/products'
import { format } from 'date-fns'
import TableHeadCell from '../../procedures/Q8/Layouts/Table/TableHeadCell'
import OrderCountBodyTableRow from './components/OrderCountBodyTableRow'
import useConfigCount from './useConfigCount'
import { titleByCalcMethod } from './helpers/titleByCalcMethod'
import { sortBySelectedField } from './helpers/sortBySelectedField'

const OrderCount = () => {
    const dayPlus = () => {
        const date = new Date()
        date.setDate(date.getDate() + 1)
        return date
    }
    const [date, setDate] = useState<Date>(() => dayPlus())
    const [search, setSearch] = useState<string>('')
    const [sortID, setSortID] = useState<number>(0)
    const data = useConfigCount()

    const {
        data: productList,
        setData: setProductList
    } = useDataFetcher<IProductWithCounItem[]>(
        Product.getProductListWithConts, format(date, 'yyyyMMdd')
    )

    const handleSort = (sort: number) => {
        if(!productList) return
        let finalResult = [...productList]
        if(sortID === sort) {
            finalResult = sortBySelectedField(finalResult, sort, true) 
        } else {
            setSortID(sort)
            finalResult = sortBySelectedField(finalResult, sort, false)
        }
        setProductList(finalResult)
    }

    const handleDateChange = (date: Date) => {
        setDate(date)
    }

    const render = useMemo(() => {
        if(!productList) return []
        return productList.filter(e => 
            `${e.ProductDesc.trim()} ${e.VarietyDesc.trim()}`.toLowerCase().includes(search.toLowerCase())
        )
    }, [productList, search])
    

  return (
    <MarketMainLayout
        title='דו"ח חוסרים'
    >
        <ControlSearchPannelLayout>
            <DatePickerISO
                selected={date}
                onChange={handleDateChange}
            />
            <SearchInput 
                input={search}
                setInput={setSearch}
            />
        </ControlSearchPannelLayout>
        <Q4Layout>
            <Table>
                <TableHead>                    
                    {
                        titleByCalcMethod(data.calculationMethod).map((e, i) =>
                            <TableHeadCell 
                                key={e.sortingID}
                                onClick={() => handleSort(e.sortingID)}
                            >
                                {e.titleName}
                            </TableHeadCell>
                        
                        )
                    }

                </TableHead>
                <TableBody>
                    {
                        render.map(item =>
                            <OrderCountBodyTableRow 
                                key={`${item.ProductID}-${item.VarietyID}`}
                                {...item}
                                calculationMethod={data.calculationMethod}
                            />
                        )
                    }
                </TableBody>
            </Table>
        </Q4Layout>
    </MarketMainLayout>
  )
}

export default OrderCount