import React, { useContext } from 'react'
import { IPostProductsMlaiRequest, IProductMlaiForCreate } from '../../../../../modules/API/Mlai/models/productsForCreate'
import { useDispatch } from 'react-redux'
import { LOAD, LOAD_END } from '../../../../../../store/actions/actionsTypes'
import { Mlai } from '../../../../../modules/API/Mlai/mlai'
import { useHistory } from 'react-router-dom'
import { LINKS } from '../../../../../Routs/config'
import { ITitleMlaiItem } from '../../../../../modules/API/Mlai/models/title'
import { addIDForTheList } from '../../../../../generic/utils/addUIDForTheList'
import { ITitleMlaiItemWithDetails } from '../../../../../modules/API/Mlai/models/details'
import { ContextModal } from '../../../../../modules/modal/modalContext'


const useMlaiController = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [modal, setModal] = useContext(ContextModal)

  const handleModal = (mood: boolean, message: string) => {
    setModal({
      open: true,
      value: message,
      mood: mood
    })
    setTimeout(() => {
      setModal({
          ...modal,
          open: false
      })
    }, 3000)
  }

  const postProducts = async (
    list: IProductMlaiForCreate[], 
    date: string,
    tranzit: number,
    id: string,
    type: number
  ) => {
    // if(!productsList) return
    dispatch({type: LOAD})
    try {
      const dataPost: IPostProductsMlaiRequest = {
        DateStock: date,
        TypeStock: type,
        TranzitID: tranzit,
        Details: list.filter(line => line.NumPackagesActulal > 0).map(item => {
          let {id, isEdited, tempQty, tempWeight, codeSrika, ...rest} = item
          return {
            ...rest
          }
        })
      }
      let data = await Mlai.postProducts(dataPost)
      history.push(LINKS.mlai.mlai.mainURL + `/create/${id}/${data.CodeStock}`)
      // history.push(LINKS.mlai.mlai.mainURL)
      handleModal(true, ' נוספו בהצלחה')
    } catch (error) {
      handleModal(false, 'שגיאה בהוספת המוצרים')
    } finally {
      dispatch({type: LOAD_END})
    }
  }

  const putProducts = async (
    list: IProductMlaiForCreate[],
    titleData: ITitleMlaiItem,
    subID: string,
  ) => {
    dispatch({type: LOAD})
    try {
      const dataFormed = {
        ...titleData,
        Details: list.map(item => {
          let {id, isEdited, tempQty, tempWeight, ...rest} = item
          return {
            ...rest
          }
        })
      }
      const data = await Mlai.putProducts(dataFormed, Number(subID))
      const withID: IProductMlaiForCreate[] = addIDForTheList(data.Details)
      handleModal(true, ' נשמר בהצלחה')
      return withID
    } catch {
      handleModal(false, 'שגיאה בשמירת המילאי')
    } finally {
      dispatch({type: LOAD_END})
    }
  }
  const changeQty = (
    list: IProductMlaiForCreate[], 
    qty: number, 
    item: IProductMlaiForCreate
  ) => {
    const newList = [...list]
    const index = newList.findIndex(i => i.id === item.id)
    newList[index].NumPackagesActulal = qty
    newList[index].WeightActual = qty * newList[index].MiddleWeight
    newList[index].isEdited = true
    return newList
  }

  return {
    postProducts,
    putProducts,
    changeQty,
    handleModal
  }
}

export default useMlaiController