import React, {useState, useMemo, useEffect, useContext} from 'react'
import { useDispatch } from 'react-redux'
import { IWarhouseListItem } from '../../../../../modules/API/Defaults/models/warhouseList'
import { IProductMlaiForCreate } from '../../../../../modules/API/Mlai/models/productsForCreate'
import { Mlai } from '../../../../../modules/API/Mlai/mlai'
import ControlSearchPannelLayout from '../../../../../generic/Layouts/Wrappers/ControlSearchPannelLayout'
import DatePickerModule from '../../../../../generic/datePicker/datePicker'
import SearchInput from '../../../../../generic/inputs/inputSearch'
import BodyScrollContainer from '../../../../../generic/Layouts/Wrappers/BodyScrollContainer'
import TitlesList from '../../../../../generic/titlesRowItem/TitlesList'
import ProductItem from './ProductItem'
import { useHistory, useParams } from 'react-router-dom'
import { LOAD, LOAD_END } from '../../../../../../store/actions/actionsTypes'
import ControlButton from '../../../../../generic/buttons/ControlButton'
import styled from 'styled-components'
import { LINKS } from '../../../../../Routs/config'
import WarehouseFilter from './WarehouseFilter'
import { addIDForTheList } from '../../../../../generic/utils/addUIDForTheList'
import FlexColumnLayout from '../Layouts/FlexColumnLayout'
import { ContextMlai } from '../Context/MlaiContext'
import useBeforeUnload from '../../../../../generic/hooks/useBeforeUnload'
import { ITitleMlaiItem } from '../../../../../modules/API/Mlai/models/title'
import MlaiTitleItem from '../Components/MlaiTitleItem'
import useMlaiController from '../hooks/useMlaiController'


const MlaiCreateMain = () => {
  const dispatch = useDispatch()
  const {id, subID} = useParams<{id: string, subID: string}>()

  const history = useHistory()
  
  const {
    date,
    setDate,
    tranzit,
    isCustomer,
    warehouseList,
    defaultWarehouse
  } = useContext(ContextMlai)

  const {
    postProducts,
    putProducts,
    changeQty
  } = useMlaiController()

  const [productsList, setProductsList] = useState<IProductMlaiForCreate[]>([])
  const [selectedTranzit, setSelectedTranzit] = useState<IWarhouseListItem | null>(null)
  const [titleData, setTitleData] = useState<ITitleMlaiItem | null>(null)

  const [input, setInput] = useState<string>('')

  
  const getProducts = async (tranzitID: number | string) => {
    if(subID !== '0') return
    dispatch({type: LOAD})
    try {
      const res = await Mlai.getProductsForCreate(tranzitID.toString())
      setProductsList(addIDForTheList(res))
    } catch (error) {
      
    } finally {
      dispatch({type: LOAD_END})
    }
  }

  const handlePostProducts = async () => {
    if(!productsList) return
    
    const tranzitID = isCustomer ? Number(tranzit || 0) : Number(selectedTranzit?.WarehouseID || 0)
    postProducts(
      productsList,
      date,
      tranzitID,
      id,
      0
    ) 
  }

  const handlePutProduct = async () => {
    if(!titleData) return
    try {
      const result = await putProducts(productsList, titleData, subID)
      if(result) {
        setProductsList(result)
      }
    } catch {}
  }


  const handleSaveFunc = async (item: IProductMlaiForCreate) => {
    const newArr = [...productsList]
    const index = newArr.findIndex(i => i.id === item.id)
    newArr[index].isEdited = false
    setProductsList(newArr)
    if(subID !== '0') {
      await handlePutProduct()
    } else {
      await handlePostProducts()
    }
  }

  const saveAndExit = async () => {
    if(subID !== '0') {
      await handlePutProduct()
      history.push(LINKS.mlai.mlai.mainURL)

    } else {
      await handlePostProducts()
    }
  }

  const handleChangeQty = (qty: number, item: IProductMlaiForCreate) => {
    if(!productsList) return
    const result = changeQty(productsList, qty, item)
    setProductsList(result)
  }

  const getMlaiData = async (subID: number) => {
    dispatch({type: LOAD})
    try {
      const res = await Mlai.getMlaiItem(subID)
      setTitleData(res)
      setProductsList(addIDForTheList(res.Details))
    } catch {

    } finally {
      dispatch({type: LOAD_END})
    }
  }

  const renderProducts = useMemo(() => {
    if(!productsList) return []
    return productsList.filter(item => 
      `${item.ProductDesc.trim()} ${item.VarietyDesc.trim()}`.includes(input)
    )
  }, [productsList, input])

  const productProps = {
    handleChangeQty: handleChangeQty,
    handleSaveFunc: handleSaveFunc
  }

  useEffect(() => {
    if(isCustomer ? !tranzit : !selectedTranzit  ) return setProductsList([])
    const tranzitID = isCustomer ? (tranzit || 0) : (selectedTranzit?.WarehouseID || 0)
    getProducts(tranzitID || defaultWarehouse)
  }, [tranzit, selectedTranzit, defaultWarehouse])


  useEffect(() => {
    if(subID !== '0') {
      getMlaiData(Number(subID))
    }
  }, [subID])

  useBeforeUnload()
  return (
    <FlexColumnLayout>
      {

        subID !== '0' && titleData ?
        <>
        <MlaiTitleItem
          TypeStock={titleData.TypeStock || 0}
          RecID={titleData.RecID || 0}
          Remarks={titleData.Remarks || ''}                                                                                                                                                                                     
          Season={titleData.Season || 0}
          StatusStock={titleData.StatusStock || 0}
          TimeStock={titleData.TimeStock || ""}
          TranzitID={titleData.TranzitID || 0}
          WorkerID={titleData.WorkerID || 0}
          CodeStock={titleData.CodeStock || 0}
          DateStock={titleData.DateStock || ''}
          WarehouseDesc={titleData.WarehouseDesc || ''}
        />
          <ControlSearchPannelLayout>
            <SearchInput 
              input={input}
              setInput={setInput}
            />
          </ControlSearchPannelLayout>
        </>
        : 
        <ControlSearchPannelLayout>
      
          <DatePickerModule
            setSelectedDateStart={setDate}
            setSelectedDateEnd={false}
            defEndDate={false}
            selected={false}
            ignorBrod={true}
            disabled={false}
            large
          />
          <SearchInput 
            input={input}
            setInput={setInput}
          />
          {
            !isCustomer &&
              <WarehouseFilter
                defaultID={defaultWarehouse}
                list={warehouseList || []}
                selectedWarehouse={selectedTranzit}
                setSelectedWarehouse={setSelectedTranzit}
              />
            
          }
        </ControlSearchPannelLayout>
      }
      <BodyScrollContainer>
        <TitlesList 
          list={renderProducts}
          uniqueKey={'id'}
          noPadding={false}
          Element={ProductItem}
          additionalData={productProps}
        />
      </BodyScrollContainer>
        <ControlWrapper>
          <ControlButton
            lable={'חזרה'}
            handleClick={() => history.push(LINKS.mlai.mlai.mainURL)}
          />
          <ControlButton 
            lable={'סיום'}
            handleClick={saveAndExit}
          />
        </ControlWrapper>
    </FlexColumnLayout>
  )
}
export default MlaiCreateMain

const ControlWrapper = styled.div`
    width: 100%;
    display: flex;
    gap: 0.5em;
`