import { v4 } from 'uuid'
import { IWarhouseListItem } from "../../../../../modules/API/Defaults/models/warhouseList";
import { Gathering } from "../../../../../modules/API/Gathering/gathering";
import { IProductMlaiForCreate } from "../../../../../modules/API/Mlai/models/productsForCreate";
import { IResponseSrikaNode } from '../../../../../modules/API/Gathering/models/srika';
interface IScanHandler {
  selectedTranzit: number | string | null | undefined | IWarhouseListItem
  productsList: IProductMlaiForCreate[]
  setProductsList: React.Dispatch<React.SetStateAction<IProductMlaiForCreate[]>>
  input: string
  setInput: React.Dispatch<React.SetStateAction<string>> | ((value: string) => void)
  handleModal: (mood: boolean, message: string) => void
  handleControlModal: () => void
  setSrikaProducts: React.Dispatch<React.SetStateAction<IProductMlaiForCreate | null>>
}

export const handleScan = async ({
  selectedTranzit,
  productsList,
  setProductsList,
  input,
  setInput,
  handleModal,
  handleControlModal,
  setSrikaProducts
}: IScanHandler): Promise<void> => {
  if (!selectedTranzit) {
    handleModal(false, `אנא בחר מחסן`);
    return
  }
  // Check if input has something other than numbers (English and Hebrew)
  if (input.match(/[a-z\u0590-\u05FF]/i) && !input.includes('#')) return;

  try {
    let res = {} as IResponseSrikaNode
    const permanentCode = input.split('#')
    if(permanentCode.length === 1) {
      res = await Gathering.getKnisaByBarcode(input);
    } else {
      res = await Gathering.getPermanentKnisaByBarcode(permanentCode[1])
    }
    // const findInTheList = productsList.find(
    //   item => item.ProductID === res.entryLine[0].ProductID &&
    //           item.VarietyID === res.entryLine[0].VarietyID &&
    //           item.GrowerID === res.entryLine[0].GrowerID &&
    //           item.SizeID === res.entryLine[0].SizeDesc &&
    //           item.PackingID === res.entryLine[0].PackID
              
              
    // );
    const findInTheList = productsList.find(
      item => item.codeSrika === input       
    );
    
    if (findInTheList) {
      setInput(`${findInTheList.ProductDesc.trim()} ${findInTheList.VarietyDesc.trim()}`);
    } else {

      const newProduct: IProductMlaiForCreate = {
        GeneralProductID: res.entryLine[0].GeneralProductID,
        NumPackagesActulal: 0,
        ProductDesc: res.entryLine[0].ProductDesc,
        ProductID: res.entryLine[0].ProductID,
        VarietyDesc: res.entryLine[0].VarietyDesc,
        VarietyID: res.entryLine[0].VarietyID,
        WeightActual: res.entryLine[0].Gross,
        GrowerID: res.entryLine[0].GrowerID,
        GrowerName: res.entryLine[0].GrowerName,
        SizeID: res.entryLine[0].SizeDesc,
        PackingID: res.entryLine[0].PackID,
        tempQty: 0,
        tempWeight: 0,
        isEdited: false,
        Price: 0,
        id: v4(),
        CodeStock: 0,
        EntryPackages: 0,
        EntryPallets: 0,
        EntryWeight: 0,
        ExitPackages: 0,
        ExitPallets: 0,
        ExitWeight: 0,
        MonetaryValue: 0,
        NumPackagesReg: 0,
        NumPalletsActual: 0,
        NumPalletsReg: 0,
        QualityID: 0,
        RecID: 0,
        Remarks: '',
        Season: 0,
        WeightReg: 0,
        codeSrika: input,
        MiddleWeight: res.entryLine[0].AvgWeightPerPack
      };
      setSrikaProducts(newProduct);
      setInput('')
      handleControlModal()

    }
  } catch (error: any) {

    if(error.response.status >= 500) {
      
      handleModal(false, `שגיאה במהלך הסריקה`);
    }
  }
};


