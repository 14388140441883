import React from "react";
import { IDataTItlePrahim } from "../types/contextTitle";

const initialState = {
    date: new Date(),
    handleDate: (date: Date) => {},
    dataTitle: {} as IDataTItlePrahim,
    handleDataTitle: (dataTitle: IDataTItlePrahim) => {},
    printPallet: (id: number) => {},
}
// create context prahim
export const ContextPrahim = React.createContext(initialState)
