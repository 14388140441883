import React, { useMemo } from 'react'
import { IProductMlaiForCreate } from '../../../../../modules/API/Mlai/models/productsForCreate'
import styled from 'styled-components'
import ProductItem from '../Create/ProductItem'
import ControlButton from '../../../../../generic/buttons/ControlButton'
import ProductImage from '../../queries/TeudotDetails/Components/ProductImage'
import TitleRow from '../../kosherTeudot/generic/TitleRow'
import TitleRowItem from '../../kosherTeudot/generic/TitleRowItem'
import { AppleIcon, PackageIcon, UserIcon } from '../../../../../generic/icons/Icons'
import RowInput from '../../../../../generic/inputs/RowInput'

type Props = {
  item: IProductMlaiForCreate,
  setItem: React.Dispatch<React.SetStateAction<IProductMlaiForCreate>>,
  list: IProductMlaiForCreate[],
  handleSave: (item: IProductMlaiForCreate) => void
}

const ProductSrikaModal = ({
  item, list, setItem, handleSave
}: Props) => {

  const renderList = useMemo(() => {
    return list.filter(listItem => 
      listItem.ProductID === item.ProductID &&
      listItem.VarietyID === item.VarietyID &&
      listItem.GrowerID === item.GrowerID &&
      listItem.SizeID === item.SizeID 
    )
  }, [list, item])
  const handleChangeQty = (qty: React.ChangeEvent<HTMLInputElement>) => {
    const packQty = Number(qty.target.value)
    // if (isNaN(packQty)) return
    setItem({
      ...item,
      NumPackagesActulal: packQty,
      WeightActual: packQty * item.MiddleWeight
    })

  }
  const handleSaveItem = () => {
    handleSave(item)
  }
  return (
    <MainWrapper>
        <NewItemWrapper>
      <ImageWrapper>
        <ProductImage 
          id={item.GeneralProductID}
        />
      </ImageWrapper>

        <TitleRow>
          <TitleRowItem 
            icon={<AppleIcon />}
            name={`${item.ProductDesc.trim()} ${item.VarietyDesc.trim()} ${item.SizeID.trim()}`}
            />
        </TitleRow>
        <TitleRow>
          <TitleRowItem 
            icon={<UserIcon />}
            name={`${item.GrowerName.trim()}`}
            />
        </TitleRow>
        <TitleRow>
          <RowInput 
            value={item.NumPackagesActulal}
            handleInput={handleChangeQty}
            icon={<PackageIcon />}
            isDisabled={false}
          />
          
        </TitleRow>

    </NewItemWrapper>
     {

        renderList.length > 0 ?
        <NewItemWrapper>

        <TitleRowItem 
              icon={<></>}
              name={`במלאי`}
        />
        
        <>
          <BodyWrapper>
            {
              renderList.map((item, index) => (
                <ProductItem 
                key={index}
                item={item}
                index={index}
                {...item}
                handleChangeQty={() => {}}
                disabled
                />
              ))
            }
          </BodyWrapper>
        </>
        </NewItemWrapper> : <NewItemWrapper></NewItemWrapper>
     }

      <ControlButton 
        lable={'שמור'}
        handleClick={handleSaveItem}
      />
    </MainWrapper>
  )
}

const MainWrapper = styled.div`
  width: 90%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5em;
`
const NewItemWrapper = styled.div`
  padding: 2em 0.5em;
  width: 100%;
  height: 40%;
  display: flex;
  flex-flow: column;
  gap: 1em;
  align-items: flex-end;
  border-radius: 30px;
  background-color: rgba(245, 245, 245, 0.6);
`
const ImageWrapper = styled.div`
  width: 100%;
  height: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
`
const BodyWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 0;
  }
`
export default ProductSrikaModal