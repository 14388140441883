import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import FlexColumnLayout from '../Layouts/FlexColumnLayout'
import ControlSearchPannelLayout from '../../../../../generic/Layouts/Wrappers/ControlSearchPannelLayout'
import DatePickerModule from '../../../../../generic/datePicker/datePicker'
import { ContextMlai } from '../Context/MlaiContext'
import { ContextModal } from '../../../../../modules/modal/modalContext'
import { IPostProductsMlaiRequest, IProductMlaiForCreate } from '../../../../../modules/API/Mlai/models/productsForCreate'
import { IWarhouseListItem } from '../../../../../modules/API/Defaults/models/warhouseList'
import WarehouseFilter from './WarehouseFilter'
import BodyScrollContainer from '../../../../../generic/Layouts/Wrappers/BodyScrollContainer'
import TitlesList from '../../../../../generic/titlesRowItem/TitlesList'
import ProductItem from './ProductItem'
import ControlWrapper from '../Layouts/ControlWrapper'
import ControlButton from '../../../../../generic/buttons/ControlButton'
import { useHistory, useParams } from 'react-router-dom'
import { handleScan } from '../utils/scanHandler'
import { useDispatch } from 'react-redux'
import { LOAD, LOAD_END } from '../../../../../../store/actions/actionsTypes'
import { Mlai } from '../../../../../modules/API/Mlai/mlai'
import { LINKS } from '../../../../../Routs/config'
import useScannedDataHandler from '../../kosherTeudot/Hooks/useScannedDataHandler'
import ScanInput from '../../kosherTeudot/Components/ScanInput'
import { ITitleMlaiItem } from '../../../../../modules/API/Mlai/models/title'
import MlaiTitleItem from '../Components/MlaiTitleItem'
import ColumModal from '../../../../../generic/Layouts/ColumModal'
import ProductSrikaModal from '../Components/ProductSrikaModal'
import { addIDForTheList } from '../../../../../generic/utils/addUIDForTheList'
import useMlaiController from '../hooks/useMlaiController'

interface IRef {
  focusInput: () => void;
  blurInput: () => void;
}

const MlaiCreateByScan = () => {

  const history = useHistory()
  const {
    date,
    setDate,
    tranzit,
    isCustomer,
    warehouseList,
    defaultWarehouse
  } = useContext(ContextMlai)

  const {
    postProducts,
    putProducts,
    changeQty,
    handleModal
  } = useMlaiController()


  const dispatch = useDispatch()
  const refBody = useRef<IRef | null>(null);
  const {id, subID} = useParams<{id: string, subID: string}>()

  const [productsList, setProductsList] = useState<IProductMlaiForCreate[]>([])
  const [srikaProducts, setSrikaProducts] = useState<IProductMlaiForCreate | null>(null)
  const [selectedTranzit, setSelectedTranzit] = useState<IWarhouseListItem | null>(null)
  const [titleData, setTitleData] = useState<ITitleMlaiItem | null>(null)
  const [input, setInput] = useState<string>('')
  const [isOpenSrikaModal, setIsOpenSrikaModal] = useState<boolean>(false)

  

  const handleScanFunc = async () => {
    handleScan({
      selectedTranzit: selectedTranzit || titleData?.TranzitID,
      productsList,
      setProductsList,
      input,
      setInput,
      handleModal,
      handleControlModal,
      setSrikaProducts

    })
  }

  const getMlaiData = async (subID: number) => {
    dispatch({type: LOAD})
    try {
      const res = await Mlai.getMlaiItem(subID)
      setTitleData(res)
      setProductsList(addIDForTheList(res.Details))
    } catch {

    } finally {
      dispatch({type: LOAD_END})
    }
  }

  const handleChangeQty = (qty: number, item: IProductMlaiForCreate) => {
    if(!productsList) return
    const result = changeQty(productsList, qty, item)
    setProductsList(result)
  }

  const handlePostProduct = async (list?: IProductMlaiForCreate[]) => {
    if (!productsList) return
    const listDitails = list ? list : productsList
    const tranzitID = isCustomer ? Number(tranzit || 0) : Number(selectedTranzit?.WarehouseID || 0)

    postProducts(
      listDitails,
      date,
      tranzitID,
      id,
      1
    )
  }

  const handlePutProduct = async (list?: IProductMlaiForCreate[]) => {
    if(!titleData) return
    const listDitails = list ? list : productsList
    try {
      const result = await putProducts(listDitails,titleData,subID)
      if(result) {
        setProductsList(result)
      }
    } catch {

    }
  }
  
  const renderProducts = useMemo(() => {
    if (!productsList) return []
    return productsList.filter(item =>
      `${item.ProductDesc.trim()} ${item.VarietyDesc.trim()}`.includes(input)
      ||
      item.GrowerName?.trim().includes(input)
    )
  }, [productsList, input])

  const handleSaveSrika = async (data: IProductMlaiForCreate) => {
    if(!srikaProducts) return
    let newList = [...productsList]
    const index = newList.findIndex(
        item => item.ProductID === srikaProducts.ProductID &&
              item.VarietyID === srikaProducts.VarietyID &&
              item.GrowerID === srikaProducts.GrowerID &&
              item.SizeID?.trim() === srikaProducts.SizeID?.trim() &&
              item.PackingID === srikaProducts.PackingID
    )

    if (index === -1) {
      // append data
      newList = [srikaProducts, ...newList]
    } else {
      // update data
      newList[index] = {
        ...srikaProducts,
        RecID: newList[index].RecID,
        NumPackagesActulal: newList[index].NumPackagesActulal + srikaProducts.NumPackagesActulal,
        WeightActual: newList[index].WeightActual + srikaProducts.WeightActual
      }
      
    }
    setProductsList(newList)
    if(subID !== '0') {
      await handlePutProduct(newList)
    } else {
      await handlePostProduct(newList)
    }
    setIsOpenSrikaModal(false)
  }

  const handleSaveFunc = async (item: IProductMlaiForCreate) => {
    const newArr = [...productsList]
    const index = newArr.findIndex(i => i.id === item.id)
    newArr[index].isEdited = false
    setProductsList(newArr)
    if(subID !== '0') {
      await handlePutProduct()
    } else {
      await handlePostProduct()
    }
  }
  
  const saveAndExit = async () => {
    if(subID !== '0') {
      await handlePutProduct()
      history.push(LINKS.mlai.mlai.mainURL)
    } else {
      await handlePostProduct()
    }
  }

  const handleControlModal = () => {
    setIsOpenSrikaModal(!isOpenSrikaModal)
  }

  const productProps = {
    handleChangeQty: handleChangeQty,
    handleSaveFunc: handleSaveFunc
  }

  const srikaModalProps = {
    item: srikaProducts,
    setItem: setSrikaProducts,
    list: productsList,
    handleSave: handleSaveSrika
  }

  useEffect(() => {
    if(subID !== '0') {
      getMlaiData(Number(subID))
    }
  }, [subID])
  
  useScannedDataHandler(refBody, handleScanFunc)
  return (
    <FlexColumnLayout>
      {
        isOpenSrikaModal &&
        <ColumModal 
          childrenProps={srikaModalProps}
          Children={ProductSrikaModal}
          controlFunc={handleControlModal}
          title={srikaProducts?.codeSrika || ''}
        />
      }
      <>
      {
        subID !== '0' && titleData ?
        <>
        <MlaiTitleItem
          TypeStock={titleData.TypeStock || 0}
          RecID={titleData.RecID || 0}
          Remarks={titleData.Remarks || ''}                                                                                                                                                                                     
          Season={titleData.Season || 0}
          StatusStock={titleData.StatusStock || 0}
          TimeStock={titleData.TimeStock || ""}
          TranzitID={titleData.TranzitID || 0}
          WorkerID={titleData.WorkerID || 0}
          CodeStock={titleData.CodeStock || 0}
          DateStock={titleData.DateStock || ''}
          WarehouseDesc={titleData.WarehouseDesc || ''}
          />
          <ControlSearchPannelLayout>

          <ScanInput
            input={input}
            setInput={setInput}
            enterFunc={handleScanFunc}
            ref={refBody}
            
            />
            </ControlSearchPannelLayout>
        </>
        : 
        <>
        
        <ControlSearchPannelLayout>
          <DatePickerModule
            setSelectedDateStart={setDate}
            setSelectedDateEnd={false}
            defEndDate={false}
            selected={false}
            ignorBrod={true}
            disabled={false}
            large
          />
          
          {
            !isCustomer &&
            <WarehouseFilter
              list={warehouseList || []}
              selectedWarehouse={selectedTranzit}
              setSelectedWarehouse={setSelectedTranzit}
              defaultID={defaultWarehouse}
            />
          }
        </ControlSearchPannelLayout> 
        <ControlSearchPannelLayout>

        <ScanInput
          input={input}
          setInput={setInput}
          enterFunc={handleScanFunc}
          ref={refBody}
          
          />
          </ControlSearchPannelLayout>
        </>

        }
        
      
      </>
      <BodyScrollContainer>
        <TitlesList
          list={renderProducts}
          uniqueKey={'id'}
          noPadding={false}
          Element={ProductItem}
          additionalData={productProps}
        />
      </BodyScrollContainer>
      <ControlWrapper>
        <ControlButton
          lable={'חזרה'}
          handleClick={() => history.push(LINKS.mlai.mlai.mainURL)}
        />
        <ControlButton
          lable={'סיום'}
          handleClick={saveAndExit}
        />
      </ControlWrapper>
    </FlexColumnLayout>
  )
}

export default MlaiCreateByScan