import React from 'react';
import styled, { keyframes } from 'styled-components';

const LoadingDotsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const dotFlashing = keyframes`
  0% {
    opacity: 0.3;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.3;
  }
`;

const Dot = styled.div<{ delay: string }>`
  background-color: black;
  border-radius: 50%;
  width: 6px;
  height: 6px;
  margin: 0 4px;
  animation: ${dotFlashing} 1.4s infinite ease-in-out both;
  animation-delay: ${({ delay }) => delay};
`;

const LoadingDotsAnimated: React.FC = () => (
  <LoadingDotsWrapper>
    <Dot delay="0s" />
    <Dot delay="0.2s" />
    <Dot delay="0.4s" />
  </LoadingDotsWrapper>
);

export default LoadingDotsAnimated;