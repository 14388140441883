import React, {useState, useMemo} from 'react'
import { IProductGroupItem } from '../../../../../../modules/API/Defaults/models/productGroup'
import SearchFromTheList from '../../../../../../generic/customSearchComponents/SearchFromTheList'

type Props = {
  defaultID: number,
  list: IProductGroupItem[]
  setProductGroupID: React.Dispatch<React.SetStateAction<number>>
  required?: boolean
  placeholder?: string

}

const SearchProductGroup = ({
  defaultID, list, setProductGroupID, required, placeholder
}: Props) => {
  const defaultProductGroup = list.filter(item => item.GroupID === defaultID)[0]
  const [isOpenDropDown, setIsOpenDropDown] = useState<boolean>(false)
  const [selectedProductGroup, setSelectedProductGroup] = useState<IProductGroupItem | null>(defaultProductGroup || null)
  const [search, setSearch] = useState<string>(() => defaultProductGroup?.GroupDesc.trim() || '')
  const handleSearch = (e: string) => {
    if(e === '') {
      setSelectedProductGroup(null)
      setIsOpenDropDown(false)
      setSearch('')
      setProductGroupID(0)
      return
    }
    setIsOpenDropDown(true)
    setSearch(e)
  }
  const handleSelect = (item: IProductGroupItem) => {
    setSearch(item.GroupDesc.trim())
    setSelectedProductGroup(item)
    setIsOpenDropDown(false)
    setProductGroupID(item.GroupID)
  }
  const render = useMemo(() => {
    if(!list) return []
    return list.filter(item => 
      item.GroupDesc.trim().toLocaleLowerCase().includes(search.toLocaleLowerCase())
    )
  }, [search, list])

  return (
    <SearchFromTheList
      isOpenList={isOpenDropDown}
      placeHolder={placeholder || 'חפש קבוצת מוצרים'}
      list={render}
      selectFunc={handleSelect}
      searchFunc={handleSearch}
      isNeedFullObject
      input={search}
      itemDisplayName='GroupDesc'
      nameIdElement='GroupID'
      required={required}
      size={'full'}
    />
  )
}

export default SearchProductGroup

// REFFERENCE:

/*

type Props = {
  required?: boolean
  list: IWarhouseListItem[]
  defaultID: number
  setWarehouseID: React.Dispatch<React.SetStateAction<number>>
}

const SearchWarehouse = ({
  list, required, setWarehouseID, defaultID
}: Props) => {
  console.log(defaultID)
  const defaultWarehouse = list.filter(item => item.WarehouseID === defaultID)[0]
  const [isOpenDropDown, setIsOpenDropDown] = useState<boolean>(false)
  const [selectedWarehouse, setSelectedWarehouse] = useState<IWarhouseListItem | null>(defaultWarehouse || null)
  const [search, setSearch] = useState<string>(() => defaultWarehouse?.WarehouseDesc.trim() || '')
  console.log(defaultWarehouse)
  const handleSearch = (e: string) => {
    if(e === '') {
      setSelectedWarehouse(null)
      setIsOpenDropDown(false)
      setSearch('')
      setWarehouseID(0)
      return
    }
    setIsOpenDropDown(true)
    setSearch(e)
  }
  const handleSelect = (item: IWarhouseListItem) => {
    setSearch(item.WarehouseDesc.trim())
    setSelectedWarehouse(item)
    setIsOpenDropDown(false)
    setWarehouseID(item.WarehouseID)
  }
  // useEffect(() => {
  //   if(defaultWarehouse) {
  //     setSelectedWarehouse(defaultWarehouse)
  //     setWarehouseID(defaultWarehouse.WarehouseID)
  //     setSearch(defaultWarehouse.WarehouseDesc.trim())
  //   }
  // }, [defaultWarehouse])
  const render = useMemo(() => {
    if(!list) return []
    return list.filter(item => 
      item.WarehouseDesc.trim().toLocaleLowerCase().includes(search.toLocaleLowerCase())
    )
  }, [search, list])
  
  return (
    <SearchFromTheList
      isOpenList={isOpenDropDown}
      placeHolder={'חפש מחסן'}
      list={render}
      selectFunc={handleSelect}
      searchFunc={handleSearch}
      isNeedFullObject
      input={search}
      itemDisplayName='WarehouseDesc'
      nameIdElement='WarehouseID'
      required={required}
      size={'full'}
    />
  )
}

*/