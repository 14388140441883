import { IActionLog } from "../../../../../modules/API/Drivers/models/kavData";

export const actionsData = (Actions: IActionLog[]) => {
  const start = Actions.find(item => item.Action === 1)
        const finish = Actions.find(item => item.Action === 2)
        return {
            Start: {
                isActive: !!start,
                time: substring(start?.LogTime || '') 
            },
            Finish: {
                isActive: !!finish,
                time: substring(finish?.LogTime || '') 
            }
        }
}

export const substring = (time: string) => {
  return `${time.substring(0, 2)}:${time.substring(2, 4)}`
}