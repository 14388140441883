import React from 'react'
import styled from 'styled-components'
import { ISellerItem } from '../../../../../modules/API/Defaults/models/seller'
import { ITeudotTypeItem } from '../../../../../modules/API/Defaults/models/teudotTypes'
import SearchBlockWithTitle from '../../procedures/Q8/Layouts/SearchBlockWithTitle'
import SearchCustomers from '../../forcasts/Supply/Components/SearchCustomers'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../../store/reducers/rootReducer'
import { IClientsItem } from '../../../../../modules/API/Defaults/models/customers'
import SearchSeller from '../../market/Collect/Components/SearchSeller'
import SearchTeudaTypes from '../../procedures/Q2/Components/SearchTeudaTypes'
import { ICarItem } from '../../../../../../models/Defaults/carList'
import SearchCar from '../../knisot/Create/Components/SearchCar'
import ControlButton from '../../../../../generic/buttons/ControlButton'

type Props = {
  carList: ICarItem[] | null
  carID: number,
  setCarID: React.Dispatch<React.SetStateAction<number>>
  sellerList: ISellerItem[] | null
  sellerID: number
  setSellerID: React.Dispatch<React.SetStateAction<number>>
  teudaType: number
  teudaTypeList: ITeudotTypeItem[] | null
  setTeudaType: React.Dispatch<React.SetStateAction<number>>
  selectedClient: number
  setSelectedClient: React.Dispatch<React.SetStateAction<number>>
  handleClose: () => void
}

const SettingsModsl = ({
  sellerList,
  sellerID,
  setSellerID,
  teudaType,
  teudaTypeList,
  setTeudaType,
  selectedClient,
  setSelectedClient,
  carList,
  carID,
  setCarID,
  handleClose
}: Props) => {
  const clients = useSelector((state: RootState): IClientsItem[] => state.defaults?.clientList?.list);
  return (

    <MainWrapper>
      {
        teudaTypeList && carList && sellerList &&
        <Body>
          <SearchBlockWithTitle
            title="בחר לקוח"
          >

            <SearchCustomers
              defaultID={selectedClient}
              setCustomerID={setSelectedClient}
              list={clients}
            />
          </SearchBlockWithTitle>
          <SearchBlockWithTitle
            title="בחר משווק"
          >

            <SearchSeller
              defaultID={sellerID}
              setSellerID={setSellerID}
              list={sellerList}
            />
          </SearchBlockWithTitle>

          <SearchBlockWithTitle
            title="בחר סוג תעודה"
          >

            <SearchTeudaTypes
              defaultID={teudaType}
              setTeudaTypeID={setTeudaType}
              list={teudaTypeList}
            />
          </SearchBlockWithTitle>
          <SearchBlockWithTitle
            title="בחר רכב"
          >

            <SearchCar
              defaultID={carID}
              setCarID={setCarID}
              list={carList}
            />
          </SearchBlockWithTitle>
        </Body>

      }
      <ControlButton 
        lable={'המשך'}
        handleClick={handleClose}
      />
    </MainWrapper>
  )
}

const MainWrapper = styled.div`
  width: 90%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5em;
`
const Body = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5em;
`
export default SettingsModsl