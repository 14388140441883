import { useEffect } from 'react';

interface IHookProps {
  refBody: React.RefObject<any>; // Change the type to match the ref type in your component
  func?: () => void;
}
function useScannedDataHandler( 
  refBody: React.RefObject<any>, 
  func?: () => void
  ) {
  useEffect(() => {
    const handleScannedData = (event: KeyboardEvent) => {
      const barcodeData = event.code;
      // alert(event.keyCode)
      // alert(event.charCode)
      // alert(event.which)

      if (barcodeData === "") {
        // alert(barcodeData);
        refBody.current?.focusInput();
        if(func) {
          func();
        }
        setTimeout(() => {
          refBody.current?.blurInput();
        });
      }
    };

    window.addEventListener('keypress', handleScannedData);

    return () => {
      window.removeEventListener('keypress', handleScannedData);
    };
  }, [refBody, func]);
}

export default useScannedDataHandler;