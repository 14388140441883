import React, {useState,useMemo} from 'react'
import { reportTypes } from '../../config'
import SearchFromTheList from '../../../../../../../generic/customSearchComponents/SearchFromTheList'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../../../../store/reducers/rootReducer'

type Props = {
  defaultID: number,
  setDocTypeID: (id: number) => void,
  required?: boolean
}

const SearchDocTypeDriverReport = ({
  defaultID, setDocTypeID, required
}: Props) => {
  const isTRcompany = useSelector((state: RootState) => state.mainReducer.user?.rights?.includes('TRCOMPANY'))
  const typesList = isTRcompany ? reportTypes.filter(item => item.id > 3) : reportTypes

  const defalutValue = typesList?.find(item => item.id === defaultID) || typesList[0]
  const [searchValue, setSearchValue] = useState<string>(defalutValue?.desc.trim() || '')
  const [selected, setSelected] = useState<{id: number, desc: string} | null>(defalutValue || null)

  const handleSelect = (item: any) => {
    setSearchValue(item.desc.trim())
    setSelected(item)
    setDocTypeID(item.id)
  }
  
  const handleSearch = (value: string) => {
    if(value === '') {
      setSelected(null)
      setDocTypeID(0)
      setSearchValue('')
      return
    }

    setSearchValue(value)
  }

  const filteredList = useMemo(() => {
    if(!typesList) return []
    return typesList.filter(item => item.desc.includes(searchValue))
  }, [typesList, searchValue])
  return (
    <SearchFromTheList 
      list={filteredList}
      selectFunc={handleSelect}
      searchFunc={handleSearch}
      input={searchValue}
      itemDisplayName='desc'
      nameIdElement='id'
      isNeedFullObject
      size='full'
      placeHolder='חפש סוג דוח'
      required={required}
    
    />
  )
}

export default SearchDocTypeDriverReport