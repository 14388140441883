import React from 'react'
import { IOrderRehesh } from '../../../../../../modules/API/AzmanotRehesh/models/order'
import styled from 'styled-components'
import TitleRow from '../../../kosherTeudot/generic/TitleRow'
import TitleRowItem from '../../../kosherTeudot/generic/TitleRowItem'
import TitleLayout from '../../../kosherTeudot/generic/TitleLayout'
import { AppleIcon, PackageIcon, PalletIcon, ScalesIcon, UserIcon } from '../../../../../../generic/icons/Icons'
import EditProductNestedItem from './EditProductNestedItem'
import { CountsAddData } from '../types/countsAddData'

interface Props extends CountsAddData {
  handleChangeQty: (qty: number, index: number) => void,
  calculationMethod: number
}

const EditProductItem = ({
  ProductDesc,
  DetailsByGrowers,
  VarietyDesc,
  OrderPacks,
  Order_Pallets,
  OrderWeight,
  calculationMethod,
  handleChangeQty,
  MlaiPacks,
  MlaiPallets,
  MlaiWieght,
  OrdersPacks,
  OrdersPallets,
  OrdersWieght
}: Props) => {
  return (
    <MainWrapper>
      <TitleLayout>
      
        <TitleRow>
          <TitleRowItem 
            icon={<AppleIcon />}
            name={`${ProductDesc.trim()} ${VarietyDesc.trim()}`}
          />
        </TitleRow>
        <TitleRow>
          <TitleRowItem
            name={
              <>
              {
                calculationMethod === 2 ? Math.round(MlaiPallets) :
                calculationMethod === 3 ? Math.round(MlaiWieght) :
                Math.round(MlaiPacks)
              } {
                 calculationMethod === 2 ? <PalletIcon /> :
                 calculationMethod === 3 ? <ScalesIcon /> :
                   <PackageIcon />
              } מלאי
              </>
              
            }
            icon={<></>}
          />
          <TitleRowItem 
            name={
              <>
              {
                calculationMethod === 2 ? OrdersPallets  :
                calculationMethod === 3 ? OrdersWieght  :
                OrdersPacks 
              } {
                calculationMethod === 2 ? <PalletIcon /> :
                calculationMethod === 3 ? <ScalesIcon /> :
                <PackageIcon />
              } הוזמן
              </> 
            }
            icon={<></>} 
          />
        </TitleRow>
        <TitleRow>
          <TitleRowItem 
            icon={
              calculationMethod === 2 ? <PalletIcon /> :
              calculationMethod === 3 ? <ScalesIcon /> :
              <PackageIcon />
            }
            name={`${
              calculationMethod === 2 ? Order_Pallets :
              calculationMethod === 3 ? OrderWeight :
              OrderPacks
            }`}
          />
        </TitleRow>
      </TitleLayout>
      <NestedWrapper>
        {
          DetailsByGrowers.map((item, index) => 
            <EditProductNestedItem 
              index={index}
              key={index}
              {...item}
              handleChangeQty={handleChangeQty}
              calculationMethod={calculationMethod}
            />
          )
        }
        
      </NestedWrapper>
    </MainWrapper>
  )
}
const MainWrapper = styled.div`
  width: 100%;
  padding: 0.5em;
  display: flex;
  flex-flow: column;
  gap: 0.5em;
`
const NestedWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column;
  gap: 0.5em;
  padding: 0.5em;
  background: #f5f5f5;
  border-radius: 20px;
`


export default EditProductItem