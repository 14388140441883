import { IDataQ4 } from "../../../../../../modules/API/Reports/models/q4";

export const filterData = (data: IDataQ4[], searchReport: string) => {
  const searchLower = searchReport.toLowerCase();
  return data.filter(item => 
    item.Field1_Desc?.trim().toLocaleLowerCase().includes(searchLower) ||
    item.Field2_Desc?.trim().toLocaleLowerCase().includes(searchLower)
  );
};

export const sortData = (data: IDataQ4[], sorting: number, prevSorting: number) => {
  switch (sorting) {
    case 1:
      {
        if(sorting !== prevSorting) {
          return data.sort((a, b) => b.AmountPack - a.AmountPack);

        } else {
          // return data.sort((a, b) => a.AmountPack - b.AmountPack);
          let newArr = []
          // reverse the array from the end to the start
          for (let i = data.length - 1; i >= 0; i--) {
            newArr.push(data[i]);
          }
          return newArr;
        }
      }
    case 2:
      {
        if(sorting !== prevSorting) {
          return data.sort((a, b) => b.AmountPallets - a.AmountPallets);

        } else {
          // return data.sort((a, b) => a.AmountPallets - b.AmountPallets);
          let newArr = []
          // reverse the array from the end to the start
          for (let i = data.length - 1; i >= 0; i--) {
            newArr.push(data[i]);
          }
          return newArr;
        }
      }
    case 3:
      {
        if(sorting !== prevSorting) {
          return data.sort((a, b) => b.Weight - a.Weight);

        } else {
          // return data.sort((a, b) => a.Weight - b.Weight);
          let newArr = []
          // reverse the array from the end to the start
          for (let i = data.length - 1; i >= 0; i--) {
            newArr.push(data[i]);
          }
          return newArr;
        }
      }
    case 4:
      {
        if(sorting !== prevSorting) {
          return data.sort((a, b) =>( b.Price || 0) - (a.Price || 0));

        } else {
          // return data.sort((a, b) => (a.Price || 0) - (b.Price || 0));
          let newArr = []
          // reverse the array from the end to the start
          for (let i = data.length - 1; i >= 0; i--) {
            newArr.push(data[i]);
          }
          return newArr;
        }
      }
    case 5:
      {
        if(sorting !== prevSorting) {
          return data.sort((a, b) => b.Commission - a.Commission);

        } else {
          let newArr = []
          // reverse the array from the end to the start
          for (let i = data.length - 1; i >= 0; i--) {
            newArr.push(data[i]);
          }
          
          return newArr;

        }
      }
   
    default:
      return data;
  }
};