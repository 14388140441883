import React, {useState, useMemo} from 'react'
import SearchFromTheList from '../../../../../../generic/customSearchComponents/SearchFromTheList'

type Props = {
  defaultID: number,
  setCalculationMethodID: React.Dispatch<React.SetStateAction<number>>
  placeholder?: string
  required?: boolean
}

const list = [
  {
    id: 1,
    desc: 'כמות'
  },
  {
    id: 2,
    desc: 'משטחים'
  },
  {
    id: 3,
    desc: 'משקל'
  }
]
const SearchCalculationMethod = ({
  defaultID, setCalculationMethodID, placeholder, required
}: Props) => {
  const defaultCalculationMethod = list.filter(item => item.id === defaultID)[0]
  const [isOpenDropDown, setIsOpenDropDown] = useState<boolean>(false)
  const [selectedCalculationMethod, setSelectedCalculationMethod] = useState<{id: number, desc: string} | null>(defaultCalculationMethod || null)
  const [search, setSearch] = useState<string>(() => defaultCalculationMethod?.desc.trim() || '')

  const handleSearch = (e: string) => {
    if(e === '') {
      setSelectedCalculationMethod(null)
      setIsOpenDropDown(false)
      setSearch('')
      setCalculationMethodID(0)
      return
    }
    setIsOpenDropDown(true)
    setSearch(e)
  }
  const handleSelect = (item: {id: number, desc: string}) => {
    setSearch(item.desc.trim())
    setSelectedCalculationMethod(item)
    setIsOpenDropDown(false)
    setCalculationMethodID(item.id)
  }
  const render = useMemo(() => {
    if(!list) return []
    return list.filter(item => 
      item.desc.trim().toLocaleLowerCase().includes(search.toLocaleLowerCase())
    )
  }, [search, list])

  return (
    <SearchFromTheList 
      isOpenList={isOpenDropDown}
      placeHolder={placeholder || 'חיפוש חישוב'}
      list={render}
      selectFunc={handleSelect}
      searchFunc={handleSearch}
      isNeedFullObject
      input={search}
      itemDisplayName='desc'
      nameIdElement='id'
      required={required}
      size={'full'}
    
    />
  )
}

export default SearchCalculationMethod