import { useState, useMemo } from 'react'
import styled from 'styled-components'
import { ArrowIcon, ChecklistIcon, LocationIcon, PalletIcon, PrintIcon, ScalesIcon, SpeedIcon, TimeIcon, UserIcon } from '../../../../../generic/icons/Icons'
import { ICustomerItem, IGrowerItem, IKavDataItem, IOrderItem } from '../../../../../modules/API/Drivers/models/kavData'
import ClientsOrderItem, { ButtonsWrapper } from './ClientsOrderItem'
import { actionsData } from '../helpers/kavAction'
import TimeComponent from '../Components/TimeComponent'

interface Props extends IKavDataItem {
    printFunc: (CODE: number, SUBCODE: number) => void,
    selectOrderForCloseDelivery: (item: IOrderItem[], dataCustomer: ICustomerItem) => void
    selectDataForPackageOrder: (dataClient: ICustomerItem, kavID: number) => void,
    selectGrowersFromKav: (item: IGrowerItem[]) => void,
    handleActionMovments: (kavID: number, action: number) => void,
    isOffice?: boolean,
    getPackageStatisticByKav: (item: IKavDataItem) => void,
    item: IKavDataItem,
    printLogPdf: (kavID: number) => void
}

interface Container {
    isOpen: boolean
}

const KavItem = ({
    AreaDesc, Customers, printFunc, selectGrowersFromKav, DriverName,
    selectOrderForCloseDelivery, selectDataForPackageOrder, isOffice,
    handleActionMovments, AreaID, Actions, getPackageStatisticByKav, item,
    printLogPdf
}: Props) => {
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const handleToggleOpen = () => {
        setIsOpen(!isOpen);
    }
    const generalClientQty = Customers.length
    const { weight, pallets } = useMemo(() => {
        // let weight, pallets
        const { weight, pallets } = Customers.reduce((acc: Record<string, number>, cur) => {
            if (cur.Type === 1) return acc
            const { weight, pallets } = cur.Orders.reduce((acc: Record<string, number>, cur) => {
                if (cur.PalletsList.length === 0) {
                    acc.weight = (acc.weight ? acc.weight : 0) + cur.Weight;
                    acc.pallets = (acc.pallets ? acc.pallets : 0) + cur.Pallets;
                } else {
                    acc.weight = (acc.weight ? acc.weight : 0) + cur.PalletsList.reduce((sum, curP) => {
                        return sum + curP.Gross
                    }, 0);
                    acc.pallets = (acc.pallets ? acc.pallets : 0) + cur.PalletsList.reduce((sum, curP) => {
                        return sum + curP.PallQty
                    }, 0);
                }
                return acc;
            }, {})
            // console.log(weight)
            if (AreaID === 147) {
                console.log(pallets)
                // console.log(pallets ? (acc.pallets || 0) + pallets : 0)
            }

            acc.weight = acc.weight ? acc.weight + weight : weight
            // acc.weight = weight ? (acc.weight || 0) + weight : 0
            // acc.pallets = pallets ? (acc.pallets || 0) + pallets : 0
            acc.pallets = acc.pallets ? acc.pallets + pallets : pallets
            return acc
        }, {})
        return { weight, pallets }
    }, [Customers, AreaID])

    const handleQuestsClick = (e: React.MouseEvent) => {
        e.stopPropagation()
        // selectGrowersFromKav(Growers)
    }
    const handlePrintLog = () => {
        printLogPdf(item.AreaID)
    }
    const handleOpenStat = () => {
        getPackageStatisticByKav(item)
    }
    const handleTimeWorkClick = (e: React.MouseEvent, action: number) => {
        e.stopPropagation()
        handleActionMovments(AreaID, action)
    }
    const actionData = actionsData(Actions)
    return (
        <MainWrapper isOpen={isOpen} onClick={handleToggleOpen}>
            <TimeWrapper>
                {/* <TimeItem>
                    {
                        actionData.Start.isActive ?
                            <>
                                {actionData.Start.time}
                                <TimeIcon />
                                <ArrowIcon position={'top'} />

                            </> :
                            <ButtonTime
                                onClick={(e) => handleTimeWorkClick(e, 1)}
                            >
                                התחלה
                            </ButtonTime>

                    }
                </TimeItem> */}
                <TimeComponent 
                    time={actionData.Start.time}
                    isActive={actionData.Start.isActive}
                    onClick={(e) => handleTimeWorkClick(e, 1)}
                />
                <TimeComponent 
                    isFinish
                    time={actionData.Finish.time}
                    isActive={actionData.Finish.isActive}
                    onClick={(e) => handleTimeWorkClick(e, 2)}
                    isHideFinish={!actionData.Start.isActive}
                />
                {/* <TimeItem isFinish>
                    {
                        actionData.Finish.isActive ?
                            <>
                                {actionData.Finish.time}
                                <TimeIcon />
                                <ArrowIcon position={'bottom'} />
                            </> : actionData.Start.isActive ?
                                <ButtonTime
                                    onClick={(e) => handleTimeWorkClick(e, 2)}
                                >
                                    סיום
                                </ButtonTime> : ''
                    }

                </TimeItem> */}
            </TimeWrapper>
            <DotsIndicator >
                <Dot />
                <Dot />
                <Dot />
            </DotsIndicator>
            <MainTitle onClick={handleToggleOpen} isTitle>
                <ButtonsWrapper>
                    {/* <Button onClick={handleQuestsClick}>
                    <ChecklistIcon />
                </Button> */}
                </ButtonsWrapper>

                <Item>
                    {
                        isOffice ?
                            <>
                                {`  ${DriverName?.trim()}  -`}

                                <UserIcon />
                            </> : ''
                    }
                    {AreaDesc}
                    <SpeedIcon color={'#7ab0ab'} />
                </Item>
            </MainTitle>
            <MainTitle>
                <Item>
                    {pallets?.toFixed(2)}
                    <PalletIcon />
                </Item>
                <Item>
                    {Math.round(weight)}
                    <ScalesIcon />
                </Item>
                <Item>
                    {generalClientQty}
                    <LocationIcon />
                </Item>
            </MainTitle>
            <NestedContainer isOpen={isOpen} onClick={(e) => e.stopPropagation()}>
                {
                    isOpen && isOffice &&
                    <ButtonPackageWrapper>
                        <Button
                            isPrimal
                            onClick={handlePrintLog}
                        >
                            <PrintIcon />
                        </Button>
                        <Button
                            onClick={handleOpenStat}
                        >
                             סיכום
                             אריזות
                        </Button>
                    </ButtonPackageWrapper>
                }
                {
                    isOpen &&
                    Customers.map((item, index) =>
                        <ClientsOrderItem
                            key={index}
                            {...item}
                            item={item}
                            printFunc={printFunc}
                            isOffice={isOffice}
                            AreaID={AreaID}
                            selectDataForPackageOrder={selectDataForPackageOrder}
                            selectOrderForCloseDelivery={selectOrderForCloseDelivery}
                        />
                    )
                }

            </NestedContainer>
        </MainWrapper>
    )
}
const TimeWrapper = styled.div`
    position: absolute;
    top: 1.1em;
    left: 1em;
    width: 5em;
    height: 3em;
    /* background: red; */
    display: flex;
    flex-flow: column;
    gap: 0.3em;
`
const TimeItem = styled.div<{ isFinish?: boolean }>`
    display: flex;
    width: 100%;
    justify-content: flex-end;
    gap: 0.3em;
    color: ${props => props.isFinish ? '#80E081' : '#838484'};

`
const ButtonTime = styled.button<{ isFinish?: boolean }>`
    width: 80%;
    height: 100%;
    padding: 0.3em;
    border: none;
    border-radius: 20px;
    background: #80E081;
    color: #fff;
    cursor: pointer;
    :hover {
        opacity: 0.8;
    }

`
const transition = '0.5s';
const MainWrapper = styled.div<Container>`
    position: relative;
    width: 100%;
    height: auto;
    border-radius: 30px;
    padding: 1em;
    display: flex;
    flex-flow: column;
    gap: ${props => props.isOpen ? '1em' : '0.3em'};
    background: #eff3f4;
    transition: ${transition};
`
const MainTitle = styled.div<{ isTitle?: boolean }>`
    width: 100%;
    display: flex;
    justify-content: ${props => props.isTitle ? 'space-between' : 'flex-end'} ;
    gap: 1em;
    font-weight: 600;
    color: #838484;
    cursor: pointer;
`
const Item = styled.div`
    display: flex;
    gap: 0.4em;
    
    i {
        color: #7ab0ab;
    }
`
const NestedContainer = styled.div<Container>`
    height: ${props => props.isOpen ? 'auto' : '0'};
    display: flex;
    padding: 0 0 0.5em 0;
    flex-flow: column;
    gap: 0.5em;
    overflow: hidden;
    transition: height ${transition} ease-in-out;
`
const DotsIndicator = styled.div`
    position: absolute;
    width: 3em;
    bottom: 0.4em;
    left: calc(50% - 1.5em);
    display: flex;
    justify-content: center;
    gap: 0.2em;
`
const Dot = styled.div`
    width: 0.3em;
    height: 0.3em;
    background: #7ab0ab;
    border-radius: 50%;

`
const ButtonPackageWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 0.5em;
    padding: 0 0.5em;

`

const Button = styled.button<{isPrimal?: boolean}>`
    width: ${props => !props.isPrimal ? '50%' : '2em'};
    padding: ${props => props.isPrimal ? '0.4em' : '0.3em'};
    border-radius: ${props => props.isPrimal ? '50%' : '15px'} ;
    outline: none;
    border: none;
    background-color: ${props => props.isPrimal ? '#fff': 'rgba(41,127,118, 0.6)'} ;
    color: ${props => props.isPrimal ? '#297F76' : "#fff"} ;
    cursor: pointer;
    transition: 0.3s;
    font-size: 1.04em;
    :hover {
        opacity: 0.8;
    }

`
export default KavItem