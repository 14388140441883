import React, {useState, useEffect} from 'react'
import { ITitleMlaiItem } from '../../../../../modules/API/Mlai/models/title'
import TitleLayout from '../../kosherTeudot/generic/TitleLayout'
import TitleRow from '../../kosherTeudot/generic/TitleRow'
import TitleRowItem from '../../kosherTeudot/generic/TitleRowItem'
import { convertToDate } from '../../../../../modules/convertDateString'
import { BoxesIcon, CalendatDay, DeleteIcon, InvoiceIcon, TimeIcon, WarehouseIcon } from '../../../../../generic/icons/Icons'
import styled from 'styled-components'
import { SwipeableListItem } from '@sandstreamdev/react-swipeable-list'
import SwipeContent from '../../knisot/Components/SwipeContent'

interface IProps extends ITitleMlaiItem {
  handleSelectForEdit?: (id: number, TypeStock: number) => void,
  handleDelete?: (id: number) => void
}

const MlaiTitleItem = ({
  DateStock, CodeStock, TranzitID, handleSelectForEdit, 
  handleDelete, TimeStock, WarehouseDesc, TypeStock
}: IProps) => {
  const [isShowDelete, setIsShowDelete] = useState<boolean>(false)
  const [isShowLine, setIsShowLine] = useState<boolean>(true)
  const convertedDate = convertToDate(DateStock)
  const convertedTime = `${TimeStock.slice(0, 2)}:${TimeStock.slice(2, 4)}`

  const handleOpenDetails = () => {
    if (!handleSelectForEdit) return
    handleSelectForEdit(CodeStock, TypeStock)
  }

  const handleSwipeDelete = () => {
    setIsShowDelete(!isShowDelete)
  }
  const handleDeleteItem = () => {
    if (!handleDelete) return
    handleDelete(CodeStock)
  }
  return (
    
    <TitleLayout>
      <SwipeableListItem
        swipeRight={
          {
            content: <SwipeContent text={'עיון'} />,
            action: handleOpenDetails
          }
        }
        swipeLeft={{
          content: <SwipeContent text={'delete'} isPrimal />,
          action: handleSwipeDelete
        
        }}
        threshold={0.3}
        blockSwipe={!handleSelectForEdit}
      >
       
        <MainWrapper>
          <BodyTitle>
            <TitleRow>
              {/* <TitleRowItem name={`${CodeStock}`} icon={<InvoiceIcon />} /> */}
              <TitleRowItem name={convertedTime} icon={<TimeIcon />} />
              <TitleRowItem name={convertedDate} icon={<CalendatDay />} />
            </TitleRow>
            <TitleRow>
              <TitleRowItem name={`${WarehouseDesc.trim()}`} icon={<WarehouseIcon />} />
              <TitleRowItem name={`${CodeStock}`} icon={<InvoiceIcon />} />
              
              {/* <TitleRowItem name={convertedDate} icon={<CalendatDay />} /> */}
            </TitleRow>

          </BodyTitle>
        </MainWrapper>
        {
          isShowDelete ?
            <DeleteWrapper
              onClick={handleDeleteItem}
            >
              <DeleteIcon />
            </DeleteWrapper> : ''
        }
    </SwipeableListItem>
    </TitleLayout>
  )
}
const MainWrapper = styled.div`
    display: flex;
    width: 100%;
    /* background: red; */
`
const BodyTitle = styled.div`
    width: 100%;
    display: flex;
    flex-flow: column;
    gap: 0.5em;

`
const DeleteWrapper = styled.div`
  width: 3em;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: tomato;
  color: #fff;
  margin-left: 0.5em;
  border-radius: 0 15px 15px 0;
  cursor: pointer;
`
export default MlaiTitleItem