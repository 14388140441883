import React from 'react'
import styled from 'styled-components'
import { ErrorIcon } from '../../../../../../generic/icons/Icons'

type Props = {
  message: string
}

const Message = ({
  message
}: Props) => {
  return (
    <MainWrapper>
      <MessageText>
        {
          message || 'test Message'
        }
      </MessageText>
      <ErrorIcon />
    </MainWrapper>
  )
}

const MainWrapper = styled.p`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 0.5em;
  color: tomato;
  padding: 0 0.5em;
  /* background: red; */
  i {
    font-size: 1.3em;
  }
`

const MessageText = styled.span`
    /* color: tomato; */
`

export default Message