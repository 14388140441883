import { requests } from "../config";
import { ITitleMlaiItemWithDetails } from "./models/details";
import { IPostProductsMlaiRequest } from "./models/productsForCreate";

export const Mlai = {
  //DateFrom=123123&DateTo=123123
  getMlaiList: (params: string) => requests.get(`/stock_taking?${params}`, 'go'),
  getMlaiItem: (id: string | number): Promise<ITitleMlaiItemWithDetails> => requests.get(`/stock_taking/${id}`, 'go'),
  deleteTitleItem: (id: string | number) => requests.delete(`/stock_taking/${id}`, 'go'),
  getProductsForCreate: (tranzitID: string | number) => requests.get(`/stock_taking/draft?TranzitID=${tranzitID}`, 'go'),
  postProducts: (data: IPostProductsMlaiRequest): Promise<ITitleMlaiItemWithDetails> => requests.post(`/stock_taking`, 'go', data),
  putProducts: (data: ITitleMlaiItemWithDetails, id: string | number): Promise<ITitleMlaiItemWithDetails> => requests.put(`/stock_taking/${id}`, 'go', data),
}