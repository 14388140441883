import { IProductWithCounItem } from "../../../../../../modules/API/Products/models/productsWithCount";

const variationDict: Record<number, string> = {
  0: 'ProductDesc',
  1: 'OrdersPacks',
  3: 'OrdersPallets',
  4: 'OrdersWeight',
  5: 'OrdersSupplyPacks',
  6: 'OrdersSupplyPallets',
  7: 'OrdersSupplyWeight',
  2: 'MlaiPacks',
  8: 'MlaiPallets',
  9: 'MlaiWeight',
}
export const sortBySelectedField = (
  array: IProductWithCounItem[], 
  sortID: number, 
  isNeedToReverse: boolean
) => {
    const key = variationDict[sortID] as keyof IProductWithCounItem
    if(!key) return array
    if(isNeedToReverse) {
      return reverseFunction(array)
    } else {
      return array.sort((a, b) => {
        const aValue = a[key]
        const bValue = b[key]
        if (typeof aValue === 'number' && typeof bValue === 'number') {
          return aValue - bValue;
        } else if (typeof aValue === 'string' && typeof bValue === 'string') {
          return aValue.localeCompare(bValue);
        } else {
          return 0; // если типы не поддерживают сравнение
        }
      });
    }
}

const reverseFunction = (array: IProductWithCounItem[]) => {
  let arrFinal = []
  for (let i = array.length - 1; i >= 0; i--) {
    arrFinal.push(array[i])
  }
  return arrFinal
}
// { sortingID: 3, titleName: 'הזמנות לקוח משטחים' },
// { sortingID: 4, titleName: 'הזמנות לקוח משקל' },
// { sortingID: 5, titleName: 'הזמנות רכש אריזות' },
// { sortingID: 6, titleName: 'הזמנות רכש משטחים' },
// { sortingID: 7, titleName: 'הזמנות רכש משקל' },
// { sortingID: 8, titleName: 'מלאי משתחים' },
// { sortingID: 9, titleName: 'מלאי משקל' },