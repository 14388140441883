import React, { forwardRef, useImperativeHandle, useRef } from "react";
import styled from "styled-components";
import { CloseIcon, SearchIcon } from "../../../../../generic/icons/Icons";
import selectOnFocus from "../../../../../modules/autoselect";

type Props = {
  setInput: (e: string) => void;
  input: string;
  disabled?: boolean;
  enterFunc?: () => void;
  icon?: React.ReactNode;
  isHide?: boolean;
};
interface IRef {
  focusInput: () => void;
  blurInput: () => void;
}
const ScanInput = forwardRef<IRef, Props>(
  ({ setInput, input, disabled, enterFunc, icon, isHide }: Props, ref) => {
    const clearInput = () => {
      setInput("");
    };
    const iconNode = icon ? icon : <SearchIcon />
    let controlToggle = 
      input?.length > 0 ? <CloseIcon handler={clearInput} /> : iconNode;
    const inputRef = useRef<HTMLInputElement | null>(null);

    // Expose the inputRef to the parent component using useImperativeHandle
    useImperativeHandle(ref, () => ({
      focusInput: () => {
        if (inputRef.current) {
          inputRef.current.focus();
        }
      },
      blurInput: () => {
        if (inputRef.current) {
          inputRef.current.blur();
        }
      },
     
    }), []);
    return (
      <InputWrapper isHide={isHide}>
        {!disabled && <IconWrapper>{controlToggle}</IconWrapper>}
        <Input
          type={"text"}
          placeholder={"סריקה"}
          value={input}
          inputMode="text"
          onClick={selectOnFocus}
          readOnly={disabled}
          onChange={(e) => {
            setInput(e?.target?.value);
          }}
          min={0}
          ref={inputRef}
          onKeyDown={(e) => {
            if (enterFunc && e.key === "Enter") {
              enterFunc();
            }
          }}
        />
      </InputWrapper>
    );
  }
);

const InputWrapper = styled.div<{isHide?: boolean}>`
  position: relative;
  width: 100%;
  opacity: ${props => props.isHide ? 0 : 1}; 
  /* background: #eff3f4; */
  /* border-radius: 30px; */
`;
const IconWrapper = styled.div`
  position: absolute;
  background: #eff3f4;
  /* background: red; */
  top: 0.3em;
  left: 0.3em;
  width: 2.9em;
  height: 2.9em;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  i {
    display: block;
    font-size: 0.9em;
    color: #838484;
  }
  .fa-times {
    cursor: pointer;
  }
`;

const Input = styled.input`
  width: 100%;
  height: 3.2em;
  border: 2px solid #eff3f4;
  padding: 0.5em 1em;
  letter-spacing: 0.5px;
  outline: none;
  text-align: right;
  z-index: 0;
  background: #eff3f4;
  font-size: 1.1em;
  border-radius: 30px;
  -webkit-appearance: none;
  appearance: none;
  color: #8a97a5;
  ::placeholder {
    opacity: 0.5;
  }
  :focus {
    animation: 2s pulse infinite;
  }
  @keyframes pulse {
    0% {
      border: 2px solid #eff3f4;
    }
    50% {
      border: 2px solid #80e081;
    }
  }
`;

export default ScanInput;
