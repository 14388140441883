import React from 'react'
import styled from 'styled-components'
import TitleRow from '../generic/TitleRow'
import TitleRowItem from '../generic/TitleRowItem'
import { AppleIcon, InvoiceIcon, SpeedIcon, UserIcon, VerticalDots } from '../../../../../generic/icons/Icons'
import { ITeudotItem } from '../../../../../../models/RequestsModel/teudot'
import TitleLayout from '../generic/TitleLayout'

interface Props extends ITeudotItem {
  handleControlModal: () => void
}

const TitleTeuda = ({
  Order_Num,
  Delivery_Num,
  Customer_Name,
  TranzitDesc,
  GroupDesc,
  handleControlModal
}: Props) => {
  return (
    <TitleLayout>

        <SideButtonWrapper onClick={handleControlModal}>
          <VerticalDots />
        </SideButtonWrapper>
    <BodyTeudotTitle>
          <TitleRow>
            <TitleRowItem 
              name={`(${Order_Num}) / ${Delivery_Num}`}
              icon={<InvoiceIcon />}
            />
          </TitleRow>
          <TitleRow>
            <TitleRowItem 
              name={Customer_Name}
              icon={<UserIcon />}
            />
          </TitleRow>
          <TitleRow>
            <TitleRowItem 
              name={GroupDesc}
              icon={<AppleIcon />}
            />
            <TitleRowItem 
              name={TranzitDesc?.trim() || ''}
              icon={<SpeedIcon color={''} />}
            />
          </TitleRow>
        </BodyTeudotTitle>
    </TitleLayout>

  )
}
const BodyTeudotTitle = styled.div`
  display: flex;
  flex-flow: column;
  gap: 0.5em;
`
const SideButtonWrapper = styled.div`
  height: 4em;
  width: 2em;
  left: 1em;
  top: calc(50% - 2em);
  position: absolute;
  /* background: red; */
  display: flex;
  justify-content: center;
  align-items: center;
  color: #297F76;
  font-size: 1.2em;
  cursor: pointer;
`
export default TitleTeuda