import { requests } from "../config";
import { IFlowerPalletItem } from "./models/flowerPallet";
import { InnerPalletItem } from "./models/innerPallet";

export const Flowers = {
  getPalletList: (date: string): Promise<IFlowerPalletItem[]> =>
    requests.get(`flowers/pallets?Date=${date}`, 'go'),
  getPackageInfo: (cartonNum: string | number, palletNum: string | number): Promise<InnerPalletItem> =>
    requests.get(`flowers/get_carton?Carton_Num=${cartonNum}&Pallet_Num=${palletNum}`, 'go'),
  postPacakgeToPallet: (data: {Carton_Num : string | number, Pallet_Num: string | number}): Promise<InnerPalletItem> =>
    requests.post(`flowers/accept_carton`, 'go', data),
  getPalletDetailsByID: (id: string): Promise<InnerPalletItem[]> =>
    requests.get(`flowers/pallet_cartons?Pallet_Num=${id}`, 'go'),
  unAcceptPackage: (data: {Carton_Num : string | number}): Promise<InnerPalletItem> =>
    requests.put(`flowers/unaccept_carton`, 'go', data),
  deletePalletTitle: (id: string | number): Promise<{status: string}> =>
    requests.delete(`flowers/pallet/${id}`, 'go'),
  }