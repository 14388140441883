import React from 'react'
import styled from 'styled-components'
import SearchBlockWithTitle from '../../../procedures/Q8/Layouts/SearchBlockWithTitle'
import SearchInput from '../../../../../../generic/inputs/inputSearch'
import Message from './Message'
import CircleNumericInput from '../../../../../../generic/inputs/CircleNumericInput'
import CircleActiveFilterBtn from '../../../../../../generic/particles/CircleActiveFilterBtn'
import { InnerPalletItem } from '../../../../../../modules/API/Flowers/models/innerPallet'
import { PlaneIcon, ShipIcon } from '../../../../../../generic/icons/Icons'

type Props = {
  packageData: InnerPalletItem | null
  barcode: string
  setBarcode: React.Dispatch<React.SetStateAction<string>>
  handleScan: () => void
}

const NewPackageForm = ({
  packageData, barcode, setBarcode, handleScan
}: Props) => {
  return (
    <MainWrapper>
            <SearchBlockWithTitle
              title=''
            >
              <SearchInput
                isEnableFocusSelect
                input={barcode}
                setInput={setBarcode}
                iconHide={true}
                value={'חיפוש לפי ברקוד'}
                enterFunc={handleScan}
              />
            </SearchBlockWithTitle>
              {
                packageData && 
                <>
                  {
                    packageData?.Message && 
                    <Message 
                      message={packageData.Message }
                    />
                  }
                  <SearchBlockWithTitle
                    title='לקוח'
                  >
                    <SearchInput 
                      input={packageData?.Customer_Name?.trim() || ''}
                      disabled
                      value={'לקוח'}
                    />
                  </SearchBlockWithTitle>
                  <SearchBlockWithTitle
                    title='מגדל'
                  >
                  <SearchInput 
                    input={packageData?.GrowerName?.trim() || ''}
                    disabled
                    value={'מגדל'}
                  />
                  </SearchBlockWithTitle>
                  <SearchBlockWithTitle
                    title={['אופן משלוח', 'משקל']}
                  >
                    <CircleNumericInput
                      disabled
                      value={packageData?.GrossWeight || 0}
                      changeFunc={() => {}}
                    /> 
                    <PseudoWrapper />
                    <CircleActiveFilterBtn
                      func={() => {}}
                    >
                      {
                        packageData?.Trasportation_Method === 1 ? <PlaneIcon /> : 
                        packageData?.Trasportation_Method === 2 ? <ShipIcon /> : ''
                      }  
                    </CircleActiveFilterBtn> 
                  </SearchBlockWithTitle>
                </>
              } 
            </MainWrapper> 
  )
}
const MainWrapper = styled.div`
  z-index: 1;
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  gap: 0.5em;
  background: #fff;
  border-radius: 0 0 30px 30px;
  padding: 0.5em 0;
`
const PseudoWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 0.5em;
`
export default NewPackageForm