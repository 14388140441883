import { useMemo, useState } from "react";
import styled from "styled-components";
import TitlesList from "../../../../generic/titlesRowItem/TitlesList";
import { ICustomerItem, IGrowerItem, IKavDataItem, IOrderItem } from "../../../../modules/API/Drivers/models/kavData";
import KavItem from "./ItemComponents/KavItem";

interface IProps {
  list: IKavDataItem[] | undefined;
  printFunc: (CODE: number, SUBCODE: number) => void;
  selectOrderForCloseDelivery: (item: IOrderItem[], dataCustomer: ICustomerItem, areaID: number) => void,
  selectDataForPackageOrder: (dataClient: ICustomerItem, kavID: number) => void,
  selectGrowersFromKav: (item: IGrowerItem[]) => void,
  handleActionMovments: (kavID: number, action: number) => void,
  isOffice?: boolean,
  getPackageStatisticByKav: (item: IKavDataItem) => void,
  printLogPdf: (kavID: number) => void
}

const DriverBody = ({ 
  list, printFunc, selectOrderForCloseDelivery,
  selectDataForPackageOrder, selectGrowersFromKav, isOffice,
  handleActionMovments, getPackageStatisticByKav, printLogPdf
}: IProps) => {
  const [activeTab, setActiveTab] = useState<string | null>('הכול');

  const uniqueTypes = useMemo(() => {
    let data = Array.from(
      new Set(list?.map((item) => item.AreaDesc.trim()))
    );
    data.push('הכול')

    return data
  }, [list])

  const handleTabClick = (tab: string) => {
      setActiveTab(tab);
  };

  const render = useMemo(() => {
    if (!list) return [];
    if (activeTab && activeTab !== 'הכול') {
      return list.filter((item) => item.AreaDesc.trim() === activeTab);
    } else {
      return list;
    }
  }, [activeTab, list]);

  const itemProps = {
    printFunc: printFunc,
    selectOrderForCloseDelivery: selectOrderForCloseDelivery,
    selectDataForPackageOrder: selectDataForPackageOrder,
    selectGrowersFromKav: selectGrowersFromKav,
    isOffice: isOffice,
    handleActionMovments: handleActionMovments,
    getPackageStatisticByKav: getPackageStatisticByKav,
    printLogPdf: printLogPdf
  };
  return (
    <Container>
      {
        !isOffice &&
        <TabContainer>
          {uniqueTypes.map((type) => (
            <Tab
              key={type}
              active={activeTab === type}
              onClick={() => handleTabClick(type)}
            >
              {type}
            </Tab>
          ))}
        </TabContainer>

      }
      <TitlesList
        list={render}
        uniqueKey={"AreaID"}
        additionalData={itemProps}
        Element={KavItem}
        noPadding={false}
        borderLess
      />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
`;

const TabContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  background: #eff3f4;
`;

const Tab = styled.div<{ active: boolean }>`
  padding: 8px 16px;
  color: #838484;
  width: 7em;
  border-radius: 30px 30px 0 0;
  cursor: pointer;
  ${(props) =>
    props.active
      ? `
                background: #fff;
                border-bottom: none;
                color: #7ab0ab;
            `
      : `
            
            `}
`;

export default DriverBody;
