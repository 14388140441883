import React from 'react'
import styled from 'styled-components'
import TitlesList from '../../../../../../generic/titlesRowItem/TitlesList'
import { InnerPalletItem } from '../../../../../../modules/API/Flowers/models/innerPallet'
import PackageListItem from './PackageListItem'

type Props = {
  handleClick: (item: InnerPalletItem) => void,
  palletData: InnerPalletItem[] | null
}

const PackageList = ({
  handleClick, palletData
}: Props) => {
  const packageItemProps = {
    handleClick: handleClick
  }
  return (
    <MainWrapper>

            <TitlesList
              list={palletData || []}
              uniqueKey={'Pallet_Num'}
              Element={PackageListItem}
              additionalData={packageItemProps}
              noPadding={false}
            
            />

          </MainWrapper>
  )
}
const MainWrapper = styled.div`
  z-index: 1;
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  gap: 0.5em;
  background: #fff;
  border-radius: 0 0 30px 30px;
  padding: 0.5em 0;
`
export default PackageList