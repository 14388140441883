import React, { useEffect } from 'react'
import styled from 'styled-components'
import { PalletToChangeTypes } from '../../modules/API/Pallets/models/palletToChange'
import usePalletToChangeService from '../../../Services/Pallets/usePalletToChangeService'
import { IPalletTypeItem } from '../../modules/API/Defaults/models/pallets'
import LoaderSpinner from '../../modules/loadSpinner/LoaderSpinner'
import PalletToChangeItem from './Components/PalletToChangeItem'
import ControlButton from '../buttons/ControlButton'
import { useTranslation } from 'react-i18next'
import SearchBlockWithTitle from '../../main/second/mainbar/procedures/Q8/Layouts/SearchBlockWithTitle'

type Props = {
  id: number,
  type: PalletToChangeTypes,
  palletTypeList: IPalletTypeItem[],
  saveFunc?: () => void
}

const PalletsToChange = ({
  id, 
  type,
  palletTypeList,
  saveFunc
}: Props) => {
  const [t] = useTranslation('buttons')
  const {
    data,
    loading,
    error,
    onTypeChange,
    onQtyChange,
    onSave,
    clearData,
    checkForSave
  } = usePalletToChangeService({id, type})

  const handleAccept = async () => {
    await onSave()
    saveFunc && saveFunc()
  }
  const palletQty = data?.reduce((acc, item) => acc + item.Quantity_Pallets, 0)
  return (
    <MainWrapper>
      <BodyWrapper>
      <SearchBlockWithTitle
        title={
          <Line>
            <Circle>
              {palletQty}
            </Circle>
            :סך הכל
          </Line>
        }
      >
        <></>
      </SearchBlockWithTitle>
      {
        loading ? (
          <LoaderSpinner />
        ) :
        (
          data?.map((item, index) => 
            <PalletToChangeItem
              index={index} 
              key={item.RecID}
              palletTypeList={palletTypeList}
              onChangeQty={onQtyChange}
              onChangeType={onTypeChange}
              onClear={clearData}
              {...item}
            />
          )
        )
      }
      </BodyWrapper>
      {
        checkForSave && 
          <ControlButton
            lable={t('save')}
            handleClick={handleAccept}
          />
        
      }
    </MainWrapper>
  )
}

const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  width: 90%;
  height: 100%;
  
`
const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  width: 100%;
  height: 85%;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 0;
  }
`
const Circle = styled.div`
  padding: 0.3em;
  width: 2em;
  height: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid #8CC53D;
`
const Line = styled.div`
  display: flex;
  gap: 0.5em;
  align-items: center;
`
export default PalletsToChange