import React from 'react'
import styled from 'styled-components'

type Props = {
  children: React.ReactNode | React.ReactNode[]
}

const FlexColumnLayout = (props: Props) => {
  return (
    <MainWrapper>
      {props.children}
    </MainWrapper>
  )
}
const MainWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    background: #fff;
    display: flex;
    flex-flow: column;
    gap: 0.5em;
`
export default FlexColumnLayout