import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import RequiredFieldMark from '../particles/RequiredFieldMark'


export const Wrapper = styled.div`
    /* background: red; */
    position: relative;
    width: ${props => props.size === 'full' ? '100%' 
        : props.size === 'large' ? '250px'
            : props.size === 'medium' ? '210px'
                : props.size === 'small' ? '150px'
                    : 'none'
    };
    height: 3.5em;
    border-radius: 30px;
`
export const Select = styled.select`
    width: 100%;
    height: 100%;
    border-radius: 30px;
    outline: none;
    border: none;
    font-size: 1em;
    background: #eff3f4;
    padding: 0 1em;
    appearance: none;
    direction: rtl;
    cursor: pointer;
    color: #8a97ac;
    font-size: 1.1em;
`
export const SelectedIndicator = styled.div`
    position: absolute;
    top: -0.1em;
    right: -0.1em;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    background: tomato;
`
export default function SelectFromTheList({
    lable, list, dispalyFieldName, itemKey, fieldValue, required, tabIndex,
    selectFunction, defaultValue, selectedValue, size, disableSelectedMark,
    dispalyFieldNameSecond, disabled
}) {
  return (
    <Wrapper
        size={size}
    >
        {
            !!selectedValue && !disableSelectedMark && <SelectedIndicator />
        }
        <RequiredFieldMark visible={required} />
        <Select
            onChange={e => {selectFunction(e.target.value)}}
            value={selectedValue || defaultValue || ''}
            tabIndex={tabIndex}
            disabled={disabled}
        >
            <option value={''}>{lable}</option>
            {
                list?.map(item => 
                    <option 
                        key={item[itemKey]}
                        value={item[fieldValue]}
                    >
                        {
                            `
                                ${item[dispalyFieldName]?.trim()} 
                                ${dispalyFieldNameSecond && item[dispalyFieldNameSecond] ? `(${item[dispalyFieldNameSecond]})` : ''}
                            `
                            }
                    </option>
                )
            }
        </Select>
    </Wrapper>
  )
}

SelectFromTheList.propTypes = {
    lable: PropTypes.string,
    list: PropTypes.arrayOf(PropTypes.object).isRequired,
    dispalyFieldName: PropTypes.string.isRequired,
    itemKey: PropTypes.string.isRequired,
    fieldValue: PropTypes.string.isRequired,
    selectFunction: PropTypes.func.isRequired,
    defaultValue: PropTypes.number | PropTypes.string ,
    selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    size: PropTypes.oneOf(['small', 'medium', 'large', 'full'])
}

SelectFromTheList.defaultProps = {
    lable: 'select',
    primary: false,
    onChange: undefined,
    size: 'medium',
    list: [
        {id: 1, text: 'test1'},
        {id: 2, text: 'test4'},
        {id: 3, text: 'test3'},
        {id: 4, text: 'test2'},
    ],
    dispalyFieldName: 'text',
    itemKey: 'id',
    fieldValue: 'id',
    disableSelectedMark: false,
    required: false,
    tabIndex: undefined,
    dispalyFieldNameSecond: undefined,
    disabled: false
}
