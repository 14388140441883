import React, { useState } from 'react'
import styled from 'styled-components'
import TitleLayout from '../../../kosherTeudot/generic/TitleLayout'
import TitleRow from '../../../kosherTeudot/generic/TitleRow'
import TitleRowItem from '../../../kosherTeudot/generic/TitleRowItem'
import { IFlowerPalletItem } from '../../../../../../modules/API/Flowers/models/flowerPallet'
import { CalendatDay, DeleteIcon, EditIcon, PackageIcon, PalletIcon, PlaneIcon, PrintIcon, ScalesIcon, ShipIcon, UserIcon } from '../../../../../../generic/icons/Icons'
import { convertToDate } from '../../../../../../modules/convertDateString'
import { SwipeableListItem } from '@sandstreamdev/react-swipeable-list'
import SwipeContent from '../../../knisot/Components/SwipeContent'
import { IDataTItlePrahim } from '../types/contextTitle'
import SwipeMenuComponent from './SwipeMenuComponent'
import SwipeMenuItem from './SwipeMenuItem'

interface Props extends IFlowerPalletItem {
  item: IFlowerPalletItem
  handleClick: (item: IFlowerPalletItem) => void,
  handleDeleteItem: (item: IFlowerPalletItem) => void,
  handleDataTitle: (item: IDataTItlePrahim) => void,
  printPallet: (palletNum: number) => void
}

const TitleItem = ({
  Pallet_Num,
  Customer_Name,
  Pallet_Date,
  Trasportation_Method,
  GrossWeight,
  Total_Pallets,
  item,
  handleClick,
  handleDeleteItem,
  handleDataTitle,
  printPallet,
  
}: Props) => {
  const [isShowDelete, setIsShowDelete] = useState<boolean>(false)
  
  const handleSwaipDelete = () => {
    setIsShowDelete(!isShowDelete)
  }
 
  const handleSelect = () => {
    handleDataTitle({customer: Customer_Name.trim(), method: Trasportation_Method})
    handleClick(item)

  }
  const handleDelete = () => {
    handleDeleteItem(item)
  }
  const handlePrint = () => {
    printPallet(Pallet_Num)
  }
  return (
    <TitleLayout
      isDraggable
    >
      <SwipeableListItem
        
        swipeLeft={{
          content: <SwipeContent text={'delete'} isPrimal position='right' />,
          action: handleSwaipDelete
        }}
        
      
        swipeRight={{
          content: <SwipeContent text={'עדכון'} position='left' />
          ,
          action: handleSelect,
          
        }}
        threshold={0.5}
      >
       
        <MainWrapper>

          <SideWrapper
            onClick={handlePrint}
          >
            <PrintIcon />
          </SideWrapper>
          <TitleRow>
            <TitleRowItem
              name={``}
              icon={
                Trasportation_Method === 1 ? <PlaneIcon /> :
                Trasportation_Method === 2 ? <ShipIcon /> : <></>
              }
            />
            <TitleRowItem
              name={`${convertToDate(Pallet_Date)}`}
              icon={<CalendatDay />}
            />
            <TitleRowItem
              name={`${Pallet_Num}`}
              icon={<PalletIcon />}
            />
          </TitleRow>
          <TitleRow>
            <TitleRowItem
              name={`${Customer_Name.trim()}`}
              icon={<UserIcon />}
            />
          </TitleRow>
          <TitleRow>
            <TitleRowItem
              name={`${GrossWeight.toFixed(2)}`}
              icon={<ScalesIcon />}
            />
            <TitleRowItem
              name={`${Total_Pallets}`}
              icon={<PackageIcon />}
            />

          </TitleRow>
        </MainWrapper>
        {

          isShowDelete ?
            <DeleteWrapper
              onClick={handleDelete}
            >
              <DeleteIcon />
            </DeleteWrapper> : ''
        }
      </SwipeableListItem>
    </TitleLayout>
  )
}

const SideWrapper = styled.div`
  position: absolute;
  left: 0.5em;
  top: calc(50% - 0.6em);
  font-size: 1em;
  color: #297F76;
`

const MainWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-flow: column;
  gap: 0.5em;
`

const DeleteWrapper = styled.div`
  width: 3em;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: tomato;
  color: #fff;
  margin-left: 0.5em;
  border-radius: 0 15px 15px 0;
  cursor: pointer;
`
const Lable = styled.div`

`
export default TitleItem