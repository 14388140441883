import React, {useState} from 'react'
import TabsSelector from './Components/TabsSelector'
import FullSizeLayout from '../../../../../generic/Layouts/FullSizeLayout'
import CustomerBody from './CustomerBody'
import GrowerBody from './GrowerBody'
import useDataFetcher from '../../knisot/hooks/useFetchData'
import { ISupplyDataCustomerItem, ISupplyDataGrowerItem } from '../../../../../modules/API/Forcasts/models/seller'
import { Forecasts } from '../../../../../modules/API/Forcasts/forcasts'
import { format } from 'date-fns'

const tabs: string[] = ['customer', 'grower']

const SellerMain = () => {
  
  // const [date, setDate] = useState<string>(format(new Date(new Date().setDate(new Date().getDate() - 4)), 'yyyyMMdd'))
  const [date, setDate] = useState<string>('20240808')
  const [selected, setSelected] = useState<string>('customer')
  
  const {
    data: customerList,
    setData: setCustomerList
  } = useDataFetcher<ISupplyDataCustomerItem[]>(
    Forecasts.getSellerSupplyByCustomer, date
  )

  const {
    data: growerList,
    setData: setGrowerList
  } = useDataFetcher<ISupplyDataGrowerItem[]>(
    Forecasts.getSellerSupplyByGrower, date
  )

  const handleChangeCustomer = (list: ISupplyDataCustomerItem[]) => {
    setCustomerList(list)
  }
  
  const handleChangeGrower = (list: ISupplyDataGrowerItem[]) => {
    setGrowerList(list)
  }

  return (
    <FullSizeLayout
      label='seller'
    >
      <TabsSelector
        list={tabs}
        selected={selected}
        setSelected={setSelected}
      />
      {
        selected === 'customer' ?
          <CustomerBody 
            list={customerList || []}
            setList={handleChangeCustomer}
          />
          :
          <GrowerBody 
            list={growerList || []}
            setList={handleChangeGrower}
          />
      }
    </FullSizeLayout>
  )
}

export default SellerMain