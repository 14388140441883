import React from 'react'
import { InnerPalletItem } from '../../../../../../modules/API/Flowers/models/innerPallet'
import TitleLayout from '../../../kosherTeudot/generic/TitleLayout'
import TitleRow from '../../../kosherTeudot/generic/TitleRow'
import TitleRowItem from '../../../kosherTeudot/generic/TitleRowItem'
import { DeleteIcon, PackageIcon, ScalesIcon, UserIcon } from '../../../../../../generic/icons/Icons'
import { SwipeableListItem } from '@sandstreamdev/react-swipeable-list'
import styled from 'styled-components'
import SwipeContent from '../../../knisot/Components/SwipeContent'

interface Props extends InnerPalletItem {
  item: InnerPalletItem
  handleClick: (item: InnerPalletItem) => void
}

const PackageListItem = ({
  Pallet_Num, GrossWeight, Customer_Name, handleClick, item
}: Props) => {
  const [isShowDelete, setIsShowDelete] = React.useState<boolean>(false)
  const handleSwaipDelete = () => {
    setIsShowDelete(!isShowDelete)
  }
  const handleDeleteItem = () => {
    handleClick(item)
  }
  return (
    <TitleLayout
      isDraggable
      // disableShadow
    >
    <SwipeableListItem
     
      swipeLeft={{
        content: <SwipeContent text={'delete'} isPrimal position='right' />,
        action: handleSwaipDelete
      
      }}
      threshold={0.3}
    
    >
      <MainWrapper>
          <TitleRow>
            <TitleRowItem 
              name={Pallet_Num}
              icon={<PackageIcon />}
            />
          </TitleRow>
          <TitleRow>
            <TitleRowItem 
              name={Customer_Name.trim()}
              icon={<UserIcon />}
            />
          </TitleRow>
          <TitleRow>
            <TitleRowItem 
              name={GrossWeight.toLocaleString()}
              icon={<ScalesIcon />}
            />
          </TitleRow>
          
      </MainWrapper>
          {
            
            isShowDelete ?
            <DeleteWrapper
              onClick={handleDeleteItem}
            >
              <DeleteIcon />
            </DeleteWrapper> : ''
          }
    </SwipeableListItem>

        </TitleLayout>
  )
}
const MainWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column;
  gap: 0.5em;
`
const DeleteWrapper = styled.div`
  width: 3em;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: tomato;
  color: #fff;
  margin-left: 0.5em;
  border-radius: 0 15px 15px 0;
  cursor: pointer;
`
export default PackageListItem