import React from 'react'
import styled from 'styled-components'
import { PlusIcon } from '../../../../../../generic/icons/Icons'

type Props = {
    onClick: () => void;
    size?: "md" | "full";
    hight?: string;
    isPrimal?: boolean;
    disabled?: boolean;
}

const AddClientButton = ({onClick, size, hight, isPrimal, disabled}: Props) => {
    return (
       
            <ButtonAdd
                size={size}
                type='button'
                onClick={onClick}
                hight={hight}
                isPrimal={isPrimal}
                disabled={disabled}
            > 
              {
                !isPrimal ?
                 <>
                  <PlusIcon /> 
                 </>
                 :
                 <>
                  <PlusIcon /> משטח
                 </>
              }
                
            </ButtonAdd>
     
    )
}

const ButtonAdd = styled.button<Props>`
  height: ${props => props.hight ? props.hight : '70px'};
  width: ${props => props.size === "full" ? '100%' : '100px'};
  border-radius: 15px;
  outline: none;
  appearance: none;
  border: 2px rgba(128,224,129, 1) dashed;
  font-size: 1.5em;
  background: ${props => props.isPrimal ? "rgba(128,224,129, 1)": "#fff"} ;
  color: ${props => props.isPrimal ? '#fff': "rgba(128,224,129, 1)"} ;
  cursor: pointer;
  transition: 0.3s;
  :hover {
    background: rgba(128,224,129, 0.6);
    border: 2px rgba(128,224,129, 0.2) dashed;

    color: #fff;
  }
  :disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }
`
export default AddClientButton