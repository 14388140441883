import React from 'react'
import { IDataTItlePrahim } from '../types/contextTitle'
import styled from 'styled-components'
import TitleLayout from '../../../kosherTeudot/generic/TitleLayout'
import TitleRow from '../../../kosherTeudot/generic/TitleRow'
import TitleRowItem from '../../../kosherTeudot/generic/TitleRowItem'
import { PlaneIcon, ShipIcon, UserIcon } from '../../../../../../generic/icons/Icons'



const InnerTitle = ({
  customer,
  method
}: IDataTItlePrahim) => {
  return (
    <TitleLayout>
      <SideWrapper>
        {
          method === 1 ? <PlaneIcon /> :
          method === 2 ? <ShipIcon /> : ''
        }
      </SideWrapper>
      <TitleRow>
        <TitleRowItem 
          name={customer}
          icon={<UserIcon />}
        />
      </TitleRow>
    </TitleLayout>
  )
}
const SideWrapper = styled.div`
  position: absolute;
  left: 0.5em;
  top: calc(50% - 0.6em);
  font-size: 1.5em;
  color: #297F76;
`
export default InnerTitle