import React from 'react'
import styled from 'styled-components'
import { AppleIcon, ScalesIcon } from '../../../../../generic/icons/Icons'
import { stylePackByID } from '../../../../../generic/utils/stylePackByPackType'
import { IPackagesItem } from '../../../../../modules/API/Defaults/models/packages'
import { IPalletDetailes } from '../../../../../modules/API/Pallets/models/palletList'

interface IPalletDetailesItem extends IPalletDetailes {
    packageHash?: Record<number, IPackagesItem>
}
interface IRowElement {
    color?: string
}

const PalletDetailesItem = ({ ProductDesc, VarietyDesc, SizeID, packageHash, PackID, Quantity, WeightBuy, Pack_Name }: IPalletDetailesItem) => {
    const goodTitle = `${SizeID.trim()} ${VarietyDesc.trim()}  ${ProductDesc.trim()}`
    const packType = packageHash ? packageHash[PackID]?.Pack_Type : 0
    const { color, component } = stylePackByID(packType)
    return (
        <MainWrapper>
            <RowItem>
                <RowElement >
                    {goodTitle}
                    <AppleIcon />
                </RowElement>
            </RowItem>
            <RowItem>
                <RowElement >
                    {WeightBuy}
                    <ScalesIcon />
                </RowElement>
                <RowElement >
                    {Quantity} - {Pack_Name?.trim()}
                    {component}
                </RowElement>
            </RowItem>
        </MainWrapper>
    )
}

const MainWrapper = styled.div`
    width: 100%;
    background: #fff;
    border-radius: 20px;
    padding: 0.3em 0.5em;
    display: flex;
    flex-flow: column;
    gap: 0.5em;
    color: #7D7DA4;
    i {
        color: #7ab0ab;
    }
`
const RowItem = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    gap: 1em;
`
const RowElement = styled.div<IRowElement>`
    display: flex;
    gap: 0.5em;
    i {
        color: ${props => props.color ? props.color : '#7ab0ab'}
    }
    @media screen and (max-width: 800px) {
        font-size: 0.9em;
    }
`

export default PalletDetailesItem