import React, {useState, useMemo} from 'react'
import MarketMainLayout from '../../market/Collect/Layout/MarketMainLayout'
import ControlSearchPannelLayout from '../../../../../generic/Layouts/Wrappers/ControlSearchPannelLayout'
import useDataFetcher from '../../knisot/hooks/useFetchData'
import { Reports } from '../../../../../modules/API/Reports/reports'
import ColumModal from '../../../../../generic/Layouts/ColumModal'
import MainFilter from './Components/MainFilter'
import CircleActiveFilterBtn from '../../../../../generic/particles/CircleActiveFilterBtn'
import { CalendatDay, PackageIcon, PalletIcon, ScalesIcon, SettingMiksher, ShekelIcon } from '../../../../../generic/icons/Icons'
import { IReportListItem } from '../../../../../modules/API/Reports/models/reportList'
import { IReportQ4, IReportQ4Response } from '../../../../../modules/API/Reports/models/q4'
import Q4Table from './Components/Q4Table'
import Q4Layout from './Layouts/Q4Layout'
import TitleLayout from '../../kosherTeudot/generic/TitleLayout'
import TitleRow from '../../kosherTeudot/generic/TitleRow'
import TitleRowItem from '../../kosherTeudot/generic/TitleRowItem'
import { convertToDate } from '../../../../../modules/convertDateString'
import styled from 'styled-components'
import SearchInput from '../../../../../generic/inputs/inputSearch'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../store/reducers/rootReducer'
import { SELECT_DATE_PROCEDURES_Q4, SELECT_REPORT_PROCEDURES_Q4 } from '../../../../../../store/actions/actionsTypes'
import { filterData, sortData } from './helpers/utils'


const MainQ4 = () => {
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const [searchReport, setSearchReport] = useState<string>("")
  const [loadingQ4, setLoadingQ4] = useState<boolean>(false)
  const [isOpenMainFilter, setIsOpenMainFilter] = useState<boolean>(true);
  const [q4Data, setQ4Data] = useState<IReportQ4Response | null>(null)
  const [sorting, setSorting] = useState<number>(0)
  // const [prevSorting, setPrevSorting] = useState<number>(0)

  const dispatch = useDispatch()
  const setDefaultDate = useSelector((state: RootState) => state.procedures.Q4.date)
  const selectedReport = useSelector((state: RootState) => state.procedures.Q4.selectedReport)
  
  const handleDate = (e: object | object[]) => {
        dispatch({type: SELECT_DATE_PROCEDURES_Q4, payloadDate: e}
    )
  } 
  const handleSort = (sort: number) => {
    if(!q4Data) return

    let newArr = [...q4Data.Data]
    const sortedArr = sortData(newArr, sort, sorting)
    setQ4Data({...q4Data, Data: sortedArr})
    setSorting(sort)
    // setPrevSorting(sorting)
  }
  const handleDispatchReport = (item: IReportListItem) => {
    dispatch({type: SELECT_REPORT_PROCEDURES_Q4, report: item})

  }
  const selectedDate =  Array.isArray(setDefaultDate) ? setDefaultDate : new Date(setDefaultDate)

  const {
    data: reportList,
    isLoading: reportListIsLoading
  } = useDataFetcher<IReportListItem[]>(
    Reports.getReportList, "2",
  )

  const getQ4Data = async (RepID: number) => {
    handleControlMainFilter()
    setLoadingQ4(true)
    try {
      let reqData: IReportQ4 = {
        FromDate: startDate,
        ToDate: endDate,
        RepID: RepID,
      }
      let res = await Reports.getReportQ4(reqData);
      setQ4Data(res)
      console.log(res)
    } catch (error) {
      handleControlMainFilter()
      
    } finally {
      setLoadingQ4(false)
    }
  }

  const handleControlMainFilter = () => {
    setIsOpenMainFilter(!isOpenMainFilter)
  }

  const mainFilterProps = {
    setEndDate: setEndDate,
    setStartDate: setStartDate,
    reportList: reportList,
    handleSave: getQ4Data,
    selectedDate: selectedDate,
    handleDate: handleDate,
    setDefaultDate: setDefaultDate,
    cashReport: selectedReport,
    handleDispatchReport: handleDispatchReport
  }

  const dateStartToShow = convertToDate(startDate)
  const dateEndToShow = convertToDate(endDate)

  const renderTable = useMemo(() => {
    if(!q4Data) return [];
    let data = filterData(q4Data.Data, searchReport)
    
    return data
  }, [q4Data, searchReport])

  return (
    <MarketMainLayout size='lg' title='Q4 שאילתת תעודות משלוח'>
      {
        isOpenMainFilter &&
          <ColumModal 
            title='שאילתת תעודות משלוח'
            childrenProps={mainFilterProps}
            controlFunc={handleControlMainFilter}
            Children={MainFilter}
          />
      }
        <TitleLayout disableShadow>
          <TitleRow>
            <TitleRowItem 
              name={`${dateEndToShow} - ${dateStartToShow}`} 
              icon={<CalendatDay />}
            />
            
          </TitleRow>
          <TitleRow>
           
            <TitleRowItem 
              name={`(${q4Data?.Summary.MiddlePrice.toFixed(2) || "0.00"}~) ${q4Data?.Summary.Price.toLocaleString() || "0.00"}`} 
              icon={<ShekelIcon />}
            />
          </TitleRow>
          <TitleRow>
            <TitleRowItem 
              name={q4Data?.Summary.AmountPallets.toLocaleString() || "0"} 
              icon={<PalletIcon />}
            />
            <TitleRowItem 
              name={q4Data?.Summary.Weight.toLocaleString() || "0.00"} 
              icon={<ScalesIcon />}
            />
             <TitleRowItem 
              name={q4Data?.Summary.AmountPack.toLocaleString() || "0"} 
              icon={<PackageIcon />}
            />
          </TitleRow>
          <SideWrapper>
            <CircleActiveFilterBtn
              withAction={false}
              func={handleControlMainFilter}
            >
              <SettingMiksher />
            </CircleActiveFilterBtn>
          </SideWrapper>
        </TitleLayout>
        <ControlSearchPannelLayout>
        <SearchInput
            input={searchReport}
            setInput={setSearchReport}
        />
      </ControlSearchPannelLayout>
      <Q4Layout isLoading={loadingQ4}>
        {
          q4Data &&
            <Q4Table 
              metaData={q4Data?.MetaData}
              data={renderTable}
              handleSort={handleSort}
            />
        }
      </Q4Layout>
    </MarketMainLayout>
  )
}

const SideWrapper = styled.div`
  position: absolute;
  left: 1em;
  top: calc(50% - 1.8em);
`

export default MainQ4