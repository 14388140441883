import React, {useCallback} from 'react'
import styled from 'styled-components'
import ImageComponentHead from '../../../../../../generic/imageComponents/ImageComponentHead'
import Image from '../../../../../../generic/assets/cogsPNG.png'
import Message from './Message'
import ControlButton from '../../../../../../generic/buttons/ControlButton'
type Props = {
  handleConfirmation: (action: boolean) => void
  message: string
}

const ConfirmModal = ({
  handleConfirmation, message
}: Props) => {

  const handleDecicle = useCallback(() => {
    handleConfirmation(false)
  }, [handleConfirmation])

  const handleConfirm = useCallback(() => {
    handleConfirmation(true)
  }, [handleConfirmation])
  return (
    <MainWrapper>
      <ImageWrapper>
        <ImageComponentHead 
          img={Image}
          large
        />
      </ImageWrapper>
      <BodyWrapper>
        <Message
          message={message}
        />
      </BodyWrapper>
      <ControlWrapper>
        <ControlButton 
          lable={'ביטול'}
          handleClick={handleDecicle}
        />
        <ControlButton 
          lable={'אישור'}
          handleClick={handleConfirm}
        />
      </ControlWrapper>
    </MainWrapper>
  )
}
const MainWrapper = styled.div`
  width: 90%;
  height: 100%;
  display: flex;
  flex-flow: column;
  gap: 0.5em;
`
const BodyWrapper = styled.div`
  width: 100%;
  height: 100%;
`
const ImageWrapper = styled.div`

`
const ControlWrapper = styled.div`
  display: flex;
  gap: 0.5em;
`
export default ConfirmModal