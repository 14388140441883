import React, { useContext, useMemo, useState } from 'react'
import OrderlikeLayout from '../../../../../generic/Layouts/OrderlikeLayout'
import ControlSearchPannelLayout from '../../../../../generic/Layouts/Wrappers/ControlSearchPannelLayout'
import SearchInput from '../../../../../generic/inputs/inputSearch'
import DatePickerISO from '../../forcasts/Distribution/Components/DatePickerISO'
import useDataFetcher from '../../knisot/hooks/useFetchData'
import { IFlowerPalletItem } from '../../../../../modules/API/Flowers/models/flowerPallet'
import { Flowers } from '../../../../../modules/API/Flowers/flowers'
import { format } from 'date-fns'
import TitlesList from '../../../../../generic/titlesRowItem/TitlesList'
import TitleItem from './Components/TitleItem'
import { useHistory } from 'react-router-dom'
import { LINKS } from '../../../../../Routs/config'
import ControlButton from '../../../../../generic/buttons/ControlButton'
import { ContextPrahim } from './helpers/ContextPrahim'


const TitlesMain = () => {
  
  const history = useHistory()
  const [search, setSearch] = useState<string>('')
  const {
    date, 
    handleDate, 
    dataTitle, 
    handleDataTitle, 
    printPallet
  } = useContext(ContextPrahim)
  
  const {
    data: palletList,
    setData: setPalletList
  } = useDataFetcher<IFlowerPalletItem[]>(
    Flowers.getPalletList, format(date, 'yyyyMMdd')
  )

  const render = useMemo(() => {
    if(!palletList) return []
    return palletList.filter(pallet => 
      pallet.Pallet_Num.toString().includes(search) ||
      pallet.Customer_Name.trim().toLocaleLowerCase().includes(search.toLocaleLowerCase()) ||
      pallet.GrowerName.trim().toLocaleLowerCase().includes(search.toLocaleLowerCase())
    )
  }, [palletList, search])

  const handleSelectPallet = (pallet: IFlowerPalletItem) => {
    history.push(LINKS.mishtahim.likutPrahim.editByID + pallet.Pallet_Num)
  }

  const handleDeleteItem = async (pallet: IFlowerPalletItem) => {
    if(!palletList) return
    try {
      // TODO: implement deletePallet method
      await Flowers.deletePalletTitle(pallet.Pallet_Num)
      const newList = palletList.filter(item => item.Pallet_Num !== pallet.Pallet_Num)
      setPalletList(newList)
    } catch (err) {
      console.log(err)
    }
  }
  const handleNewPallet = () => {
    history.push(LINKS.mishtahim.likutPrahim.editByID + 0)
    handleDataTitle({
      customer: '',
      method: 0,
    })
  }
  const listProps = {
    handleClick: handleSelectPallet,
    handleDeleteItem: handleDeleteItem,
    handleDataTitle: handleDataTitle,
    printPallet: printPallet
  }

  return (
    
    <OrderlikeLayout
      label='משטחים'
    >
      <ControlSearchPannelLayout>
        <DatePickerISO 
          selected={date}
          onChange={handleDate}
        />
        <SearchInput 
          input={search}
          setInput={setSearch}
          value={'חיפוש משטח'}
        />
      </ControlSearchPannelLayout>
      <TitlesList 
        list={render}
        uniqueKey={'Pallet_Num'}
        additionalData={listProps}
        Element={TitleItem}
        noPadding={false}
      />
      <ControlButton 
        lable={'הוספת משטח'}
        handleClick={handleNewPallet}
      />
    </OrderlikeLayout>
  )
}

export default TitlesMain