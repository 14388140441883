import React, { useState, useMemo, useCallback, useContext } from 'react'
import { IClientsItem, IClientsItemResponse } from '../../../../../../modules/API/Defaults/models/customers';
import selectBrodMigdal from '../../../../../../generic/utils/selectBrodMigdal';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../../store/reducers/rootReducer';
import styled from 'styled-components';
import SearchFromTheList from '../../../../../../generic/customSearchComponents/SearchFromTheList';
import ImageComponentHead from '../../../../../../generic/imageComponents/ImageComponentHead';
import DeliveryImg from '../../../../../../generic/assets/delivery.png';
import ControlButton from '../../../../../../generic/buttons/ControlButton';
import SlectNewClient from './SlectNewClient';
import { IAddCustomer } from '../MarketCollectMain';
import { Defaults } from '../../../../../../modules/API/Defaults/defaults';
import useDataFetcher from '../../../knisot/hooks/useFetchData';
import { ISellerItem } from '../../../../../../modules/API/Defaults/models/seller';
import SearchSeller from './SearchSeller';
import { ContextModal } from '../../../../../../modules/modal/modalContext';
import SearchBlockWithTitle from '../../../procedures/Q8/Layouts/SearchBlockWithTitle';
import CircleActiveFilterBtn from '../../../../../../generic/particles/CircleActiveFilterBtn';
import { AcceptIcon, EditIcon } from '../../../../../../generic/icons/Icons';
import PhoneInputWithMask from './generic/PhoneInputWithMask';

type Props = {
  isRequiredSeller?: boolean;
  isDisableNewCustomer?: boolean;
  clientList: IClientsItem[];
  sellerList?: ISellerItem[];
  selectedSeller?: number
  setSelectedSeller?: React.Dispatch<React.SetStateAction<number>>
  handleSave: (obj: IAddCustomer) => void;
  isOnlyCreateNew?: boolean;
  disableAddNewCustomer?: boolean;
  hashCustomersList: Record<number, {
    index: number;
    palletHash: Record<number, {
        index: number;
    }>;
}>
}

const AddClientModal = ({
  handleSave, isOnlyCreateNew,
  hashCustomersList, sellerList,
  selectedSeller, setSelectedSeller,
  isRequiredSeller, isDisableNewCustomer,
  disableAddNewCustomer
}: Props) => {
  const [modal, setModal] = useContext(ContextModal)

  const defaults = useSelector((state: RootState) => state.defaults.defaults)
  const clientCash: number = selectBrodMigdal(defaults, 611, "Num_Value")
  const [selectedClient, setSelectedClient] = useState<IClientsItem | null>(null)
  const [clientInput, setClientInput] = useState<string>("")
  const [isOpenClientList, setIsOpenClientList] = useState<boolean>(false);
  const [selectedNewClient, setSelectedNewClient] = useState<number | null>(isDisableNewCustomer ? 0 : null)
  const [phone, setPhone] = useState<string>("");
  const [selectedCustomerPhoneFOrEdit, setSelectedCustomerPhoneFOrEdit] = useState<string>("");
  const [isShowPhoneSettings, setIsShowPhoneSettings] = useState<boolean>(false)
  const [newClientName, setNewClientName] = useState<string>(clientInput);
  const isSellerNeed = sellerList && selectedSeller !== undefined && setSelectedSeller
  console.log(selectedCustomerPhoneFOrEdit)
  const {
    data: clientList,
    isLoading: isLoadingClients
  } = useDataFetcher<IClientsItemResponse>(
    Defaults.getCustomers, 'data' 
  )
  const formatedClientsList = useMemo(() => {
    if (!clientList || !hashCustomersList) return []
    let newArr = clientList.customers.filter(item => {
      return !hashCustomersList[item.CustomerID]

    })
     return newArr
  }, [hashCustomersList, clientList])
  const handleSearchClient = useCallback((e: string) => {
    setIsOpenClientList(true)
    setClientInput(e)
    if (!e) {
      setIsShowPhoneSettings(false)
      setSelectedNewClient(null)
      setSelectedClient(null)
    }
  }, [])

  const handleSelectClient = useCallback((item: IClientsItem) => {
    setSelectedClient(item)
    setClientInput(item.Customer_Name.trim())
    setIsOpenClientList(false)
    console.log(item)
    setSelectedCustomerPhoneFOrEdit(item.Phone)
  }, [])

  const handlePhone = useCallback((e: string) => {
    setPhone(e)
  }, [])

  const handleNewClientName = useCallback((e: string) => {
    setNewClientName(e)
  }, [])
  const handleSelectButton = useCallback((id: number) => {
    setSelectedNewClient(id)
  }, [])
  const renderClientList = useMemo(() => {
    if (!formatedClientsList) return []
    return formatedClientsList.filter((item: IClientsItem) => {
      return item.Customer_Name.trim().includes(clientInput) ||
      item.Phone.trim().includes(clientInput)
    })
  }, [formatedClientsList, clientInput])

  const handleSaveClient = () => {
    if (isShowNewClient) {
      const isUniqueCustomerName = clientList?.customers.filter(item => item.Customer_Name.trim() === newClientName.trim()).length === 0 
      if (!isUniqueCustomerName) {
        setModal({
            open: true,
            value: 'שם לקוח כבר קיים',
            mood: false
        })
        setTimeout(() => {
            setModal({
                ...modal,
                open: false
            })
        }, 3000)
        return
      }
    }
    handleSave({
      customer: selectedClient,
      ClientID: selectedNewClient === 0 ? clientCash : 0,
      CustomerID: 0,
      Customer_Name: newClientName?.trim() || clientInput?.trim(),
      Phone: phone
    })
  }
  const handleMarkFunc = useCallback((item: IClientsItem) => {
    return item.Casual
  }, [])

  const isShowNewClient = useMemo(() => {
    if(isLoadingClients) return false
    return renderClientList.length === 0 && !selectedClient 
  }, [renderClientList, selectedClient])

  console.log(clientCash)
  const handleShowPhoneComponent = () => {
    setIsShowPhoneSettings(!isShowPhoneSettings)
  }
  const handleChangePhoneForEdit = useCallback((e: string) => {
    setSelectedCustomerPhoneFOrEdit(e)
  }, [])

  const handleSavePhone = async () => {
    if(!selectedClient) return
    if(selectedCustomerPhoneFOrEdit.length !== 12 && selectedCustomerPhoneFOrEdit.length !== 0) {
      setModal({
        open: true,
        value: 'מספר טלפון לא תקין',
        mood: false
      })
      setTimeout(() => {
        setModal({
          open: false,
          value: ''
        })
      }, 3000)
      return
    }
    try {
        await Defaults.editCustomer(selectedClient.CustomerID, {
          Phone: selectedCustomerPhoneFOrEdit
        })
      setSelectedClient({
        ...selectedClient,
        Phone: selectedCustomerPhoneFOrEdit
      })
      setIsShowPhoneSettings(false)
      setModal({
        open: true,
        value: 'נשמר בהצלחה',
        mood: true
      })
      setTimeout(() => {
        setModal({
          open: false,
          value: ''
        })
      }, 3000)
    } catch (err) {

    }
  }
  return (
    <MainWrapper>
      <SelectWrapper>
      <SelectorWrapper >
        <ImageComponentHead
          img={DeliveryImg}
        />
        {
          isSellerNeed ? 
            <>
              <Title>
                בחר מוכר
              </Title>
              <SearchSeller 
                list={sellerList}
                defaultID={selectedSeller}
                required={!selectedSeller}
                setSellerID={setSelectedSeller}
              />
            </>
            :
            ''
        }
        {
          !isOnlyCreateNew &&
          <>
          <SearchBlockWithTitle
            title={
              'בחר לקוח'
            }
          >
            {
              selectedClient &&
              <CircleActiveFilterBtn
                func={handleShowPhoneComponent}
              >
                <EditIcon />
              </CircleActiveFilterBtn>
            }
            <SearchFromTheList
              list={renderClientList}
              itemDisplayName='Customer_Name'
              itemSecondaryName={'Phone'}
              input={clientInput}
              nameIdElement='CustomerID'
              isNeedFullObject
              placeHolder={'חיפוש לקוח'}
              searchFunc={handleSearchClient}
              selectFunc={handleSelectClient}
              size={'full'}
              isOpenList={isOpenClientList}
              required={!clientInput}
              markedLinePattern={handleMarkFunc}
              
            />
          </SearchBlockWithTitle>
            {/* <Title>
              בחר לקוח
            </Title> */}
            
          </>
          
        }
        
      </SelectorWrapper>
      {
          isShowPhoneSettings ?
          <SelectorWrapper>
            <SearchBlockWithTitle
              title={'עדכון טלפון'}
            >
              <CircleActiveFilterBtn
                func={handleSavePhone}
              >
                <AcceptIcon />
              </CircleActiveFilterBtn>
              <PhoneInputWithMask 
                defaultValue={selectedCustomerPhoneFOrEdit}
                handleChange={handleChangePhoneForEdit}
                />
              
                </SearchBlockWithTitle>
          </SelectorWrapper> : ''
        }
      {
        isShowNewClient && !disableAddNewCustomer && !isLoadingClients &&
          <SlectNewClient 
            isSelected={selectedNewClient}
            selectButton={handleSelectButton} 
            handlePhone={handlePhone}
            handleNewClientName={handleNewClientName}
            defaultName={clientInput}
            isDisableNewCustomer={isDisableNewCustomer}
          />
       
      }
        
      </SelectWrapper>
      {
        (selectedClient || (selectedNewClient || selectedNewClient === 0)) &&
        (isRequiredSeller ? selectedSeller : true) ?
        <ControlButton lable={'שמור'}  handleClick={handleSaveClient}/> : ''
      }
    </MainWrapper>
  )
}

const MainWrapper = styled.div`
  width: 90%;
  height: 100%;
  display: flex;
  flex-flow: column;
  gap: 1em;
  padding-bottom: 0.5em;
  overflow: hidden;
`

const SelectWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  gap: 1em;
  padding: 0.5em; 
  overflow-y: auto;
  ::-webkit-scrollbar{
    width: 0;
  }
`

const SelectorWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column;
  gap: 0.5em;
  padding: 0.5em;
  border-radius: 30px;
  background: rgb(233,240,240, 0.2)
`

const Title = styled.div`
  text-align: end;
  color: #297F76;
  font-weight: 600;
  padding: 0 0.5em;
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse

`
const  AddClientModalMemo = React.memo(AddClientModal)
export default AddClientModalMemo