import React from 'react'
import SelectFromTheList from '../../../../../../generic/customSearchComponents/SelectFromTheList'
import { EProductWithCountsSort } from '../types/productWithCountSort'

type Props = {
  sort: string
  setSort: React.Dispatch<React.SetStateAction<string>>
}

const list = [
  {
    id: EProductWithCountsSort.DEFAULT,
    name: EProductWithCountsSort.DEFAULT
  },
  {
    id: EProductWithCountsSort.PRODUCT,
    name: EProductWithCountsSort.PRODUCT
  }
]
const SelectProductWithCountSearch = ({
  sort, setSort
}: Props) => {

  const handleSelectFunction = (id: EProductWithCountsSort) => {
    setSort(id)
  }
  return (
    <SelectFromTheList 
      list={list}
      defaultValue={sort}
      dispalyFieldName={'name'}
      itemKey={'id'}
      fieldValue={'id'}
      selectFunction={handleSelectFunction}
      selectedValue={sort}
    />
  )
}

export default SelectProductWithCountSearch