import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types'

const AddButton = styled.button`
    width: ${props => props.size === 'full' ? '100%'
        : props.size === 'lg' ? '250px'
            : props.size === 'md' ? '200px'
                : 'none'
    };
    padding: ${props => props.size === 'full' ? '1em 0'
        : props.size === 'md' ? '0.8em 0'
            : props.size === 'lg' ? '1em 0'
                : 'none'
    } ;
    border-radius: 30px;
    border: none;
    background: ${props => props.primal ? 'rgba(76,148,141, 1)' : 'rgba(76,148,141, 0.7)'} ;
    font-size: 1.05em;
    color: #fdfdfd;
    cursor: pointer;
    transition: 0.3s ease-in;
    -webkit-box-shadow: -2px 8px 2px -6px rgba(41,127,118,0.31); 
    box-shadow: -2px 8px 2px -6px rgba(41,127,118,0.31);
    :disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }
    &:hover{
        background: rgba(76,148,141, 1);
        -webkit-box-shadow: -2px 8px 2px -6px rgba(41,127,118,0.51); 
        box-shadow: -2px 8px 2px -6px rgba(41,127,118,0.51);
    }
    
`
function ControlButton({lable, handleClick, size, primal, tabIndex, disabled, icon}) {
    
    return (
        <AddButton
            onClick={handleClick}
            size={size}
            primal={primal}
            tabIndex={tabIndex}
            disabled={disabled}
        >
            {lable}
            {icon}
        </AddButton>
    );
}

export default ControlButton;

ControlButton.propTypes = {
    lable: PropTypes.string,
    handleClick: PropTypes.func,
    size: PropTypes.oneOf(['full', 'md', 'lg']),
    primal: PropTypes.bool
}
ControlButton.defaultProps = {
    lable: 'Button',
    handleClick: () => console.log('action'),
    size: 'full',
    primal: false,
    tabIndex: undefined,
    disabled: false,
    icon: undefined
}