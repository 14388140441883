

export const buttonsTranslationTH = {
  buttons: {
    "save": "บันทึก",
    "cancel": "ยกเลิก",
    "new": "สร้าง",
    "close": "ปิด",
    "exit": "ออก",
    "back": "กลับ",
  }
}