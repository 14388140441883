import React, { useContext, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch, useHistory, useRouteMatch } from "react-router-dom";
import { LOAD, LOAD_END, SELECT_DATA_FOR_ORDER_PACKAGE, SELECT_DATE_DRIVERS } from "../../../../../store/actions/actionsTypes";
import { RootState } from "../../../../../store/reducers/rootReducer";
import DatePickerModule from "../../../../generic/datePicker/datePicker";
import SearchInput from "../../../../generic/inputs/inputSearch";
import ColumModal from "../../../../generic/Layouts/ColumModal";
import OrderlikeLayout from "../../../../generic/Layouts/OrderlikeLayout";
import BodyScrollContainer from "../../../../generic/Layouts/Wrappers/BodyScrollContainer";
import ControlSearchPannelLayout from "../../../../generic/Layouts/Wrappers/ControlSearchPannelLayout";
import { Defaults } from "../../../../modules/API/Defaults/defaults";
import { ITableOfTableItem } from "../../../../modules/API/Defaults/models/tableOfTables";
import { Drivers } from "../../../../modules/API/Drivers/drivers";
import {
  IActionLog,
  ICustomerItem,
  IGrowerItem,
  IKavDataItem,
  IOrderItem,
} from "../../../../modules/API/Drivers/models/kavData";
import { IOrderPackageTitle } from "../../../../modules/API/Packages/models/createPackageOrderModel";
import { GET_PDF_TEUDOT, STATIC_PATH_V2 } from "../../../../modules/API/settings";
import { ContextModal } from "../../../../modules/modal/modalContext";
import DriverBody from "./DriverBody";
import DriverModal from "./modals/DriverModal";
import QuestsModal from "./modals/QuestsModal";
import PackageOverlay from "./modals/PackageOverlay";
import useGeolocation from "../../../../generic/hooks/useGeolocation";
import { Trucks } from "../../../../modules/API/Distribution/trucks";
import PackagesPortalModal from "./modals/PackagesPortalModal";
import useDataFetcher from "../knisot/hooks/useFetchData";
import { LINKS } from "../../../../Routs/config";
import CircleActiveFilterBtn from "../../../../generic/particles/CircleActiveFilterBtn";
import { PrintIcon } from "../../../../generic/icons/Icons";
import StatisticPackageModal from "./modals/StatisticPackageModal";
import { IPackageLogData } from "../../../../modules/API/Drivers/models/packageLogData";


const DriversMain = () => {

  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.mainReducer.user);
  const isOffice = user ? user?.code_internal === '0' || user?.rights?.includes('TRCOMPANY') : false
  const token = useSelector((state: RootState) => state.mainReducer.token);
  const defaultDate = useSelector((state: RootState) => state.driverDerucer.date);
  const {path} = useRouteMatch();
  const history = useHistory();
  const {position, error} = useGeolocation();
  const apiKey = process.env.REACT_APP_GOOGLE_MAP_API_KEY;
  // console.log(apiKey)
  const postLocation = async () => {
    try {
      const res = await fetch(
        `https://my.agroline.co.il/v2/driver/orders_tree?latitude=${position?.latitude}&longitude=${position?.longitude}`, 
        {
          method: 'POST',
          body: JSON.stringify({
            latitude: position?.latitude,
            longitude: position?.longitude
          })
        })
      // console.log(res)
    } catch (err) {
      // console.log(err)
    }
  } 
//   useMemo(() => {
//     if(!position) return
//     console.log(position)
//     postLocation()
//    return {
//      latitude: position.latitude,
//      longitude: position.longitude
//    }
//  }, [position])

  const [date, setDate] = useState<string>('');
  const [input, setInput] = useState<string>("");
  const [defaultStatuses, setDefaultStatuses] = useState<ITableOfTableItem[]>()
  const [modal, setModal] = useContext(ContextModal);
  const [isOpenDriverModal, setIsOpenDriverModal] = useState(false);
  const [isOpenQuestsModal, setIsOpenQuestsModal] = useState(false);
  const [isOpenPackageOverlay, setIsOpenPackageOverlay] = useState(false);
  const [isOpenStatisticModal, setIsOpenStatisticModal] = useState(false);
  const [selectedDeliveryOrder, setSelectedDeliveryOrder] =
    useState<IOrderItem[]>();
  const [data, setData] = useState<IKavDataItem[]>();
  const [growersData, setGrowersData] = useState<IGrowerItem[]>([])
  const [customerData, setCustomerData] = useState<ICustomerItem>()
  const [selectedKav, setSelectedKav] = useState<number>(0)
  const [dataPackageLog, setDataPackageLog] = useState<IPackageLogData[] | null>(null)
  const [kavInfo, setKavInfo] = useState<IKavDataItem | null>(null)
  // console.log(data)
  const handleIsOpenPackageOverlay = () => {
    setIsOpenPortalModal(false)
    setIsOpenPackageOverlay(!isOpenPackageOverlay)
  }
  const getDefaultStatuses = async () => {
    try {
      const res = await Defaults.getStandartTableItems(112)
      setDefaultStatuses(res)
    } catch (err) {
      setModal({
        open: true,
        value: "משהו השתבש",
        mood: false,
      });
      setTimeout(() => {
        setModal({
          open: false,
          value: "2",
        });
      }, 3000);
    }
  }
  const getDriverDate = async () => {
    dispatch({ type: LOAD });
    try {
      let res = await Drivers.getDriverOrders(date);
      setData(res);
    } catch (err) {
      setModal({
        open: true,
        value: "משהו השתבש",
        mood: false,
      });
      setTimeout(() => {
        setModal({
          open: false,
          value: "2",
        });
      }, 3000);
    } finally {
      dispatch({ type: LOAD_END });
    }
  };

  const getDriverDataOffice = async () => {
    dispatch({ type: LOAD });
    try {
      let res = await Trucks.getOrdersTree(`${date}&ShowDeliveries=1`);
      res.splice(0, 1)
      setData(res);
    } catch (err) {
      setModal({
        open: true,
        value: "משהו השתבש",
        mood: false
      })
      setTimeout(() => {
        setModal({
          open: false,
          value: "2"
        })
      }, 3000)
    } finally {
      dispatch({ type: LOAD_END });
    }
  }
  const printPdf = async (CODE: number, SUBCODE: number) => {
    dispatch({ type: LOAD });
    try {
      let response = await fetch(
        `${GET_PDF_TEUDOT}deliveryNum=${CODE}&deliverySub=${SUBCODE}`,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      const data = await response.blob();
      // Receive PDF Steam as as Blob
      const file = new Blob([data], { type: "application/pdf" }); //Create a Blob from the PDF Stream
      const fileURL = URL.createObjectURL(file); //Build a URL from the file
      window.open(fileURL); //Open the URL on new Window
    } catch (err) {
      console.log(err);
    } finally {
      dispatch({ type: LOAD_END });
    }
  };

  const printLogPdf = async (areaID?: number ) => {
    dispatch({ type: LOAD });
    try {
      let response = await fetch(
        !areaID ?
        `${STATIC_PATH_V2}driver/actions_log/pdf?Date=${date}`
        :
        `${STATIC_PATH_V2}driver/actions_log/pdf?Date=${date}&TransferZone=${areaID}`
        ,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      const data = await response.blob();
      // Receive PDF Steam as as Blob
      const file = new Blob([data], { type: "application/pdf" }); //Create a Blob from the PDF Stream
      const fileURL = URL.createObjectURL(file); //Build a URL from the file
      window.open(fileURL); //Open the URL on new Window
    } catch {

    } finally {
      dispatch({ type: LOAD_END });
    }
  }

  // const confirmDeliveryOrder = async (id: number) => {
  //   dispatch({ type: LOAD });
  //   if (!selectedDeliveryOrder) return
  //   const data = selectedDeliveryOrder?.filter(item => item.Delivery_Num !== 0 && item.Selected).map(e => {
  //     return {
  //       Delivery_Num: e.Delivery_Num,
  //       Delivery_Sub: e.Delivery_Sub,
  //       DeliveryStat: id
  //     }
  //   })
  //   try {
  //     let res = await Drivers.changeStatusDelivery(data);
  //     setModal({
  //       open: true,
  //       value: `הסטטוס השתנה`,
  //       mood: true,
  //     });
  //     setTimeout(() => {
  //       setModal({
  //         open: false,
  //         value: "2",
  //       });
  //     }, 3000);
  //     handleControlFuncModal();
  //     updateOrdersAfterConfirm(id)
  //     // setSelectedDeliveryOrder([])
  //   } catch (err) {
  //     setModal({
  //       open: true,
  //       value: "משהו השתבש",
  //       mood: false,
  //     });
  //     setTimeout(() => {
  //       setModal({
  //         open: false,
  //         value: "2",
  //       });
  //     }, 3000);
  //   } finally {
  //     dispatch({ type: LOAD_END });
  //   }
  // };
  const getPackageStatisticByKav = async (item: IKavDataItem) => {
    if(!isOffice) return
    dispatch({ type: LOAD })
    try {
      const res = await Drivers.getPackageLogByKav(item.AreaID, date)
      setDataPackageLog(res)
      setKavInfo(item)
      handleOpenPackageStatisticModal()
    } catch {
      
    } finally {
      
      dispatch({ type: LOAD_END })
    }
  }
  const handleOpenPackageStatisticModal = () => {
    setIsOpenStatisticModal(!isOpenStatisticModal)
  }
  const render = useMemo(() => {
    if (!data) return;
    const filtered = data.filter((item) =>
      item.AreaDesc.toLowerCase().includes(input.toLowerCase()) ||
        item.DriverName?.toLowerCase().includes(input.toLowerCase()) ||
          item.Customers.some((cust) =>
            cust.Customer_Name?.toLowerCase().includes(input.toLowerCase())
          )
    );
    return filtered;
  }, [data, input]);

  useEffect(() => {
    if (!date) return;
    if(isOffice) {
      getDriverDataOffice()
    } else {
      getDriverDate();
    }
  }, [date]);

  useEffect(() => {
    getDefaultStatuses()
  }, [])

  const updateOrdersAfterConfirm = (id: number) => {
    if(!selectedDeliveryOrder || !data) return
    const newData = [...data].map(e => {
      e.Customers.map(cust => 
        {
          cust.Orders.map(order => {
            const findDataLog = listTeudotActions?.find(e => e.SecondCode === id)
            if(selectedDeliveryOrder.some(e => 
              e.Delivery_Code === order.Delivery_Code &&
              e.Delivery_Sub === order.Delivery_Sub &&
              e.OrderID === order.OrderID &&
              e.Selected
              )) {
                order.LastAction = {
                  ...order.LastAction,
                  Action: id,
                  ActionDescription: findDataLog?.Description || '',
                  ActionFlag: id === 1 ? true: false,
                }
                order.Selected = false
              return order
            }
            else {
              return order
            }
          })
          return cust
        }
      )
      return e
    })
    setData(newData)
  }

  const selectOrderForCloseDelivery = (item: IOrderItem[], dataCustomer: ICustomerItem, kavID: number) => {
    //filter for DriverStatusModal for show item with Delivery_Num
    setCustomerData(dataCustomer)
    setSelectedKav(kavID)
    setSelectedDeliveryOrder(item.filter(e => e.Delivery_Num !== 0));
    handleControlFuncModal();
  };

  const handleControlFuncModal = () => {
    setIsOpenDriverModal(!isOpenDriverModal);
  };

  const handleControlFuncModalQuests = () => {
    setIsOpenQuestsModal(!isOpenQuestsModal);
  };
  
  const selectDataForPackageOrder = (dataCustomer: ICustomerItem, kavID: number) => {
    // console.log(dataCustomer)
    setCustomerData(dataCustomer)
    const carID = data ? data[0].CarId : 0

    const collection = {
      carID: carID,
      sender: dataCustomer.ClientID,
      reference: dataCustomer.Orders[0]?.Delivery_Num || "",
      date: date,
      clientName: dataCustomer.Customer_Name?.trim(),
      isReverseSender: dataCustomer?.Type === 1,
      finalFunc: undefined,
      customerID: dataCustomer.CustomerID,
      TransferZone: kavID
    }
    dispatch({type: SELECT_DATA_FOR_ORDER_PACKAGE, payload: collection})
    // history.push(LINKS.drivers.quests.createOrderPackages)
    // handleIsOpenPackageOverlay()
    handlePortalModal()
  }

  const handleSelectOrder = (item: IOrderItem) => {
      // add isSelected key to item, for Modal

      // exit if item is not have Delivery_Num
      if(item.Delivery_Num === 0) return
      const patchStateSelectedOrder = selectedDeliveryOrder?.map(e => {
        if(e.OrderID === item.OrderID) {
          e.Selected = !e.Selected
        }
        return e
      })
      setSelectedDeliveryOrder(patchStateSelectedOrder)
  }
  // const handleCloseDeliveryFunc = () => {};
  
  const selectGrowersFromKav = (item: IGrowerItem[]) => {
    setGrowersData(item);
    handleControlFuncModalQuests()


  }

  const updateState = (item: IOrderPackageTitle) =>  {
      let newState = [...growersData].map(e => {
        if(e.ClientID === item.ClientID_Recipient) {
          e.PO_Num = item.PO_Num
        }
        return e
      })
      setGrowersData(newState)
  }
  const questsModalProps = 
    {
      list: growersData,
      carID: data ? data[0]?.CarId : 0,
      date: date,
      updateState: updateState
    }
  
  const handleDate = (e: object) => {
    dispatch({type: SELECT_DATE_DRIVERS, payload: e})
  }
  const descForDriverModal = selectedDeliveryOrder && selectedDeliveryOrder[0]?.Order_Type === 1 ? 'אישור מסירה' : 'אישור איסוף'
  
  const {
    data: listPacakgesActions,
  } = useDataFetcher<ITableOfTableItem[]>(
    Defaults.getStandartTableItems, '129'
  )

  const {
    data: listTeudotActions,
  } = useDataFetcher<ITableOfTableItem[]>(
    Defaults.getStandartTableItems, '128'
  )

  const [isOpenPortalModal, setIsOpenPortalModal] = useState(false)
  const handlePortalModal = () => {
    setIsOpenPortalModal(!isOpenPortalModal)
  }


  const saveToLogPackage = async (
    action: number, areaID: number
    ) => {
      const postData = {
        Action: action,
        ActionTblCode: 129,
        Delivery_Date: date,
        PersonID: customerData?.CustomerID || 0,
        PersonType: customerData?.Type || 0,
        ReferenceId: customerData?.PO_Num || 0,
        ReferenceType: 2,
        TransferZone: areaID || 0

      }
      try {
        const res = await Drivers.logStatusDelivery([postData])
        setIsOpenPortalModal(false)
        if(action !== 1) {
          let newArr = [...data || []].map(e => {
            e.Customers.map(cust => {
              if(cust.CustomerID === customerData?.CustomerID) {
                  cust.PO_Num = 0
                  cust.PD_Num = 0
                  cust.LastAction = {
                    ...cust.LastAction,
                    Action: action,
                    ActionDescription: listPacakgesActions?.find(e => e.SecondCode === action)?.Description || '',
                    ActionFlag: false,
                  }
                
              }
              return cust
            })
            return e
          })
          // console.log(data)
          // console.log(newArr)
          setData(newArr)
          history.push(LINKS.drivers.quests.mainURL)
          // handleIsOpenPackageOverlay()
          setIsOpenPortalModal(false)
        }
      } catch (err) {} 
      finally {}
  }

  const saveToLogOrder = async (action: number, areaID: number) => {
    if(!selectedDeliveryOrder) return
    const postData = selectedDeliveryOrder.map(e => {
      return {
        Action: action,
        ActionTblCode: 128,
        Delivery_Date: date,
        PersonID: customerData?.CustomerID || 0,
        PersonType: customerData?.Type || 0,
        ReferenceId: e.Delivery_Num,
        ReferenceType: 1,
        ReferenceSubId: e.Delivery_Sub,
        TransferZone: selectedKav || 0
      }
    })
    try {
      const res = await Drivers.logStatusDelivery(postData)
      handleControlFuncModal();
      updateOrdersAfterConfirm(action)
    } catch (err) {

    } finally {

    }
  }
 
  const handleActionMovments = async (kavID: number, action: number) => {
    const postData = {
      Action: action,
      ActionTblCode: 0,
      Delivery_Date: date,
      PersonID: 0,
      PersonType: 1,
      ReferenceId: kavID,
      ReferenceType: 3,
      TransferZone: kavID
    }
    try {
      const res: IActionLog[] = await Drivers.logStatusDelivery([postData])
      
      const newArr = [...data || []].map(e => {
        if(e.AreaID === kavID) {
          e.Actions.push(res[0])
        }
        return e
      })
      setData(newArr)
    } catch (err) {

    } finally {

    }
    // Drivers.logStatusDelivery([postData])
  }
  // console.log(data)
  const portalModalProps = {
    customerData: customerData,
    openPackageOverlay: handleIsOpenPackageOverlay,
    list: listPacakgesActions,
    saveAction: saveToLogPackage,
    isOffice: isOffice
  }
  const modalProps = {
    confirmDeliveryOrder: saveToLogOrder,
    selectedDeliveryOrder: selectedDeliveryOrder,
    defaultStatuses: defaultStatuses,
    handleSelectOrder: handleSelectOrder,
    actionList: listTeudotActions
  };
  const packageLogProps = {
    list: dataPackageLog
  }
  console.log(date)
  return (
    <Switch>
      <Route path={path} exact> 
        <OrderlikeLayout label="נסיעות שלי">
          {
            isOpenStatisticModal &&
            <ColumModal 
              title={`סיכום אריזות ל${kavInfo?.AreaDesc?.trim()} `}
              controlFunc={handleOpenPackageStatisticModal}
              childrenProps={packageLogProps}
              Children={StatisticPackageModal}
            />
          }
          {
            isOpenPortalModal &&
            <ColumModal 
              title={listPacakgesActions?.at(0)?.Description?.trim() || ''}
              controlFunc={handlePortalModal}
              childrenProps={portalModalProps}
              Children={PackagesPortalModal}
            />
          }
          {
            isOpenPackageOverlay &&
            <PackageOverlay 
              getDriverDate={getDriverDate}
              saveToLogPackage={saveToLogPackage}
              controlFunc={handleIsOpenPackageOverlay}
            />
          }
          {isOpenDriverModal && (
            <ColumModal
              title={descForDriverModal}
              controlFunc={handleControlFuncModal}
              childrenProps={modalProps}
              Children={DriverModal}
            />
          )}
          {isOpenQuestsModal && (
            <ColumModal
              title={descForDriverModal}
              controlFunc={handleControlFuncModalQuests}
              childrenProps={questsModalProps}
              Children={QuestsModal}
            />
          )}
          <ControlSearchPannelLayout>
            {
              isOffice &&
              <CircleActiveFilterBtn
                func={printLogPdf}
              >
                <PrintIcon />
              </CircleActiveFilterBtn>
            }
            <DatePickerModule
              multyDate={false}
              setSelectedDateStart={setDate}
              // setSelectedDateEnd={false}
              disabled={false}
              selected={defaultDate ? new Date(defaultDate) : false}
              ignorBrod={false}
              defEndDate={false}
              large={false}
              isNeedWithoutFormated={true}
              saveWithoutFormat={handleDate}
              // isNextDay

            />
            <SearchInput input={input} setInput={setInput} />
          </ControlSearchPannelLayout>
          <BodyScrollContainer>
            <DriverBody
              list={render}
              printFunc={printPdf}
              selectOrderForCloseDelivery={selectOrderForCloseDelivery}
              selectDataForPackageOrder={selectDataForPackageOrder}
              selectGrowersFromKav={selectGrowersFromKav}
              handleActionMovments={handleActionMovments}
              isOffice={isOffice}
              getPackageStatisticByKav={getPackageStatisticByKav}
              printLogPdf={printLogPdf}
            />
          </BodyScrollContainer>
        </OrderlikeLayout>
      </Route>
      {/* <Route 
        path={LINKS.drivers.quests.createOrderPackages} 
        render={() => <PackagesDriver getDriverDate={getDriverDate} />}
      />
      <Route path={LINKS.drivers.quests.editGeneral} render={() => <ArizotViewMain linkBack={LINKS.drivers.quests.mainURL} />}/> */}
    </Switch>
  );
};

export default DriversMain;
