
const selectBrodMigdal = (arr, number, value, additional) => {
    try {
        if (!additional) {
            return arr?.filter(e => e.Int_ID === number)[0][value]
        }
        if(typeof additional === 'number') {
            return arr?.filter(e => 
                 e.Int_ID === number && 
                 (additional ? e.Sub_ID === additional : true)
                )[0][value]

        } else if (typeof additional === 'string') {
            return arr?.filter(e => 
                e.Int_ID === number && 
                (additional ? e.Sub_ID.trim() === additional : true)
               )[0][value]
        } 

    } catch (error) {
        return 
    }
}

export default selectBrodMigdal